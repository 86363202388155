import Immutable from 'immutable';
import { Reducer } from 'redux';
import actionTypes, { Action, PayloadAction } from '../actions/actionTypes';
import { LicenseTier } from '../types/license';
import { normalizer } from '../utils/genericNormalizer';

interface Tier {
    tierIDs: string[];
    tiers: LicenseTier;
}

export interface LicenseState {
    tier: Partial<Tier>;
}

export type LicenseImmutableState = Immutable.Map<keyof LicenseState, any>;

export const initialState: LicenseState = {
    tier: {},
};

const License: Reducer<LicenseImmutableState | undefined, Action> = (
    state = Immutable.fromJS(initialState),
    action
) => {
    switch (action.type) {
        case actionTypes.RECEIVED_LICENSE_TIERS: {
            const { payload } = action as PayloadAction<LicenseTier[]>;
            const { entities, entityIds } = normalizer(payload, 'tier');
            return state.set(
                'tier',
                Immutable.fromJS({
                    tiers: entities,
                    tierIDs: entityIds,
                })
            );
        }
        default: {
            return state;
        }
    }
};

export default License;
