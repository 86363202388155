import { Spin } from 'antd';
import React from 'react';
import styles from './LoadingOverlay.styl';

const LoadingOverlay: React.FunctionComponent<{ overlayBlocked: boolean }> = (props) => {
    const { overlayBlocked } = props;
    return (
        <div className={overlayBlocked ? styles.overlay_blocked : styles.overlay}>
            <Spin spinning={overlayBlocked} />
        </div>
    );
};

export default LoadingOverlay;
