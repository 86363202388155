import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { classNames } from '../../../../utils/classUtils';
import wrapperStyles from '../../../Authentication/AuthWrapper.styl';
import twoFAStyles from '../TwoFAStyles.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import * as authService from '../../../../api/authService';

interface SetUpAuthenticatorProps {
    onClick: () => void;
    setShowError: (showError: boolean) => void;
    setSecretKey: (key: string) => void;
}

const SetUpAuthenticator: React.FunctionComponent<SetUpAuthenticatorProps> = (props) => {
    const { onClick, setShowError, setSecretKey } = props;
    const [qrCode, setQrCode] = useState<string | undefined>();
    const [code, setCode] = useState<string | undefined>();

    useEffect(() => {
        authService
            .getTwoFASetUpCode()
            .then((res) => {
                setQrCode(res.data.qrCode);
                setCode(res.data.code);
                setSecretKey(res.data.secretKey);
            })
            .catch(() => setShowError(true));
    }, [setSecretKey, setShowError]);

    return (
        <div
            className={classNames(
                wrapperStyles.content_wrapper,
                wrapperStyles.input_block,
                twoFAStyles.generic_container
            )}
        >
            <div className={twoFAStyles.header}>
                <FormattedMessage id="2FA.SetUp.Authenticator.Title" />
            </div>
            <div className={twoFAStyles.body_text}>
                <FormattedMessage id="2FA.SetUp.Authenticator.Body" />
            </div>
            {qrCode && code ? (
                <>
                    <img className={twoFAStyles.qrCode} src={`data:image/png;base64,${qrCode}`} alt={''} />
                    <div className={twoFAStyles.body_text}>
                        <FormattedMessage id="2FA.SetUp.Authenticator.EnterCode" values={{ code }} />
                    </div>
                    <AnalyticsButton
                        className={twoFAStyles.primary_button_container}
                        type="primary"
                        onClick={onClick}
                        size="large"
                    >
                        <span className={twoFAStyles.primary_button_text}>
                            <FormattedMessage id="Common.Continue" />
                        </span>
                    </AnalyticsButton>
                </>
            ) : (
                <Spin className={twoFAStyles.spinner} />
            )}
        </div>
    );
};

export default SetUpAuthenticator;
