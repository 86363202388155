import { Spin } from 'antd';
import React from 'react';

interface FullScreenSpinnerProps {
    tip?: string;
    spinnerSize?: 'default' | 'small' | 'large' | undefined;
    extraStyle?: React.CSSProperties;
}

const FullScreenSpinner: React.FunctionComponent<FullScreenSpinnerProps> = (props) => {
    const { tip, spinnerSize = 'default', extraStyle } = props;
    return (
        <div data-mode="dark" style={{ width: '100%', height: '100%', ...extraStyle }}>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Spin size={spinnerSize} tip={tip} />
                </div>
            </div>
        </div>
    );
};

export default FullScreenSpinner;
