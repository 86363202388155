import React, { useCallback, useRef } from 'react';
import { routeUtils } from 'tds-common-fe';
import FormattedMessage from '../../../localization/FormatMessage';
import Button from '../../AnalyticsComponents/Button';
import styles from './MultipleDevices.styl';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import analytics from '../../../analytics/index';
import URL from '../../../api/urls';
import { getScreeningEagleHeaders } from '../../../api/headerUtils';
import { AUTH_ROUTES } from '../../Routes/urls';

interface MultipleDevicesWarningParams {
    email: string;
    redirect_uri: string;
}

const MultipleDevicesWarning: React.FunctionComponent = () => {
    const params = routeUtils.parseQuery<MultipleDevicesWarningParams>(location.search);
    const { email, redirect_uri: redirectURI, ...forwardedParams } = params;
    const loginPath = routeUtils.makeQueryPath(AUTH_ROUTES.LOGIN, { redirect_uri: redirectURI, ...forwardedParams });

    const htmlFormRef = useRef<HTMLFormElement>(null);
    const actionURL = routeUtils.makeQueryPath(URL.CONCURRENT_PROCEED, {
        ...getScreeningEagleHeaders(),
        redirect_uri: redirectURI,
        email,
        ...forwardedParams,
    });

    const handleSubmit = useCallback(() => {
        analytics.logMultipleDevicesWarning('proceed');
        const form = htmlFormRef.current;
        if (form !== null) {
            form.submit();
        }
    }, []);

    return (
        <>
            <div className={styles.title_container}>
                <FormattedMessage id="MultipleDevices.Warning.Title" />
            </div>
            <div className={styles.body_container}>
                <FormattedMessage id="MultipleDevices.Warning.Body" values={{ email: <b>{email}</b> }} />
            </div>
            <div className={styles.button_container}>
                <Button type="primary" loading={false} disabled={false} onClick={handleSubmit} size="large">
                    <FormattedMessage id="MultipleDevices.Warning.Proceed" />
                </Button>
            </div>
            <div className={styles.button_container}>
                <AnalyticsButton
                    type="link"
                    onClick={() => {
                        analytics.logMultipleDevicesWarning('use_different_account');
                        window.open(loginPath, '_self');
                    }}
                    size="large"
                >
                    <FormattedMessage id="MultipleDevices.Warning.Cancel" />
                </AnalyticsButton>
            </div>
            <form ref={htmlFormRef} action={actionURL} method="POST" className={styles.hidden_form} />
        </>
    );
};

export default MultipleDevicesWarning;
