import { Rule } from 'antd/lib/form';
import React from 'react';
import { NonOptionalKeys } from 'tds-common-fe';
import FormattedMessage from '../../../../localization/FormatMessage';
import * as inputUtils from '../../../../utils/inputUtils';

export interface CountrySelectionFieldsType {
    countryCode: string;
    agreeTnC: boolean;
}
export type CountrySelectionFieldsKeys = keyof CountrySelectionFieldsType;

export const requiredFields: NonOptionalKeys<CountrySelectionFieldsType>[] = ['countryCode', 'agreeTnC'];

export const rules: { [K in keyof CountrySelectionFieldsType]?: Rule[] } = {
    countryCode: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.Country.Error.Empty" />,
        },
    ],
    agreeTnC: [inputUtils.getTncValidator(<FormattedMessage id="SignUp.Field.TNC.Error.Empty" />)],
};
