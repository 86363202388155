import React from 'react';
import { Rule } from 'antd/lib/form';
import FormattedMessage from '../../../../localization/FormatMessage';
import { getPhoneNumberValidator } from '../../../../utils/phoneUtils';

export interface FieldsType {
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    countryCode: string;
    addressLine1: string;
    addressLine2?: string;
    phone: string;
    product: string;
    tier?: string;
}

export type FieldsKeys = keyof FieldsType;

export const rules: { [K in FieldsKeys]?: Rule[] } = {
    firstName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.FirstName.Error.Empty" />,
        },
    ],
    lastName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.LastName.Error.Empty" />,
        },
    ],
    email: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.Email.Error.Empty" />,
        },
        { type: 'email', message: <FormattedMessage id="SignUp.Field.Email.Error.Invalid" /> },
    ],
    companyName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.CompanyName.Error.Empty" />,
        },
    ],
    product: [
        {
            required: true,
            message: <FormattedMessage id="Contact.Field.Product.Error.Empty" />,
        },
    ],
    tier: [
        {
            required: true,
            message: <FormattedMessage id="Contact.Field.Plan.Error.Empty" />,
        },
    ],
    countryCode: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.Country.Error.Empty" />,
        },
    ],
    addressLine1: [
        {
            required: true,
            message: <FormattedMessage id="Contact.Field.Address.Error.Empty" />,
        },
    ],
    phone: [
        {
            message: <FormattedMessage id="Contact.Field.Phone.Error.Empty" />,
        },
        getPhoneNumberValidator('countryCode', <FormattedMessage id="Contact.Field.Phone.Error.Invalid" />),
    ],
};
