import { ClientParams } from '../api/headerUtils';
import { LanguageOptions } from '../localization';
import { ThemeOptions } from '../components/Routes/ThemeProvider';

interface SessionStorageItems extends Partial<ClientParams> {
    username?: string;
    relogin?: string;
    theme?: ThemeOptions;
    accessToken?: string;
}

interface LocalStorageItems {
    language?: LanguageOptions;
    deviceID?: string;
}

export function getSessionItem(key: keyof SessionStorageItems): string | null {
    return sessionStorage.getItem(key);
}

export function saveSessionItem(key: keyof SessionStorageItems, value: string) {
    sessionStorage.setItem(key, value);
}

export function removeSessionItem(key: keyof SessionStorageItems) {
    sessionStorage.removeItem(key);
}

export function getLocalItem(key: keyof LocalStorageItems): string | null {
    return localStorage.getItem(key);
}

export function saveLocalItem(key: keyof LocalStorageItems, value: string) {
    localStorage.setItem(key, value);
}

export function removeLocalItem(key: keyof LocalStorageItems) {
    localStorage.removeItem(key);
}
