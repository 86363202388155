import { reactNativeUtils, objectUtils } from 'tds-common-fe';

const types = {
    initialized: '',
    loading: '',
    error: '',
    openURL: '',
    loginSuccess: '',
    personalProfileUpdated: '',
    companyProfileUpdated: '',
    closeIframe: '',
    accessTokenNotFound: '',
    logout: '',
};

type DataType = keyof typeof types;

export const appDataTypes = objectUtils.populateKeysToValue(types);

type Message = {
    type: DataType;
    data: any;
    dismiss: boolean;
};

export interface WebKit {
    messageHandlers: {
        appHandler?: {
            postMessage: (message: Message) => void;
        };
        eagleIDHandler?: {
            postMessage: (message: Message) => void;
        };
    };
}

export function fromApp(): boolean {
    return (
        !!window.webkit?.messageHandlers.eagleIDHandler ||
        !!window.webkit?.messageHandlers.appHandler ||
        reactNativeUtils.fromReactNativeApp()
    );
}

export function postAppData(type: DataType, data: any, dismiss: boolean = false) {
    const handler = window.webkit?.messageHandlers.appHandler || window.webkit?.messageHandlers.eagleIDHandler;
    if (handler) {
        handler.postMessage({
            type,
            data,
            dismiss,
        });
    } else if (reactNativeUtils.fromReactNativeApp()) {
        reactNativeUtils.postReactNativeAppData(type, data);
    }
}

export function showError(errorMessage: string) {
    postAppData(appDataTypes.error, errorMessage);
}
