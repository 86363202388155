export const PASSWORD_MAX_LENGTH = 72;

export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY';

export const POLICY_URL = 'https://www.screeningeagle.com/en/legal/data-privacy-policy';
export const TNC_URL = 'https://www.screeningeagle.com/terms-and-conditions';

export const RECAPTCHA_POLICY_URL = 'https://policies.google.com/privacy';
export const RECAPTCHA_TNC_URL = 'https://policies.google.com/terms';

export const SUPPORT_EMAIL = 'success@screeningeagle.com';
