import { CurrencyCode, LicensePrice } from '../types/license';

export function getPricingInfo(countryCode: string = '', pricing: LicensePrice[]) {
    const isCountryUS = countryCode === 'US';
    const fallbackCurrencyCode = pricing[0]?.currencyCode;
    const filteredPricing = isCountryUS ? pricing : pricing?.filter((entry) => entry.currencyCode !== CurrencyCode.USD);
    return {
        fallbackCurrencyCode,
        filteredPricing,
    };
}

export function getLicensePrice(pricing: LicensePrice[], currencyCode: string): LicensePrice | undefined {
    return pricing.find((val) => val.currencyCode === currencyCode);
}
