import { Action as ReduxAction } from 'redux';
import { objectUtils } from 'tds-common-fe';

const actionTypes = {
    INIT: '',

    LOGIN_SUCCESS: '',
    RECEIVED_USER_PROFILE: '',
    RECEIVED_COMPANY_PROFILE: '',
    RECEIVED_USER_LICENSE: '',
    RECEIVED_LICENSE_TIERS: '',
};

type ActionTypes = typeof actionTypes;

// Convert the values to be the same as the keys
const compiledActions = objectUtils.populateKeysToValue(actionTypes);

export type Action = ReduxAction<keyof ActionTypes>;

export interface PayloadAction<T> extends Action {
    payload: T;
}

export default compiledActions;
