import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FormItem, useFormCheckErrors, routeUtils, objectUtils } from 'tds-common-fe';

import * as appUtils from '../../../utils/appUtils';
import AppWrapper from '../AppWrapper';
import styles from './UserProfile.styl';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import FormattedMessage from '../../../localization/FormatMessage';
import AuthInput from '../../Authentication/Custom/AuthInput';
import AuthPasswordLabel from '../../Authentication/Custom/AuthPasswordLabel';
import EditProfileImage from '../ProfileImage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { RootState } from '../../../reducers';
import countries from '../../../resources/countries.json';
import * as userServices from '../../../api/userService';
import { FieldsType, FieldsKeys, rules } from './personalProfileRules';
import postMessenger from '../../../utils/PostMessenger';
import InitialsPlaceholder from './InitialsPlaceholder';
import { PASSWORD_MAX_LENGTH } from '../../../config/constants';
import PlanBadge from './PlanBadge';

class Item extends FormItem<FieldsType> {}

type MutableKey = 'firstName' | 'lastName' | 'companyName' | 'phoneNumber' | 'postcode' | 'address';
const mutableFields: MutableKey[] = ['firstName', 'lastName', 'companyName', 'phoneNumber', 'postcode', 'address'];
const requiredMutableFields: MutableKey[] = ['firstName', 'lastName', 'companyName'];
const passwordFields: FieldsKeys[] = ['password', 'passwordConfirm', 'passwordOld'];

const UserProfile: React.FunctionComponent = () => {
    const { formatMessage } = useFormatMessage();
    const [form] = Form.useForm();
    const { setFieldsValue, resetFields } = form;

    const params = routeUtils.parseQuery<{ edit: string; editPassword: string }>(location.search);
    const initEditable = params.edit === 'true';
    const initPasswordEditable = params.editPassword === 'true';

    // Start Component related code

    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const userLicense = useSelector((state: RootState) => state.profile.userLicense);
    const showPlanBadge = !userLicense.tier?.free && userLicense.active;

    const userID = userProfile.id;

    const inputs = useRef<HTMLInputElement[]>([]);
    const [editEnabled, setEditEnabled] = useState(initEditable);
    const [changePassword, setChangePassword] = useState(initPasswordEditable);
    const [submitting, setSubmitting] = useState(false);

    // For app to close webview
    const [dismiss, setDismiss] = useState(false);

    useEffect(() => {
        if (userProfile.id) {
            postMessenger.post({ type: 'personalProfileUpdated', data: userProfile, dismiss });
        }
    }, [userProfile, dismiss]);

    useEffect(() => {
        if (!editEnabled && userProfile.id) {
            setFieldsValue(userProfile);
        }
    }, [userProfile, editEnabled, initEditable, setFieldsValue]);

    const handleEndPasswordChange = useCallback(() => {
        setChangePassword(false);
        resetFields(passwordFields);
    }, [resetFields]);

    const handleSuccessMessage = (messageContent: string) => {
        if (postMessenger.parentOrigin) {
            postMessenger.postPopupMessage('success', messageContent);
        } else {
            message.success(messageContent);
        }
    };

    const fields = {
        firstName: (
            <Item
                label={<FormattedMessage id="SignUp.Field.FirstName.Placeholder" />}
                className={styles.form_item}
                name="firstName"
                rules={editEnabled ? rules.firstName : []}
                initialValue={userProfile.firstName}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.FirstName.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),

        lastName: (
            <Item
                label={<FormattedMessage id="SignUp.Field.LastName.Placeholder" />}
                className={styles.form_item}
                name="lastName"
                rules={editEnabled ? rules.lastName : []}
                initialValue={userProfile.lastName}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.LastName.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),

        email: (
            <Item
                label={<FormattedMessage id="Common.Email" />}
                className={styles.form_item}
                name="email"
                initialValue={userProfile.email}
            >
                <AuthInput
                    type="email"
                    placeholder={formatMessage({ id: 'SignUp.Field.Email.Placeholder' })}
                    inputs={inputs}
                    disabled
                    style={{ WebkitAppearance: 'none' }}
                />
            </Item>
        ),
        companyName: (
            <Item
                label={<FormattedMessage id="SignUp.Field.CompanyName" />}
                className={styles.form_item}
                name="companyName"
                rules={editEnabled ? rules.companyName : []}
                initialValue={userProfile.companyName}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.CompanyName.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
        country: (
            <Item
                label={<FormattedMessage id="SignUp.Field.Country" />}
                className={styles.form_item}
                style={{ paddingBottom: 4 }}
                initialValue={userProfile.countryCode}
            >
                <AuthInput
                    value={countries.find((country) => country.code === userProfile.countryCode)?.name ?? ''}
                    disabled
                />
            </Item>
        ),
        phoneNumber: (
            <Item
                label={<FormattedMessage id="SignUp.Field.PhoneNumber.Placeholder" />}
                className={styles.form_item}
                name="phoneNumber"
                rules={editEnabled ? rules.phoneNumber : []}
                initialValue={userProfile.phoneNumber}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.PhoneNumber.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
        postcode: (
            <Item
                label={<FormattedMessage id="Profile.Field.Postcode" />}
                className={styles.form_item}
                name="postcode"
                initialValue={userProfile.postcode}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'Profile.Field.Postcode' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
        address: (
            <Item
                label={<FormattedMessage id="Contact.Field.Address" />}
                className={styles.form_item}
                name="address"
                initialValue={userProfile.address}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'Contact.Field.Address' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),

        fakePassword: (
            <Item
                label={
                    <>
                        <FormattedMessage id="Login.Field.Password" />
                        {editEnabled && (
                            <span className={styles.change_password} onClick={() => setChangePassword(true)}>
                                <FormattedMessage id="Profile.ChangePassword.Title" />
                            </span>
                        )}
                    </>
                }
                className={styles.form_item}
            >
                <AuthInput authInputType="password" placeholder="••••••••••" inputs={inputs} disabled />
            </Item>
        ),
        password: (
            <Item
                label={<FormattedMessage id="Profile.Password.New.Title" />}
                className={styles.form_item}
                name="password"
                dependencies={['email', 'firstName', 'lastName']}
                rules={editEnabled ? rules.password : []}
            >
                <AuthInput
                    addonAfter={editEnabled ? <AuthPasswordLabel /> : null}
                    authInputType="password"
                    placeholder={formatMessage({ id: 'Profile.Password.New.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                    maxLength={PASSWORD_MAX_LENGTH}
                />
            </Item>
        ),
        passwordOld: (
            <Item
                label={
                    <>
                        <FormattedMessage id="Profile.Password.Old.Title" />
                        <span className={styles.change_password} onClick={handleEndPasswordChange}>
                            {formatMessage({ id: 'Common.Cancel' }).toLowerCase()}
                        </span>
                    </>
                }
                className={styles.form_item}
                name="passwordOld"
                rules={rules.passwordOld}
            >
                <AuthInput
                    authInputType="password"
                    placeholder={formatMessage({ id: 'Profile.Password.Old.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                    maxLength={PASSWORD_MAX_LENGTH}
                />
            </Item>
        ),
        passwordConfirm: (
            <Item
                label={<FormattedMessage id="Profile.Password.Confirm.Title" />}
                className={styles.form_item}
                name="passwordConfirm"
                dependencies={['password']}
                rules={rules.passwordConfirm}
            >
                <AuthInput
                    authInputType="password"
                    placeholder={formatMessage({ id: 'Profile.Password.Confirm.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                    maxLength={PASSWORD_MAX_LENGTH}
                />
            </Item>
        ),
    };

    const isApp = appUtils.fromApp();

    const handleSubmit = useCallback(
        async (values: { [Key in FieldsKeys]: string }) => {
            if (!userID) {
                return;
            }
            const oldUserData = objectUtils.trimObjectWithKeys(userProfile, mutableFields);
            const newUserData = objectUtils.trimObjectWithKeys(values, mutableFields);
            const shouldProfileUpdate = !isEqual(oldUserData, newUserData);
            const shouldPasswordUpdate = !!values.password;

            if (shouldPasswordUpdate || shouldProfileUpdate) {
                setSubmitting(true);
            }

            if (shouldPasswordUpdate) {
                try {
                    await userServices.changeUserPassword({
                        passwordOld: values.passwordOld,
                        password: values.password,
                        passwordConfirm: values.passwordConfirm,
                    });
                    handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.ChangePassword' }));
                    handleEndPasswordChange();
                } catch {
                    setSubmitting(false);
                    return;
                    // break submission and continue the form if password update failed
                }
            }

            if (shouldProfileUpdate) {
                try {
                    await userServices.updateUserProfile(userID.toString(), newUserData);
                    handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.UserProfile' }));
                    if (isApp) {
                        setDismiss(true);
                    }
                } catch {
                    setSubmitting(false);
                    return;
                }
            } else if (isApp) {
                setDismiss(true);
            }

            setSubmitting(false);
            setEditEnabled(false);
        },
        [userID, userProfile, formatMessage, handleEndPasswordChange, isApp]
    );

    const handleUpdateUserPicture = useCallback(
        (file: RcFile) => {
            if (!userID) {
                return;
            }
            userServices.uploadUserProfilePicture(userID.toString(), file).then(() => {
                handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.ProfilePicture' }));
            });
        },
        [userID, formatMessage]
    );

    const handleRemoveUserPicture = useCallback(() => {
        if (!userID) {
            return;
        }
        userServices.removeUserProfilePicture(userID.toString()).then(() => {
            handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.ProfilePicture' }));
        });
    }, [userID, formatMessage]);

    const initialsImage = (
        <InitialsPlaceholder firstName={userProfile.firstName} lastName={userProfile.lastName} size={88} />
    );

    const requiredFields: FieldsKeys[] = [...requiredMutableFields, ...(changePassword ? passwordFields : [])];
    const { checkErrors } = useFormCheckErrors(form, requiredFields);

    const buttonArea = (
        <div className={styles.button_area}>
            {editEnabled ? (
                <Item shouldUpdate>
                    {() => (
                        <AnalyticsButton
                            type="primary"
                            htmlType="submit"
                            className={styles.submit_button}
                            disabled={submitting || checkErrors()}
                        >
                            <FormattedMessage id="Common.Done" />
                        </AnalyticsButton>
                    )}
                </Item>
            ) : (
                <AnalyticsButton type="primary" className={styles.submit_button} onClick={() => setEditEnabled(true)}>
                    <FormattedMessage id="Common.Edit" />
                </AnalyticsButton>
            )}
        </div>
    );

    const UserInfoForm = () => {
        return (
            <div className={isApp ? styles.inner_frame_basic : styles.inner_frame} style={{ paddingTop: 50 }}>
                {showPlanBadge && <PlanBadge top={24}>{userLicense.tier?.name}</PlanBadge>}
                <EditProfileImage
                    src={userProfile.pictureURL}
                    shape="round"
                    onUpload={handleUpdateUserPicture}
                    onRemove={handleRemoveUserPicture}
                    placeholder={initialsImage}
                    title={formatMessage({ id: 'Profile.ProfileImage.Title' })}
                    fullImage
                />
                <Form form={form} layout="vertical" className={styles.form} onFinish={handleSubmit as any}>
                    {buttonArea}

                    <div className={styles.inline_container}>
                        {fields.firstName}
                        <div className={styles.inline_space} />
                        {fields.lastName}
                    </div>
                    {fields.email}
                    {changePassword ? (
                        <>
                            {fields.passwordOld}
                            {fields.password}
                            {fields.passwordConfirm}
                        </>
                    ) : (
                        fields.fakePassword
                    )}
                    <div className={styles.inline_container}>
                        {fields.companyName}
                        <div className={styles.inline_space} />
                        {fields.country}
                    </div>
                    <div className={styles.inline_container}>
                        {fields.phoneNumber}
                        <div className={styles.inline_space} />
                        {fields.postcode}
                    </div>
                    {fields.address}
                </Form>
            </div>
        );
    };

    return (
        <AppWrapper className={isApp ? styles.app_wrapper_basic : styles.app_wrapper}>
            {userID ? (
                <UserInfoForm />
            ) : (
                <div className={isApp ? styles.inner_frame_basic : styles.inner_frame}>
                    <FormattedMessage id="Error.Profile.FetchPersonalProfile" />
                </div>
            )}
        </AppWrapper>
    );
};

export default UserProfile;
