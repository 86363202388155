import React, { useState } from 'react';
import { routeUtils } from 'tds-common-fe';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import FormattedMessage from '../../../localization/FormatMessage';
import AuthBasics from '../Custom/AuthBasics';
import Button from '../../AnalyticsComponents/Button';
import * as authService from '../../../api/authService';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import styles from './SignUpDone.styl';

const SignUpDone: React.FunctionComponent = () => {
    const { email = '' } = routeUtils.parseQuery<{ email: string }>(location.search);
    const { formatMessage } = useFormatMessage();
    const title = <FormattedMessage id="EmailVerification.ResendSuccessfulTitle" />;
    const description = (
        <div style={{ whiteSpace: 'pre-wrap' }}>
            <FormattedMessage id="EmailVerification.SignUpDone.Body" values={{ email: <b>{email}</b> }} />
        </div>
    );
    const [isResending, setIsResending] = useState<undefined | boolean>();

    const handleResend = async () => {
        setIsResending(true);
        try {
            await authService.resendEmailVerification({ email });
            setIsResending(false);
        } catch (err) {
            message.error(formatMessage({ id: 'EmailVerification.SignUpDone.Failed' }));
            setIsResending(undefined);
        }
    };

    return (
        <>
            <AuthBasics title={title} description={description} centerDescription />
            <div className={styles.subtitle}>
                <span style={{ marginRight: 16 }}>
                    <FormattedMessage id="EmailVerification.SignUpDone.DidntReceive" />
                </span>
                <Button type="link" onClick={handleResend} disabled={isResending !== undefined} style={{ padding: 0 }}>
                    <FormattedMessage
                        id={
                            isResending === undefined
                                ? 'Common.Resend'
                                : isResending
                                ? 'Common.Resending'
                                : 'EmailVerification.SignUpDone.Resent'
                        }
                    />
                    {isResending && (
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 12, marginLeft: 8 }} spin />} />
                    )}
                </Button>
            </div>
        </>
    );
};

export default SignUpDone;
