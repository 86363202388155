import { Reducer } from 'redux';
import actionTypes, { Action, PayloadAction } from '../actions/actionTypes';
import { LoginResponse } from '../types';

export interface AuthenticationState {
    login?: LoginResponse;
}

export const initialState: AuthenticationState = {};

const authentication: Reducer<AuthenticationState, Action> = (state = initialState, action): AuthenticationState => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS: {
            const { payload } = action as PayloadAction<LoginResponse>;
            return {
                ...state,
                login: payload,
            };
        }
    }
    return state;
};

export default authentication;
