import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import FormattedMessage from '../../../../localization/FormatMessage';
import colors from '../../../../styles/colors.json';

const DiscountBadge: React.FunctionComponent<{ percentage: number; style?: CSSProperties }> = (props) => {
    const { percentage, style } = props;
    const badgeContainer = useRef<HTMLDivElement>(null);

    const [badgeShape, setBadgeShape] = useState('');

    useEffect(() => {
        const height = badgeContainer.current?.clientHeight ?? 24;
        const width = badgeContainer.current?.clientWidth ?? 115;
        const d = [
            'M 0 0',
            `L ${height / 3} ${height / 2}`,
            `L 0 ${height}`,
            `L ${width} ${height}`,
            `L ${width - height / 3} ${height / 2}`,
            `L ${width} 0`,
            'L 0 0',
        ].join(' ');
        setBadgeShape(d);
    }, []);

    return (
        <div
            ref={badgeContainer}
            style={{ position: 'relative', padding: '0 25px', textAlign: 'center', ...(style ?? {}) }}
        >
            <svg width="100%" height="100%" style={{ position: 'absolute', zIndex: -1, left: 0, top: 0 }}>
                {badgeShape && <path d={badgeShape} fill={colors.light_yellow} />}
            </svg>
            <div style={{ color: colors.yellow, fontSize: 14, lineHeight: '24px', fontWeight: 500 }}>
                <FormattedMessage id="License.Subscription.Discount" values={{ percentage }} />
            </div>
        </div>
    );
};

export default DiscountBadge;
