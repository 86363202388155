import * as appUtils from './appUtils';
import { isConsumerValid } from '../config/consumers';

const types = {
    personalProfileUpdated: '',
    companyProfileUpdated: '',
    openPurchaseForm: '',
    openContactForm: '',
    userLicenseUpdated: '',
    closeIframe: '',
    renderComplete: '',
    logout: '',
    setUp2FA: '',
    reset2FA: '',
    viewRecoveryKeys2FA: '',
};

export type PopupMessageType = 'warning' | 'success' | 'error';
export type PostMessageDataType = keyof typeof types;

class PostMessenger {
    parentOrigin: string = '';

    setParentOrigin = (domain: string) => {
        if (isConsumerValid(domain)) {
            this.parentOrigin = domain;
        } else {
            // eslint-disable-next-line no-console
            console.error(`${domain} is not a valid consumer for Eagle ID`);
        }
    };

    post({
        type,
        data = undefined,
        dismiss = false,
        isPopup = false,
    }: {
        type: PostMessageDataType;
        data?: any;
        dismiss?: boolean;
        isPopup?: boolean;
    }) {
        if (isPopup) {
            window.opener.parent.postMessage({ type, data }, this.parentOrigin);
            window.close();
        } else if (this.parentOrigin !== '') {
            window.parent.postMessage({ type, data }, this.parentOrigin);
        }

        if (appUtils.fromApp() && isAppDataType(type)) {
            appUtils.postAppData(type, data, dismiss);
        }
    }

    postPopupMessage(messageType: PopupMessageType, messageContent: string) {
        if (this.parentOrigin !== '') {
            const popupMessage = {
                type: 'popupMessage',
                data: {
                    messageType,
                    messageContent,
                },
            };
            window.parent.postMessage(popupMessage, this.parentOrigin);
        } else {
            // eslint-disable-next-line no-console
            console.error('Missing origin for PostMessenger');
        }
    }

    postPageHeight(height?: number) {
        if (this.parentOrigin !== '') {
            window.parent.postMessage(
                { type: 'postPageHeight', data: height ?? window.document.body.scrollHeight },
                this.parentOrigin
            );
        }
    }
}

const postMessenger = new PostMessenger();

export default postMessenger;

function isAppDataType(type: any): type is typeof appUtils.appDataTypes[keyof typeof appUtils.appDataTypes] {
    return (appUtils.appDataTypes as { [key: string]: any })[type] !== undefined;
}
