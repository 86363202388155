import { useEffect } from 'react';

const useDisableLoading = (setLoading: (loading: boolean) => void) => {
    useEffect(() => {
        const handleLoading = () => {
            setLoading(false);
        };

        window.addEventListener('pageshow', handleLoading);
        window.addEventListener('focus', handleLoading);

        return () => {
            window.removeEventListener('pageshow', handleLoading);
            window.removeEventListener('focus', handleLoading);
        };
    }, [setLoading]);
};

export default useDisableLoading;
