import config from '../config';

export const EAGLE_ID_API = `${config.EAGLE_ID_DOMAIN}${config.EAGLE_ID_API_VERSION}`;

const urls = {
    LOGIN: `${EAGLE_ID_API}/oauth/login`,
    LOGOUT: `${EAGLE_ID_API}/oauth/logout`,
    VERIFY_PASSWORD: `${EAGLE_ID_API}/oauth/password/confirm/`,
    OAUTH_AUTHORIZATION: `${EAGLE_ID_API}/oauth/authorization`,
    OAUTH_SOCIAL_LOGIN: `${EAGLE_ID_API}/oauth/social/{accountType}`,

    // 2fa
    TWO_FA_SET_UP_CODE: `${EAGLE_ID_API}/oauth/2fa/setup/code/`,
    TWO_FA_SET_UP_VERIFY: `${EAGLE_ID_API}/oauth/2fa/setup/verify/`,
    TWO_FA_SET_UP_RECOVERY_KEY: `${EAGLE_ID_API}/oauth/2fa/setup/recovery/key/`,
    TWO_FA_LOGIN: `${EAGLE_ID_API}/oauth/2fa/verify/`,
    TWO_FA_RECOVERY_KEY: `${EAGLE_ID_API}/oauth/2fa/recovery/key/`,
    TWO_FA_RESET: `${EAGLE_ID_API}/oauth/2fa/reset/`,

    // multiple devices
    CONCURRENT_PROCEED: `${EAGLE_ID_API}/oauth/concurrent/proceed/`,
    CONCURRENT_CONFIRM: `${EAGLE_ID_API}/oauth/concurrent/confirm/`,

    SIGNUP: `${EAGLE_ID_API}/user`,
    SIGNUP_SOCIAL_LOGIN: `${EAGLE_ID_API}/user/social/`,
    EMAIL_VERIFICATION: `${EAGLE_ID_API}/user/verify/{token}`,
    RESEND_EMAIL_VERIFICATION: `${EAGLE_ID_API}/user/verify/resend`,
    PASSWORD_FORGOT: `${EAGLE_ID_API}/user/password/forgot`,
    PASSWORD_RESET: `${EAGLE_ID_API}/user/password/reset`,
    PASSWORD_CHANGE: `${EAGLE_ID_API}/user/password/change`,
    USER_LICENSE: `${EAGLE_ID_API}/user/license/`,
    ACTIVATE_LICENSE: `${EAGLE_ID_API}/user/license/activate`,
    DELETE_ACCOUNT: `${EAGLE_ID_API}/user/delete`,
    DELETE_ACCOUNT_CONFIRMATION: `${EAGLE_ID_API}/user/delete/{token}`,

    LICENSE_TIER: `${EAGLE_ID_API}/license/tier`,

    USER_PROFILE: `${EAGLE_ID_API}/profile/{userID}`,
    USER_SETUP_PROFILE: `${EAGLE_ID_API}/profile/`,
    USER_PROFILE_PICTURE: `${EAGLE_ID_API}/profile/{userID}/picture`,

    COMPANY_LIST: `${EAGLE_ID_API}/company`,
    COMPANY_PROFILE: `${EAGLE_ID_API}/company/{companyID}`,
    COMPANY_PICTURE: `${EAGLE_ID_API}/company/{companyID}/picture`,

    REQUEST_SALES_CONTACT: `${EAGLE_ID_API}/license/request/contact`,
    REQUEST_UPGRADE_LICENSE: `${EAGLE_ID_API}/license/request/upgrade`,
};

export const RECAPTCHA_URL = 'https://www.recaptcha.net/recaptcha/api.js';

export default urls;
