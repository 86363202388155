import React, { useEffect, useState } from 'react';
import { routeUtils } from 'tds-common-fe';
import { Input, message, Spin } from 'antd';
import { useSelector } from 'react-redux';
import styles from './SwitchAccount.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import Button from '../../AnalyticsComponents/Button';
import analytics from '../../../analytics/index';
import urls from '../../../api/urls';
import * as userService from '../../../api/userService';
import { RootState } from '../../../reducers';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { isProductExisting, productNameMap } from '../../../utils/productUtils';

interface SwitchAccountParams {
    redirect_uri: string;
    product: string;
    userID: string;
}

const SwitchAccount: React.FunctionComponent = () => {
    const params = routeUtils.parseQuery<SwitchAccountParams>(location.search);
    const { redirect_uri: redirectURI, product, userID } = params;
    const logoutPath = routeUtils.makeQueryPath(urls.LOGOUT, { redirect_uri: redirectURI });
    const [isLoading, setIsLoading] = useState(false);
    const userEmail = useSelector((state: RootState) => state.profile.userProfile.email);
    const { formatMessage } = useFormatMessage();

    useEffect(() => {
        if (!userID) return;
        userService.getUserProfile(userID, () => message.error(formatMessage({ id: 'SwitchAccount.Error' })));
    }, [formatMessage, userID]);

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <FormattedMessage id="SwitchAccount.Title" />
            </div>
            <div className={styles.center_modal_body}>
                <FormattedMessage
                    id="SwitchAccount.Body"
                    values={{
                        product: (
                            <FormattedMessage
                                id={isProductExisting(product) ? productNameMap[product] : 'ProductName.EagleID'}
                            />
                        ),
                    }}
                />
            </div>
            <div className={styles.email_container}>
                <div className={styles.disabled_input_title}>
                    <FormattedMessage id="ProductName.EagleID" />
                </div>
                {userEmail ? (
                    <div>
                        <Input className={styles.input} disabled type="text" size="large" value={userEmail} />
                    </div>
                ) : (
                    <div className={styles.center}>
                        <Spin size="small" />
                    </div>
                )}
                <div className={styles.button_container}>
                    <Button
                        type="primary"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={() => {
                            setIsLoading(true);
                            analytics.logSwitchAccount(product ?? '');
                            window.open(logoutPath, '_self');
                        }}
                        size="large"
                    >
                        <FormattedMessage id="SwitchAccount.SignInDifferentAccount" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SwitchAccount;
