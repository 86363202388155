import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { FormItem, NonOptionalKeys, routeUtils, useFormCheckErrors } from 'tds-common-fe';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppWrapper from '../../AppWrapper';
import styles from './ContactSales.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import AuthTextArea from '../../../Authentication/Custom/AuthTextArea';
import ContactFields from '../Form/ContactFields';
import { FieldsType as ContactFieldsType } from '../Form/contactFieldsRules';
import { APP_ROUTES, getRouteWithUserID } from '../../../Routes/urls';
import { usePostPageHeight } from '../PurchaseForm/usePostPageHeight';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import * as licenseService from '../../../../api/licenseService';
import { getFormattedPhoneNumber } from '../../../../utils/phoneUtils';
import { ProductCode, ProductCodeName } from '../../../../types/license';
import { RootState } from '../../../../reducers';
import useRecaptcha from '../../../../hooks/useRecaptcha';
import { getCaptchaToken } from '../../../../utils/recaptchaUtils';
import RecaptchaTNC from '../../../Shared/RecaptchaTNC';
import colors from '../../../../styles/colors.json';
import analytics from '../../../../analytics/index';
import { EventName } from '../../../../analytics/analyticsConstants';
import { loadingPlaceholder } from '../../index';

interface FieldsType extends ContactFieldsType {
    message: string;
}

export const requiredFields: NonOptionalKeys<FieldsType>[] = [
    'firstName',
    'lastName',
    'email',
    'companyName',
    'countryCode',
    'message',
];

class Item extends FormItem<FieldsType> {}

interface ContactSalesParams {
    showTitle: boolean;
    showProduct: boolean;
    product: ProductCode;
    isUpgradeRequest?: boolean;
    showTiers?: boolean; // show tiers selection
    tier?: string; // tier to default to
}

const ContactSales: React.FunctionComponent = () => {
    const [form] = Form.useForm();
    const { formatMessage } = useFormatMessage();

    const history = useHistory();
    const { userID } = useParams<{ userID: string }>();
    const {
        showTitle = 'true',
        showProduct = 'false',
        product,
        isUpgradeRequest = 'false',
        showTiers = 'false',
        tier,
    } = routeUtils.parseQuery<ContactSalesParams>(location.search);

    const { email } = useSelector((state: RootState) => {
        return state.profile.userProfile;
    });

    const inputs = useRef<HTMLInputElement[]>([]);
    const [useDefaultEmail, setUseDefaultEmail] = useState(true);

    const { containerRef, scrollRef } = usePostPageHeight();
    const [loadingTiers, setLoadingTiers] = useState(true);

    useEffect(() => {
        if (showTiers === 'true' && isUpgradeRequest === 'true') {
            // only support showing current product's tiers for now
            licenseService.getLicenseTiers({ product }).finally(() => {
                setLoadingTiers(false);
            });
        } else {
            setLoadingTiers(false);
        }
    }, [isUpgradeRequest, product, showTiers]);

    useRecaptcha();

    const handleSubmit = async (values: { [Key in keyof FieldsType]: string }) => {
        const { phone, firstName, lastName, message, email, companyName, countryCode, product, tier } = values;
        const contactNumber = phone ? getFormattedPhoneNumber(phone, countryCode) : phone;
        const upgradeRequest = isUpgradeRequest === 'true';
        const token = await getCaptchaToken(upgradeRequest ? 'upgrade_request' : 'contact_sales');
        const requestBody = {
            firstName,
            lastName,
            message,
            countryCode,
            contactNumber,
            companyName,
            contactEmail: email,
            captcha: token,
            tier,
        };
        const apiRequest = () =>
            upgradeRequest
                ? licenseService.requestUpgradeLicense(requestBody, product as ProductCode)
                : licenseService.requestSalesContact(requestBody, product as ProductCode);
        apiRequest()
            .then(() => {
                // Navigate to success page
                const path = getRouteWithUserID(APP_ROUTES.CONTACT_SALES_DONE, userID);
                analytics.logEventWithName(upgradeRequest ? EventName.UPGRADE_SUBMIT : EventName.CONTACT_SALES);
                history.push(path);
            })
            .catch(() => {});
    };

    const { checkErrors } = useFormCheckErrors(form, requiredFields);

    return loadingTiers ? (
        loadingPlaceholder
    ) : (
        <AppWrapper className={styles.app_wrapper}>
            <div className={styles.container} ref={containerRef}>
                {showTitle !== 'false' && (
                    <div className={styles.form_section}>
                        <h1 className={styles.form_title}>
                            <FormattedMessage id="Contact.Header.Title" />
                        </h1>
                    </div>
                )}
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit as any}
                    className={styles.form}
                    initialValues={{
                        message: formatMessage(
                            { id: 'Contact.Field.Message.DefaultValueProduct' },
                            { product: product ? ProductCodeName[product.toUpperCase() as ProductCode] : product }
                        ),
                    }}
                    requiredMark={false}
                >
                    <div className={styles.form_scrollable_area} ref={scrollRef}>
                        <div className={styles.form_section}>
                            <p>
                                <FormattedMessage id="Contact.Header.Message" />
                            </p>
                            <p>
                                <FormattedMessage id="Contact.Email.EagleID.Label" />
                                <b className={styles.email_section}> {email}</b>
                            </p>
                            <ContactFields
                                inputs={inputs}
                                form={form}
                                useDefaultEmail={useDefaultEmail}
                                onSetUseDefaultEmail={setUseDefaultEmail}
                                emailCheckboxMessage="Contact.Email.EagleID.SameEmail"
                                emailSettings={{
                                    label: 'Contact.Field.Email.Label',
                                    placeholder: 'Contact.Field.Email.Label',
                                    emptyErrorMessage: 'Contact.Field.Email.Error.Empty',
                                }}
                                showProduct={showProduct === 'true'}
                                product={product as ProductCode}
                                showTiers={showTiers === 'true'}
                                tier={tier}
                            />
                            <Item
                                label={<FormattedMessage id="Contact.Field.Message" />}
                                className={styles.form_item}
                                name="message"
                                rules={[
                                    {
                                        required: true,
                                        message: <FormattedMessage id="Contact.Field.Message.Error.Empty" />,
                                    },
                                ]}
                            >
                                <AuthTextArea
                                    placeholder={formatMessage({ id: 'Contact.Field.Message.Placeholder' })}
                                    inputs={inputs}
                                    rows={5}
                                />
                            </Item>
                            <div>
                                <RecaptchaTNC buttonStyles={{ border: 0, color: colors.primary_bright }} />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.actions_area} fill_form_row`}>
                        <Item shouldUpdate style={{ margin: 0 }}>
                            {() => (
                                <div className={styles.button_wrapper_container}>
                                    <div className={styles.button_wrapper}>
                                        <AnalyticsButton
                                            className={styles.button_text}
                                            type="primary"
                                            htmlType="submit"
                                            disabled={checkErrors()}
                                        >
                                            <FormattedMessage id="Common.Submit" />
                                        </AnalyticsButton>
                                    </div>
                                </div>
                            )}
                        </Item>
                    </div>
                </Form>
            </div>
        </AppWrapper>
    );
};

export default ContactSales;
