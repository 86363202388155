import React, { useEffect, useState } from 'react';
import RecoveryKey from '../shared/RecoveryKey';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA, SetUpCategories } from '../../../../analytics/analyticsConstants';
import { getTwoFASetUpRoute, SetUpFlowStage } from '../../../Routes/urls';
import { getTwoFASetUpRecoveryKey } from '../../../../api/authService';

interface SetUpRecoveryProps {
    setShowError: () => void;
}

const SetUpRecovery: React.FunctionComponent<SetUpRecoveryProps> = ({ setShowError }) => {
    const [recoveryKeys, setRecoveryKeys] = useState<string[]>([]);

    useEffect(() => {
        getTwoFASetUpRecoveryKey()
            .then((res) => setRecoveryKeys(res.data.map((obj) => obj.key)))
            .catch(() => setShowError());
    }, [setShowError]);

    return (
        <RecoveryKey
            onDownload={() => {
                analytics.logSetUpTwoFA(SetUpCategories.recoveryKey, ButtonsTwoFA.download);
            }}
            doneRedirectURL={`${location.origin}${getTwoFASetUpRoute(SetUpFlowStage.complete)}${location.search}`}
            recoveryKeys={recoveryKeys}
        />
    );
};

export default SetUpRecovery;
