import config from './index';

const consumers = new Set([config.INSPECT_DOMAIN, config.WORKSPACE_DOMAIN]);

export function isConsumerValid(consumer: string): boolean {
    // Always valid for dev in localhost
    return (config.APP_ENV === 'dev' && consumer.includes('localhost')) || consumers.has(consumer);
}

export default consumers;
