import React from 'react';
import { classNames } from '../../../utils/classUtils';
import Link from '../../Routes/Link';
import * as urls from '../../Routes/urls';
import styles from './AuthBasics.styl';

interface AuthBasicsProps {
    title: React.ReactNode;
    description?: React.ReactNode;
    centerDescription?: boolean;
    centerTitle?: boolean;
    signInLinkText?: React.ReactNode;
}

const AuthBasics: React.FunctionComponent<AuthBasicsProps> = (props) => {
    const { title, description, centerDescription, signInLinkText, centerTitle } = props;
    return (
        <>
            <div className={classNames(styles.auth_title, centerTitle && styles.center_description)}>{title}</div>
            {description && (
                <div className={classNames(styles.auth_description, centerDescription && styles.center_description)}>
                    {description}
                </div>
            )}
            {signInLinkText && (
                <Link to={urls.AUTH_ROUTES.LOGIN} size="large">
                    {signInLinkText}
                </Link>
            )}
        </>
    );
};

export default AuthBasics;
