import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox } from 'antd';
import { routeUtils } from 'tds-common-fe';
import VerificationCode, { VerificationCodeFlow } from '../shared/VerificationCode';
import FormattedMessage from '../../../../localization/FormatMessage';
import styles from './Login.styl';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { TWO_FA_ROUTES } from '../../../Routes/urls';
import URL from '../../../../api/urls';
import { getScreeningEagleHeaders } from '../../../../api/headerUtils';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA } from '../../../../analytics/analyticsConstants';
import GoBackToSignIn from './GoBackToSignIn';

const LoginTwoFA: React.FunctionComponent = () => {
    const history = useHistory();
    const [trustDevice, setTrustDevice] = useState(false);
    const [disableGoBackButton, setDisableGoBackButton] = useState(false);
    const htmlFormRef = useRef<HTMLFormElement>(null);
    const { error, ...queryParams } = routeUtils.parseQuery<{ error?: string }>(location.search);

    const actionURL = routeUtils.makeQueryPath(URL.TWO_FA_LOGIN, {
        ...getScreeningEagleHeaders(),
        ...queryParams,
    });
    const loginRecoveryKeyPath = routeUtils.makeQueryPath(TWO_FA_ROUTES.LOGIN_RECOVERY_KEY, { ...queryParams });

    const handleSubmit = useCallback(
        (code: string) => {
            analytics.logLoginTwoFA(ButtonsTwoFA.verify);
            const form = htmlFormRef.current;
            if (form !== null) {
                const codeInput: HTMLInputElement | null = form.querySelector('[name="code"]');
                const trustDeviceInput: HTMLInputElement | null = form.querySelector('[name="trust"]');
                if (codeInput) {
                    codeInput.value = code;
                }
                if (trustDeviceInput && trustDevice) {
                    trustDeviceInput.checked = trustDevice;
                    trustDeviceInput.value = '1';
                }
                setDisableGoBackButton(true);
                form.submit();
            }
        },
        [trustDevice]
    );

    const footer = (
        <div className={styles.footer_container}>
            <Checkbox
                checked={trustDevice}
                onChange={(e) => {
                    analytics.logLoginTwoFA(ButtonsTwoFA.trustDevice);
                    setTrustDevice(e.target.checked);
                }}
            >
                <FormattedMessage id="2FA.Login.Footer.TrustDevice" />
            </Checkbox>
            <AnalyticsButton
                type="link"
                onClick={() => {
                    analytics.logLoginTwoFA(ButtonsTwoFA.authCodeUnavailable);
                    history.push(loginRecoveryKeyPath);
                }}
            >
                <FormattedMessage id="2FA.Login.Footer.AuthenticationCodeUnavailable" />
            </AnalyticsButton>
        </div>
    );

    return (
        <div className={styles.base_container}>
            <VerificationCode
                onClick={handleSubmit}
                verificationCodeFlow={VerificationCodeFlow.login}
                footer={footer}
            />
            <GoBackToSignIn
                queryParams={queryParams}
                disabled={disableGoBackButton}
                setDisabled={setDisableGoBackButton}
            />
            <form ref={htmlFormRef} action={actionURL} method="POST" className={styles.hidden_form}>
                <input name="code" type="password" autoComplete="off" tabIndex={-1} />
                <input name="trust" type="checkbox" autoComplete="off" tabIndex={-1} />
            </form>
        </div>
    );
};

export default LoginTwoFA;
