// Modified from "antd-img-crop"
// Author: nanxiaobei <nanxiaobei@gmail.com> (https://github.com/nanxiaobei)
// Homepage: https://github.com/nanxiaobei/antd-img-crop,

import React, { useState, useCallback, useRef, forwardRef, Dispatch, SetStateAction } from 'react';
import { message, Modal, Slider } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { RcFile } from 'antd/es/upload/interface';
import Cropper from 'react-easy-crop';
import './index.global.styl';
import { isMaliciousFile } from 'tds-common-fe/src/lib/utils/fileUtils';
import { getHeicFile, limitImageSize } from '../../../utils/fileUtils';
import { squareImage } from './squareImage';
import { useFormatMessage } from '../../../localization/useFormatMessage';

const pkg = 'antd-img-crop';
const MEDIA_CLASS = `${pkg}-media`;
const CROP_MASK_CLASS = `${pkg}-crop-mask`;

const MIN_ZOOM = 1;
const MAX_ZOOM = 5;
const ZOOM_STEP = 0.1;

type GetContainerFunc = () => HTMLElement;
type BeforeUploadValueType = void | boolean | string | Blob | File | {};

export declare type Area = {
    width: number;
    height: number;
    x: number;
    y: number;
};

export interface ImgCropProps {
    shape?: 'rect' | 'round';
    grid?: boolean;
    zoom?: boolean;
    beforeCrop?: (file: File, fileList: File[]) => boolean;
    modalTitle?: string;
    modalWidth?: number | string;
    modalOk?: string;
    modalCancel?: string;
    children: React.ReactNode;
    getContainer?: string | HTMLElement | GetContainerFunc | false | null;
    limitSize?: number;
    fullImage?: boolean;
}

interface EasyCropProps {
    src: string;
    originalAspect?: number;
    shape?: 'rect' | 'round';
    grid?: boolean;
    hasZoom: boolean;
    zoomVal: number;
    setZoomVal: Dispatch<SetStateAction<number>>;
    onComplete: (croppedAreaPixels: Area) => void;
    limitSize?: number;
}

const EasyCrop = forwardRef<Cropper, EasyCropProps>((props, ref) => {
    const { src, originalAspect, shape, grid, hasZoom, zoomVal, setZoomVal, onComplete } = props;

    const [crop, setCrop] = useState({ x: 0, y: 0 });

    const handleSetCrop = (crop: { x: number; y: number }) => {
        if (originalAspect && typeof ref !== 'function' && originalAspect !== 1) {
            const { width = 0, height = 0 } = ref?.current?.mediaSize ?? {};
            const { x, y } = crop;

            const XRange = width * (zoomVal - 1);
            const XVoid = (1 - originalAspect) * width * zoomVal;
            const YRange = height * (zoomVal - 1);
            const YVoid = (1 - 1 / originalAspect) * height * zoomVal;
            const limitX = Math.max(0, (XRange - XVoid) / 2);
            const limitY = Math.max(0, (YRange - YVoid) / 2);

            setCrop({
                x: Math.abs(x) > limitX ? limitX * (x > 0 ? 1 : -1) : x,
                y: Math.abs(y) > limitY ? limitY * (y > 0 ? 1 : -1) : y,
            });
        } else {
            setCrop(crop);
        }
    };

    const onCropComplete = useCallback(
        (croppedArea: Area, croppedAreaPixels: Area) => {
            onComplete(croppedAreaPixels);
        },
        [onComplete]
    );

    return (
        <Cropper
            ref={ref}
            image={src}
            aspect={1}
            cropShape={shape}
            showGrid={grid}
            zoomWithScroll={hasZoom}
            crop={crop}
            zoom={zoomVal}
            maxZoom={MAX_ZOOM}
            onCropChange={handleSetCrop}
            onZoomChange={setZoomVal}
            onCropComplete={onCropComplete}
            classes={{
                containerClassName: `${pkg}-container`,
                mediaClassName: MEDIA_CLASS,
                cropAreaClassName: CROP_MASK_CLASS,
            }}
        />
    );
});

const ImgCrop = forwardRef<Cropper, ImgCropProps>((props, ref) => {
    const {
        shape,
        grid,
        zoom,
        beforeCrop,
        modalTitle,
        modalWidth,
        modalOk,
        modalCancel,
        children,
        getContainer,
        limitSize,
        fullImage,
    } = props;

    const hasZoom = zoom === true;
    const [src, setSrc] = useState<string>('');
    const [originalAspect, setOriginalAspect] = useState<number>();
    const [zoomVal, setZoomVal] = useState(1);

    const beforeUploadRef = useRef<
        (file: RcFile, fileList: RcFile[]) => BeforeUploadValueType | Promise<BeforeUploadValueType> | undefined
    >();
    const fileRef = useRef<File | Blob>();
    const resolveRef = useRef<(blob: Blob | null) => void>(() => {});
    const rejectRef = useRef<(err: Error | string) => void>(() => {});

    const cropPixelsRef = useRef<Area>();
    const { formatMessage } = useFormatMessage();

    /**
     * Upload
     */
    const renderUpload = useCallback(() => {
        const upload = (Array.isArray(children) ? children[0] : children) as React.Component<UploadProps>; // Ant Upload Component;
        const { beforeUpload, accept, ...restUploadProps } = upload.props;
        beforeUploadRef.current = beforeUpload;

        return {
            ...upload,
            props: {
                ...restUploadProps,
                accept: accept || 'image/*',
                beforeUpload: async (file: File, fileList: File[]) => {
                    if (beforeCrop && !beforeCrop(file, fileList)) {
                        return false;
                    }
                    if (isMaliciousFile(file as RcFile)) {
                        message.error(formatMessage({ id: 'Error.Upload.MaliciousFile' }), 5);
                        return false;
                    }

                    if (file.type.includes('heic')) {
                        const heicFile = await getHeicFile(file);
                        if (heicFile instanceof Error) {
                            return false;
                        }

                        fileRef.current = heicFile;
                    } else {
                        fileRef.current = file;
                    }

                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        if (reader.result) {
                            if (fullImage) {
                                squareImage(reader.result as string).then((res) => {
                                    setSrc(res.dataURL);
                                    setOriginalAspect(res.originalAspect);
                                });
                            } else {
                                setSrc(reader.result as string);
                            }
                        }
                    });
                    reader.readAsDataURL(fileRef.current);

                    return false;
                },
            },
        };
    }, [beforeCrop, children, formatMessage, fullImage]);

    /**
     * EasyCrop
     */
    const onComplete = useCallback((croppedAreaPixels: Area) => {
        cropPixelsRef.current = croppedAreaPixels;
    }, []);

    /**
     * Controls
     */
    const isMinZoom = zoomVal === MIN_ZOOM;
    const isMaxZoom = zoomVal === MAX_ZOOM;

    const subZoomVal = useCallback(() => {
        if (!isMinZoom) setZoomVal(zoomVal - ZOOM_STEP);
    }, [isMinZoom, zoomVal]);

    const addZoomVal = useCallback(() => {
        if (!isMaxZoom) setZoomVal(zoomVal + ZOOM_STEP);
    }, [isMaxZoom, zoomVal]);

    /**
     * Modal
     */
    const onClose = useCallback(() => {
        setSrc('');
        setZoomVal(1);
    }, []);

    const onOk = useCallback(async () => {
        onClose();

        const naturalImg = document.querySelector(`.${MEDIA_CLASS}`) as HTMLImageElement;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // shrink the max canvas to the crop area size, then align two center points
        const { width = 0, height = 0, x = 0, y = 0 } = cropPixelsRef.current || {};
        canvas.width = width;
        canvas.height = height;
        ctx?.drawImage(naturalImg, -x, -y);

        // Scale image if cropped image bigger than limitation
        if (limitSize && (canvas.width > limitSize || canvas.height > limitSize) && ctx) {
            const cropImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            if (cropImageData) {
                const cloneCanvas = document.createElement('canvas');
                cloneCanvas.width = canvas.width;
                cloneCanvas.height = canvas.height;
                cloneCanvas.getContext('2d')?.putImageData(cropImageData, 0, 0);

                const limitedSize = limitImageSize({
                    width: canvas.width,
                    height: canvas.height,
                    limit: limitSize,
                });

                const scaleRatioX = limitedSize.width / canvas.width;
                const scaleRatioY = limitedSize.height / canvas.height;
                ctx.resetTransform();
                canvas.width = limitedSize.width;
                canvas.height = limitedSize.height;
                ctx.scale(scaleRatioX, scaleRatioY);
                ctx.drawImage(cloneCanvas, 0, 0);
            }
        }

        // get the new image
        const { name, uid } = fileRef.current as RcFile;
        canvas.toBlob(
            async (blob) => {
                type BlobExtend = Blob & {
                    lastModifiedDate?: number;
                    name?: string;
                    uid?: string;
                };

                let newFile: BlobExtend | null = blob;

                if (newFile) {
                    newFile.lastModifiedDate = Date.now();
                    newFile.name = name;
                    newFile.uid = uid;
                }

                if (typeof beforeUploadRef.current !== 'function') return resolveRef.current(newFile);

                const res = beforeUploadRef.current((newFile as unknown) as RcFile, [(newFile as unknown) as RcFile]);

                if (typeof res !== 'boolean' && !res) {
                    // eslint-disable-next-line no-console
                    console.error('beforeUpload must return a boolean or Promise');
                    return false;
                }

                if (res === true) return resolveRef.current(newFile);
                if (res === false) return rejectRef.current('not upload');
                // Check if res is a Promise
                if (res && typeof res === 'object' && 'then' in res) {
                    try {
                        const passedFile = await res;
                        const type = Object.prototype.toString.call(passedFile);
                        if (type === '[object File]' || type === '[object Blob]')
                            newFile = passedFile as BlobExtend | null;
                        resolveRef.current(newFile);
                    } catch (err) {
                        rejectRef.current(err as Error | string);
                    }
                }
                return false;
            },
            'image/png',
            0.4
        );
    }, [onClose, limitSize]);

    return (
        <>
            {renderUpload()}
            {src && (
                <Modal
                    title={modalTitle}
                    open
                    wrapClassName={`${pkg}-modal`}
                    width={modalWidth}
                    onOk={onOk}
                    onCancel={onClose}
                    maskClosable={false}
                    destroyOnClose
                    getContainer={getContainer ?? undefined}
                    okText={modalOk}
                    cancelText={modalCancel}
                    closable={false}
                    transitionName="fade"
                >
                    <EasyCrop
                        ref={ref}
                        src={src}
                        originalAspect={originalAspect}
                        shape={shape}
                        grid={grid}
                        hasZoom={hasZoom}
                        zoomVal={zoomVal}
                        setZoomVal={setZoomVal}
                        onComplete={onComplete}
                        limitSize={limitSize}
                    />
                    {hasZoom && (
                        <div className={`${pkg}-control zoom`}>
                            <button type="button" onClick={subZoomVal} disabled={isMinZoom}>
                                －
                            </button>
                            <Slider
                                min={MIN_ZOOM}
                                max={MAX_ZOOM}
                                step={ZOOM_STEP}
                                value={zoomVal}
                                onChange={setZoomVal}
                            />
                            <button type="button" onClick={addZoomVal} disabled={isMaxZoom}>
                                ＋
                            </button>
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
});

export default ImgCrop;
