import { ProductType } from '../types/authentication';
import { FormatIDs } from '../types';
import { routeUtils } from 'tds-common-fe';
import config from '../config';

export const productNameMap: { [key in ProductType]: FormatIDs } = {
    [ProductType.Inspect]: 'ProductName.Inspect',
    [ProductType.Workspace]: 'ProductName.Workspace',
    [ProductType.Proceq]: 'ProductName.Proceq',
};

export function isProductExisting(product?: string): product is ProductType {
    return !!product && product in productNameMap;
}

export const getSignInLink = (product: string, email?: string) =>
    product.toLowerCase() === ProductType.Inspect
        ? routeUtils.makeQueryPath(`${config.INSPECT_DOMAIN}/home`, { email })
        : config.WORKSPACE_DOMAIN;
