import { Form } from 'antd';
import { Rule } from 'antd/lib/form';
import React, { useRef } from 'react';
import { FormItem, routeUtils } from 'tds-common-fe';
import { APIError, handleError } from '../../../../api/error';
import useLoadingState from '../../../../hooks/useLoadingState';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import { FormatIDs } from '../../../../types';
import AuthBasics from '../../Custom/AuthBasics';
import AuthButton from '../../Custom/AuthButton';
import AuthInput from '../../Custom/AuthInput';
import formStyles from '../../Form.styl';
import styles from '../SignUp.styl';
import { requiredFields, rules, SetupProfileFieldsType } from './SetupProfileRules';
import URL from '../../../../api/urls';
import { getScreeningEagleHeaders } from '../../../../api/headerUtils';

export class Item extends FormItem<SetupProfileFieldsType> {}

type Field = {
    rule: Rule[] | undefined;
    name: keyof SetupProfileFieldsType;
    placeholder: FormatIDs;
};

const fields: Field[] = [
    {
        rule: rules.firstName,
        name: 'firstName',
        placeholder: 'SignUp.Field.FirstName',
    },
    {
        rule: rules.lastName,
        name: 'lastName',
        placeholder: 'SignUp.Field.LastName',
    },
    {
        rule: rules.companyName,
        name: 'companyName',
        placeholder: 'SignUp.Field.CompanyName',
    },
];

const SetupProfile = () => {
    const params = routeUtils.parseQuery<any>(location.search);

    const [loading, setLoading] = useLoadingState(false);
    const { formatMessage } = useFormatMessage();
    const title = formatMessage({ id: 'SignUp.SetupProfile.Title' });
    const formRef = useRef<HTMLFormElement>(null);
    const actionURL = routeUtils.makeQueryPath(URL.USER_SETUP_PROFILE, {
        ...getScreeningEagleHeaders(),
        ...params,
    });
    const [form] = Form.useForm();

    const handleSubmit = (values: SetupProfileFieldsType) => {
        async function action() {
            try {
                setLoading(true);
                const { firstName, lastName, companyName } = values;
                const htmlForm = formRef.current;
                if (htmlForm !== null) {
                    const firstNameInput: HTMLInputElement | null = htmlForm.querySelector('input[name="firstName"]');
                    const lastNameInput: HTMLInputElement | null = htmlForm.querySelector('input[name="lastName"]');
                    const companyNameInput: HTMLInputElement | null = htmlForm.querySelector(
                        'input[name="companyName"]'
                    );

                    if (firstNameInput) {
                        firstNameInput.value = firstName;
                    }

                    if (lastNameInput) {
                        lastNameInput.value = lastName;
                    }

                    if (companyNameInput) {
                        companyNameInput.value = companyName;
                    }

                    htmlForm.submit();
                }
            } catch (error) {
                handleError(error as APIError);
                setLoading(false);
            }
        }
        action();
    };

    return (
        <div className="signup">
            <AuthBasics title={title} />
            <Form
                form={form}
                onFinish={handleSubmit}
                initialValues={{
                    firstName: '',
                    lastName: '',
                    companyName: '',
                }}
            >
                {fields.map((field) => (
                    <Item className={formStyles.form_item} key={field.name} rules={field.rule} name={field.name}>
                        <AuthInput type="text" placeholder={formatMessage({ id: field.placeholder })} />
                    </Item>
                ))}
                <Item className={styles.last_item} shouldUpdate>
                    {() => (
                        <AuthButton
                            title="SignUp.Button.GetStarted"
                            className={styles.button_container}
                            form={form}
                            loading={loading}
                            requiredFields={requiredFields}
                        />
                    )}
                </Item>
            </Form>

            <form ref={formRef} action={actionURL} method="POST" className={styles.hidden_form}>
                <input name="firstName" type="text" autoComplete="off" tabIndex={-1} />
                <input name="lastName" type="text" autoComplete="off" tabIndex={-1} />
                <input name="companyName" type="text" autoComplete="off" tabIndex={-1} />
            </form>
        </div>
    );
};

export default SetupProfile;
