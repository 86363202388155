import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import config from './config';

const composeEnhancers =
    // Only use dev tools if in local development
    config.NODE_ENV === 'development' && typeof window === 'object' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          })
        : compose;

const middleware = [thunk];

const enhancer = composeEnhancers(
    applyMiddleware(...middleware)
    // other store enhancers if any
);

const store = createStore(rootReducer, enhancer);

export const storeDispatch = store.dispatch;

export default store;
