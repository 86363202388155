import React, { useRef } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import * as appUtils from '../../../utils/appUtils';
import styles from './AuthInput.styl';

interface AuthTextAreaProps extends TextAreaProps {
    inputs?: React.MutableRefObject<HTMLInputElement[]>;
}

const AuthTextArea = React.forwardRef<any, AuthTextAreaProps>((props, ref) => {
    const { inputs } = props;
    const inputProps: AuthTextAreaProps = {
        onKeyDown: (event) => {
            // Auto move to next field when clicked on return in app
            if (event.key.toLowerCase() === 'enter' && inputs?.current && appUtils.fromApp()) {
                const nextIndex = inputIndex.current + 1;
                if (nextIndex < inputs.current.length) {
                    inputs.current[nextIndex].focus();
                    event.preventDefault();
                }
            }
        },
        ...props,
    };

    const inputIndex = useRef(-1);

    delete inputProps.inputs;

    const setupRef = (inputRef: any) => {
        ref = inputRef;
        if (!inputs?.current) {
            return;
        }
        // Set the index value automatically
        if (inputIndex.current < 0) {
            inputIndex.current = inputs.current.length;
        }
        inputs.current[inputIndex.current] = ref as any;
    };

    return <Input.TextArea ref={setupRef} className={styles.input} {...props} />;
});

export default AuthTextArea;
