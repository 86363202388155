import { useHistory } from 'react-router-dom';
import React from 'react';
import { routeUtils } from 'tds-common-fe';
import { classNames } from '../../../../utils/classUtils';
import wrapperStyles from '../../../Authentication/AuthWrapper.styl';
import styles from '../TwoFAStyles.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { AUTH_ROUTES, TWO_FA_ROUTES } from '../../../Routes/urls';
import { VerifyPasswordParams } from '../../../Authentication/VerifyPassword';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA } from '../../../../analytics/analyticsConstants';
import config from '../../../../config';

const ResetTwoFA: React.FunctionComponent = () => {
    const history = useHistory();
    const params = routeUtils.parseQuery<Partial<VerifyPasswordParams>>(location.search);
    const { email, cancel_redirect_uri: cancelRedirectURL = config.WORKSPACE_DOMAIN, product, client } = params;
    const redirectUri = routeUtils.makeQueryPath(`${location.origin}${TWO_FA_ROUTES.RESET_REDIRECTION}`, {
        redirect_uri: cancelRedirectURL,
    });
    const verifyPasswordPath = routeUtils.makeQueryPath(AUTH_ROUTES.VERIFY_PASSWORD, {
        email,
        cancel_redirect_uri: cancelRedirectURL,
        product,
        client,
        close_tab: 'false',
        action: 'reset_2fa',
        redirect_uri: redirectUri,
    });
    return (
        <div className={classNames(wrapperStyles.content_wrapper, wrapperStyles.input_block)}>
            <div className={styles.header}>
                <FormattedMessage id="2FA.Reset.Title" />
            </div>
            <div className={styles.body_text}>
                <FormattedMessage id="2FA.Reset.Body" />
            </div>
            <AnalyticsButton
                className={styles.primary_button_container}
                type="primary"
                onClick={() => {
                    analytics.logResetTwoFA(ButtonsTwoFA.reset);
                    history.push(verifyPasswordPath);
                }}
                size="large"
            >
                <span className={styles.primary_button_text}>
                    <FormattedMessage id="Common.Reset" />
                </span>
            </AnalyticsButton>
            <AnalyticsButton
                className={styles.primary_button_container}
                type="link"
                href={cancelRedirectURL}
                onClick={() => analytics.logResetTwoFA(ButtonsTwoFA.cancel)}
                size="large"
            >
                <span className={styles.primary_button_text}>
                    <FormattedMessage id="Common.Cancel" />
                </span>
            </AnalyticsButton>
        </div>
    );
};

export default ResetTwoFA;
