import { message } from 'antd';
import * as appUtils from '../utils/appUtils';
import postMessenger from '../utils/PostMessenger';

export interface APIError extends Error {
    response?: {
        status: number;
        data: {
            code: number | string;
            message: string;
            devMessage: string;
        };
    };
}

function showError(errorMessage: string) {
    if (appUtils.fromApp()) {
        appUtils.showError(errorMessage);
    } else if (postMessenger.parentOrigin) {
        postMessenger.postPopupMessage('error', errorMessage);
    } else {
        message.error(errorMessage);
    }
    // eslint-disable-next-line no-console
    console.error(errorMessage);
}

export function handleError(error: APIError, fallbackMessage: string = 'Unknown Error') {
    const { response } = error;

    if (!response) {
        showError(fallbackMessage);
        return;
    }

    // const httpErrorCode = response.status;
    const { code, message } = response.data;
    const errorMessage = message ?? error.message ?? fallbackMessage;
    showError(errorMessage);

    // Error code handling
    // eslint-disable-next-line no-empty
    switch (code) {
    }
}
