import { UserProfileInfo, CompanyInfo, UserLicenseInfo } from '../types/profile';
import actionTypes, { PayloadAction } from './actionTypes';

export function receivedUserProfile(response: Partial<UserProfileInfo>): PayloadAction<Partial<UserProfileInfo>> {
    return {
        type: actionTypes.RECEIVED_USER_PROFILE,
        payload: response,
    };
}

export function receivedUserLicense(response: Partial<UserLicenseInfo>): PayloadAction<Partial<UserLicenseInfo>> {
    return {
        type: actionTypes.RECEIVED_USER_LICENSE,
        payload: response,
    };
}

interface CompanyProfileParams extends Partial<CompanyInfo> {}
export function receivedCompanyProfile(params: CompanyProfileParams): PayloadAction<CompanyProfileParams> {
    return {
        type: actionTypes.RECEIVED_COMPANY_PROFILE,
        payload: params,
    };
}
