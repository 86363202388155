import React from 'react';
import { Item } from '..';
import { FormSteps } from '../../../../types/authentication';
import AuthInput from '../../Custom/AuthInput';
import formStyles from '../../Form.styl';
import { rules } from '../SignUpRules';

type EmailProps = {
    step: FormSteps;
    inputs: React.MutableRefObject<HTMLInputElement[]>;
    placeholder: string;
};

const Email = ({ step, inputs, placeholder }: EmailProps) => {
    const visible = step === FormSteps.email;

    return (
        <Item
            className={visible ? formStyles.form_item : formStyles.form_item_hidden}
            name="username"
            rules={rules.username}
        >
            <AuthInput type={visible ? 'email' : 'hidden'} placeholder={placeholder} inputs={inputs} />
        </Item>
    );
};

export default Email;
