import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { MyMessageDescriptor } from '../types';

export const customStaticNames: { [key: string]: any } = {
    ScreeningEagleID: 'Screening\u00A0Eagle\u00A0ID',
};

export const useFormatMessage = () => {
    const formatMessage = useIntl().formatMessage;
    return {
        formatMessage: useCallback(
            (descriptor: MyMessageDescriptor, values?: Record<string, any>) =>
                formatMessage(descriptor, { ...customStaticNames, ...values }),
            [formatMessage]
        ),
    };
};
