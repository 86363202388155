import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { routeUtils } from 'tds-common-fe';
import AuthWrapper from '../Authentication/AuthWrapper';
import { SetUpFlowStage, TWO_FA_ROUTES } from './urls';
import EnableTwoFA from '../App/2FA/Enable';
import AnalyticsRoute from '../AnalyticsComponents/Route';
import SetUpTwoFA from '../App/2FA/SetUp';
import LoginTwoFA from '../App/2FA/Login';
import LoginRecoveryKey from '../App/2FA/Login/LoginRecoveryKey';
import ResetTwoFA from '../App/2FA/Reset';
import RecoveryKeyTwoFA from '../App/2FA/RecoveryKey';
import ResetRedirection from '../App/2FA/Reset/ResetRedirection';

const TwoFARoutes: React.FunctionComponent = () => {
    return (
        <AuthWrapper hasContentOutsideModal showLanguageSwitcher={false}>
            <Switch>
                <AnalyticsRoute exact path={TWO_FA_ROUTES.ENABLE} titleFormatIDs={['NavTitle.2FA.Enable']}>
                    <EnableTwoFA />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={TWO_FA_ROUTES.SETUP} titleFormatIDs={['NavTitle.2FA.SetUp']}>
                    <SetUpTwoFA />
                </AnalyticsRoute>
                <Redirect
                    exact
                    strict={false}
                    from={TWO_FA_ROUTES.SETUP_BASE}
                    to={routeUtils.removeDuplicateSlash(`${location.pathname}/${SetUpFlowStage.setUpAuthenticator}`)}
                />
                <AnalyticsRoute exact path={TWO_FA_ROUTES.LOGIN} titleFormatIDs={['NavTitle.2FA.Login']}>
                    <LoginTwoFA />
                </AnalyticsRoute>
                <AnalyticsRoute
                    exact
                    path={TWO_FA_ROUTES.LOGIN_RECOVERY_KEY}
                    titleFormatIDs={['NavTitle.2FA.LoginRecoveryKey']}
                >
                    <LoginRecoveryKey />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={TWO_FA_ROUTES.RESET} titleFormatIDs={['NavTitle.2FA.Reset']}>
                    <ResetTwoFA />
                </AnalyticsRoute>
                <AnalyticsRoute
                    exact
                    path={TWO_FA_ROUTES.RESET_REDIRECTION}
                    titleFormatIDs={['NavTitle.2FA.ResetRedirection']}
                >
                    <ResetRedirection />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={TWO_FA_ROUTES.RECOVERY_KEY} titleFormatIDs={['NavTitle.2FA.RecoveryKey']}>
                    <RecoveryKeyTwoFA />
                </AnalyticsRoute>
            </Switch>
        </AuthWrapper>
    );
};

export default TwoFARoutes;
