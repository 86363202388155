import React, { useContext, useEffect } from 'react';
import styles from './Profile.styl';
import Header from './Header';
import { ThemeContext } from '../../../Routes/ThemeProvider';
import ProfileTwoFAActions from './ProfileTwoFAActions';
import postMessenger from '../../../../utils/PostMessenger';
import { routeUtils } from 'tds-common-fe';
import { ProfileTwoFAQueryParams } from '../../../../types/authentication';
import { usePostPageHeight } from '../../License/PurchaseForm/usePostPageHeight';

// this page is shown in the user profiles for users to manage their 2fa settings
const ProfileTwoFA: React.FunctionComponent = () => {
    const { theme = 'dark' } = useContext(ThemeContext);
    const { parentOrigin, isTwoFAEnabled: isTwoFAEnabledParam } = routeUtils.parseQuery<ProfileTwoFAQueryParams>(
        location.search
    );

    useEffect(() => {
        if (parentOrigin) {
            postMessenger.setParentOrigin(decodeURIComponent(parentOrigin));
        }
    }, [parentOrigin]);

    const is2FAEnabled = isTwoFAEnabledParam === 'true';

    const { containerRef } = usePostPageHeight({ minHeight: 0, addMargins: true });

    return (
        <div data-mode={theme} style={{ width: '100%', height: '100%' }}>
            <div className={styles.container} ref={containerRef}>
                <Header is2FAEnabled={is2FAEnabled} />
                {is2FAEnabled && <ProfileTwoFAActions />}
            </div>
        </div>
    );
};

export default ProfileTwoFA;
