import React from 'react';
import { useSelector } from 'react-redux';
import FormattedMessage from '../../../../localization/FormatMessage';
import DiscountBadge from './DiscountBadge';
import { LicenseTier, CurrencyCode, LicenseFeature } from '../../../../types/license';
import styles from './UpgradeInfo.styl';
import { RootState } from '../../../../reducers';
import { getPricingInfo } from '../../../../utils/pricingUtils';
import PlanFeatureList from '../Plan/PlanFeatureList';

interface PlanInfoCardProps {
    tier: LicenseTier;
    showPricing: boolean;
    features: LicenseFeature[];
    previousTierName: string;
}

const PlanInfoCard: React.FunctionComponent<PlanInfoCardProps> = (props) => {
    const { tier, features, showPricing, previousTierName } = props;
    const userCountryCode = useSelector((state: RootState) => state.profile.userProfile.countryCode);

    const { fallbackCurrencyCode: currencyCode = CurrencyCode.CHF } = getPricingInfo(
        userCountryCode,
        tier.pricing || []
    );

    const pricing = tier.pricing?.find((entry) => entry.currencyCode === currencyCode);

    return (
        <div className={styles.plan_info_card}>
            {showPricing && pricing && (
                <>
                    <MonthPrice price={pricing.monthlyPrice} currencyCode={currencyCode} />
                    <p className={styles.small_description} style={{ maxWidth: 250 }}>
                        <FormattedMessage id="License.Subscription.AnnualBilling" />
                    </p>
                    {tier.discount && <DiscountBadge percentage={tier.discount} style={{ marginBottom: 12 }} />}
                    {tier.discountDescription && (
                        <p className={styles.small_description} style={{ maxWidth: 250 }}>
                            {tier.discountDescription}
                        </p>
                    )}
                </>
            )}

            <PlanFeatureList
                columnCount={1}
                features={features}
                className={styles.plan_items_wrapper}
                includePreviousTier={previousTierName}
            />
        </div>
    );
};

interface MonthPriceProps {
    price: number;
    currencyCode: CurrencyCode;
}
const MonthPrice: React.FunctionComponent<MonthPriceProps> = (props) => {
    const { price, currencyCode } = props;
    return (
        <div className={styles.month_price} style={{ marginBottom: 4 }}>
            <div>
                {currencyCode}
                <span className={styles.number}>{price}</span>
                {'/'}
                <FormattedMessage id="Common.Month" />
            </div>
            <div>
                <FormattedMessage id="License.Subscription.PerUser" />
            </div>
        </div>
    );
};

export default PlanInfoCard;
