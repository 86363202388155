import React from 'react';
import { Rule } from 'antd/lib/form';
import FormattedMessage from '../../../localization/FormatMessage';
import { genericPhoneNumberRegex } from '../../../utils/phoneUtils';
import { websiteRegex } from '../../../utils/regex';

export interface FieldsType {
    name: string;
    address: string;
    contactNumber: string;
    email: string;
    website: string;
}

export type FieldsKeys = keyof FieldsType;

export const rules: { [K in keyof FieldsType]?: Rule[] } = {
    name: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.CompanyName.Error.Empty" />,
        },
    ],
    contactNumber: [
        { pattern: genericPhoneNumberRegex, message: <FormattedMessage id="Contact.Field.Phone.Error.Invalid" /> },
    ],
    email: [{ type: 'email', message: <FormattedMessage id="SignUp.Field.Email.Error.Invalid" /> }],
    website: [
        {
            pattern: websiteRegex,
            message: <FormattedMessage id="Company.Field.Website.Error.Invalid" />,
        },
    ],
};
