import { combineReducers } from 'redux';
import authentication, { AuthenticationState } from './authentication';
import profile, { ProfileState } from './userProfile';
import license, { LicenseImmutableState } from './license';

export interface RootState {
    authentication: AuthenticationState;
    profile: ProfileState;
    license: LicenseImmutableState;
}

const rootReducer = combineReducers({
    authentication,
    profile,
    license,
});

export default rootReducer;
