import { useCallback, useState } from 'react';

type Output = [boolean, (loading: boolean, message?: string) => void];

export default function useLoadingState(initialLoading: boolean): Output {
    const [loading, setLoading] = useState(initialLoading);

    const mySetLoading = useCallback((newLoading: boolean) => {
        // This is suppose to post the loading message to the app, but let's disable this for now
        // eslint-disable-next-line no-console
        setLoading(newLoading);
    }, []);

    return [loading, mySetLoading];
}
