import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { consumerInfo } from '../api/headerUtils';
import { EventName } from './analyticsConstants';
import config from '../config';

class FirebaseAnalytics {
    private instance?: firebase.analytics.Analytics;

    initialize = async () => {
        const isSupported = await firebase.analytics.isSupported();
        if (!isSupported || this.instance) {
            return;
        }

        const { product, client, version } = consumerInfo;
        if (product === 'inspect' && config.INSPECT_WEB_FIREBASE_API_KEY) {
            const firebaseConfig = {
                apiKey: config.INSPECT_WEB_FIREBASE_API_KEY,
                authDomain: `${config.INSPECT_WEB_FIREBASE_PROJECT_ID}.firebaseapp.com`,
                databaseURL: `https://${config.INSPECT_WEB_FIREBASE_PROJECT_ID}.firebaseio.com`,
                projectId: config.INSPECT_WEB_FIREBASE_PROJECT_ID,
                storageBucket: `${config.INSPECT_WEB_FIREBASE_PROJECT_ID}.appspot.com`,
                messagingSenderId: config.INSPECT_WEB_FIREBASE_SENDER_ID,
                appId: config.INSPECT_WEB_FIREBASE_APP_ID,
                measurementId: `G-${config.INSPECT_WEB_FIREBASE_MEASUREMENT_ID}`,
            };

            this.instance = firebase.initializeApp(firebaseConfig).analytics();
            this.instance?.setUserProperties({ app_version: version, product, client });
        }
    };

    setUserProperties = (name: EventName, value: string) => {
        this.instance?.setUserProperties({ [name]: value });
    };

    logEventWithName = (name: EventName | string, params: { [key: string]: string } = {}) => {
        this.instance?.logEvent(name, params);
    };

    setScreenName = (name: string) => {
        this.instance?.setCurrentScreen(name);
    };
}

const firebaseAnalytics = new FirebaseAnalytics();

export default firebaseAnalytics;
