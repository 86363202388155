import React, { useEffect, useState } from 'react';
import { routeUtils } from 'tds-common-fe';
import RecoveryKey from '../shared/RecoveryKey';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA } from '../../../../analytics/analyticsConstants';
import config from '../../../../config';
import { getTwoFARecoveryKey } from '../../../../api/authService';
import ErrorTwoFA from '../shared/ErrorTwoFA';
import styles from '../Login/Login.styl';

interface RecoverKeyQueryParams {
    cancel_redirect_uri: string;
    code: string;
}

const RecoveryKeyTwoFA: React.FunctionComponent = () => {
    const {
        code: confirmCode,
        cancel_redirect_uri: cancelRedirectURL = config.WORKSPACE_DOMAIN,
    } = routeUtils.parseQuery<RecoverKeyQueryParams>(location.search);
    const [recoveryKeys, setRecoveryKeys] = useState<string[]>([]);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        getTwoFARecoveryKey(confirmCode ?? '')
            .then((res) => setRecoveryKeys(res.data.map((obj) => obj.key)))
            .catch(() => setShowError(true));
    }, [confirmCode]);

    return showError ? (
        <ErrorTwoFA />
    ) : (
        <div className={styles.base_container}>
            <RecoveryKey
                recoveryKeys={recoveryKeys}
                onDownload={() => analytics.logRecoveryKeyTwoFA(ButtonsTwoFA.download)}
                doneRedirectURL={cancelRedirectURL!}
            />
        </div>
    );
};

export default RecoveryKeyTwoFA;
