import React from 'react';
import { FormInstance, Rule } from 'antd/lib/form';
import {
    CountryCode,
    getCountryCallingCode as getLibCountryCallingCode,
    isSupportedCountry,
    isValidNumberForRegion,
    parsePhoneNumber,
} from 'libphonenumber-js';

// libphonenumber from Google is missing certain countries
const extraCountryCodeMapping = {
    AQ: '672',
    BV: '47',
    TF: '262',
    HM: '672',
    AN: '599',
    PN: '64',
    GS: '500',
    UM: '246',
};

export const genericPhoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const getPhoneNumberValidator = <T extends string>(fieldKey: T, errorMessage: React.ReactNode): Rule => (
    ruleForm: any
) => ({
    validator(rule: any, value: string) {
        const form = ruleForm as FormInstance;
        const countryCode = form.getFieldValue(fieldKey);
        if (!value || !countryCode) {
            return Promise.resolve();
        }
        return validatePhoneNumber(value, countryCode) ? Promise.resolve() : Promise.reject(errorMessage);
    },
});

export const validatePhoneNumber = (value: string, countryCode: CountryCode): boolean => {
    // Note that we are using "min" so certain numbers will still be detected as valid.
    // Check out: https://github.com/catamphetamine/libphonenumber-js#min-vs-max-vs-mobile-vs-core
    // Use the generic if not supported by libphonenumber
    return isSupportedCountry(countryCode)
        ? isValidNumberForRegion(value, countryCode)
        : genericPhoneNumberRegex.test(value);
};

export const getFormattedPhoneNumber = (phone: string, countryCode: string): string => {
    const myCountryCode = countryCode as CountryCode;
    const callingCode = getCountryCallingCode(myCountryCode);
    const combinedNumber = `+${callingCode}${phone}`;
    const contactNumber = isSupportedCountry(myCountryCode)
        ? parsePhoneNumber(combinedNumber, myCountryCode).number
        : combinedNumber;
    return contactNumber.toString();
};

export const getCountryCallingCode = (countryCode: string): string => {
    const myCountryCode = countryCode as CountryCode;
    return (isSupportedCountry(myCountryCode)
        ? getLibCountryCallingCode(myCountryCode)
        : extraCountryCodeMapping[countryCode as keyof typeof extraCountryCodeMapping] ?? '0'
    ).toString();
};
