import axios, { Method, AxiosRequestConfig } from 'axios';
import { Dispatch } from 'redux';
import config from '../config';
import { storeDispatch } from '../store';
import { RootState } from '../reducers';
import { getScreeningEagleHeaders } from './headerUtils';
import { EAGLE_ID_API } from './urls';

const dataMethods = new Set(['PUT', 'POST', 'PATCH']);

// Timeout after 10 seconds
const timeout = 10 * 1000;

const baseURL = `https://${config.EAGLE_ID_DOMAIN}${config.EAGLE_ID_API_VERSION ?? ''}`;

const instance = axios.create({
    timeout,
    baseURL,
});

export interface APIData {
    params?: { [key: string]: string | number | boolean | undefined | null };
    config?: AxiosRequestConfig;
    errorHandler?: (error: any) => void;
}

export type ThunkResponse<R> = { response: R; dispatch: Dispatch; getState: () => RootState };

export function thunkGet<R>(
    path: string,
    data: APIData = {},
    dispatch: Dispatch<any> = storeDispatch
): Promise<ThunkResponse<R>> {
    return new Promise((resolve, reject) => {
        dispatch(async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const response = await get<R>(path, data);
                resolve({ response, dispatch, getState });
            } catch (e) {
                reject(e);
            }
        });
    });
}

export function thunkPost<R>(
    path: string,
    data: APIData = {},
    dispatch: Dispatch<any> = storeDispatch
): Promise<ThunkResponse<R>> {
    return new Promise((resolve, reject) => {
        dispatch(async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const response = await post<R>(path, data);
                resolve({ response, dispatch, getState });
            } catch (e) {
                reject(e);
            }
        });
    });
}

export function thunkPut<R>(
    path: string,
    data: APIData = {},
    dispatch: Dispatch<any> = storeDispatch
): Promise<ThunkResponse<R>> {
    return new Promise((resolve, reject) => {
        dispatch(async (dispatch: Dispatch, getState: () => RootState) => {
            try {
                const response = await put<R>(path, data);
                resolve({ response, dispatch, getState });
            } catch (e) {
                reject(e);
            }
        });
    });
}

export function get<R>(path: string, data: APIData = {}) {
    const { params, config, errorHandler } = data;
    return request<R>('GET', path, params, config, errorHandler);
}

export function post<R>(path: string, data: APIData = {}) {
    const { params, config, errorHandler } = data;
    return request<R>('POST', path, params, config, errorHandler);
}

export function put<R>(path: string, data: APIData = {}) {
    const { params, config, errorHandler } = data;
    return request<R>('PUT', path, params, config, errorHandler);
}

export function request<R = any>(
    method: Method,
    path: string,
    params?: { [key: string]: string | number | boolean | undefined | null },
    config?: AxiosRequestConfig,
    errorHandler?: (error: any) => void
): Promise<R> {
    const isDataMethod = dataMethods.has(method);
    const myParams = isDataMethod ? undefined : params;
    const data = isDataMethod ? params : undefined;

    const isEagleIDPath = path.startsWith(EAGLE_ID_API);

    const myConfig: AxiosRequestConfig = config ?? {};
    myConfig.headers = {
        'Content-Type': isDataMethod ? 'application/json' : 'text/plain',
        ...(isEagleIDPath ? getScreeningEagleHeaders() : {}),
        ...(myConfig.headers ?? {}),
    };

    return instance({
        method,
        data,
        url: path,
        params: myParams,
        withCredentials: true,
        ...myConfig,
    })
        .then((response) => response.data)
        .catch((error) => {
            if (errorHandler) {
                errorHandler(error);
            }
            throw error;
        });
}
