import React, { useEffect, useRef } from 'react';
import { routeUtils } from 'tds-common-fe';
import FullScreenSpinner from '../../../Shared/FullScreenSpinner';
import styles from '../Login/Login.styl';
import config from '../../../../config';
import URL from '../../../../api/urls';
import { getScreeningEagleHeaders } from '../../../../api/headerUtils';
import colors from '../../../../styles/colors.json';

interface ResetRedirectionParams {
    code?: string;
    redirect_uri?: string;
}

const ResetRedirection: React.FunctionComponent = () => {
    const htmlFormRef = useRef<HTMLFormElement>(null);
    const { code = '', redirect_uri: redirectUri = config.WORKSPACE_DOMAIN } = routeUtils.parseQuery<
        ResetRedirectionParams
    >(location.search);

    const actionURL = routeUtils.makeQueryPath(URL.TWO_FA_RESET, {
        ...getScreeningEagleHeaders(),
        redirect_uri: redirectUri,
    });

    useEffect(() => {
        const form = htmlFormRef.current;
        if (form !== null) {
            const codeInput: HTMLInputElement | null = form.querySelector('[name="confirmCode"]');
            if (codeInput) {
                codeInput.value = code;
            }
            form.submit();
        }
    }, [code]);

    return (
        <>
            <FullScreenSpinner spinnerSize="large" extraStyle={{ background: colors.dark_background }} />
            <form ref={htmlFormRef} action={actionURL} method="POST" className={styles.hidden_form}>
                <input name="confirmCode" type="password" autoComplete="off" tabIndex={-1} />
            </form>
        </>
    );
};

export default ResetRedirection;
