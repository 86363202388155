import React, { Ref } from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import analytics from '../../analytics/index';

interface AnalyticsButtonProps extends AntdButtonProps {
    logAnalytics?: boolean;
    ref?: Ref<HTMLButtonElement>;
}

const AnalyticsButton = React.forwardRef<HTMLButtonElement, AnalyticsButtonProps>((props, ref) => {
    const { logAnalytics, ...rest } = props;
    const onClicking = (event: React.PointerEvent<HTMLElement>) => {
        const buttonText = event?.currentTarget?.textContent;
        if (logAnalytics && buttonText) analytics.logButtonClick(buttonText);
        if (props.onClick) props.onClick(event);
    };

    // due to google translate error where FormattedMessage needs to be wrapped in span
    let childElements = props.children;
    if (React.isValidElement(props.children) && (props.children.type as any).displayName === 'FormattedMessage') {
        childElements = <span>{props.children}</span>;
    }

    return (
        <AntdButton {...rest} onClick={onClicking} ref={ref}>
            {childElements}
        </AntdButton>
    );
});

export default AnalyticsButton;
