import { Button, Form } from 'antd';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormItem, routeUtils, useFormCheckErrors } from 'tds-common-fe';
import { APIError, handleError } from '../../../api/error';
import useLoadingState from '../../../hooks/useLoadingState';
import FormattedMessage from '../../../localization/FormatMessage';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { FormatIDs, FormatMessageFunc } from '../../../types';
import { FormSteps } from '../../../types/authentication';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import Link from '../../Routes/Link';
import * as urls from '../../Routes/urls';
import LoadingOverlay from '../../Shared/LoadingOverlay';
import AuthBasics from '../Custom/AuthBasics';
import formStyles from '../Form.styl';
import styles from './SignUp.styl';
import { FieldsKeys, FieldsType, requiredFields } from './SignUpRules';
import SocialButtons from './SocialButtons';
import Email from './Steps/Email';
import Password from './Steps/Password';
import './index.global.styl';
import analytics from '../../../analytics';
import useDisableLoading from '../useDisableLoading';

const placeholderMap: { [key in FieldsKeys]: FormatIDs } = {
    username: 'SignUp.Field.EmailWork.Placeholder',
    password: 'Login.Field.Password.Placeholder',
    passwordConfirm: 'SignUp.Field.ConfirmPassword',
};

export const createPlaceholderGenerator = (formatMessage: FormatMessageFunc) => {
    return (fieldName: FieldsKeys) => {
        const isRequired = requiredFields.includes(fieldName);
        return `${isRequired ? '* ' : ''}${formatMessage({ id: placeholderMap[fieldName] })}`;
    };
};

export class Item extends FormItem<FieldsType> {}

const fields = {
    [FormSteps.email]: ['username'],
    [FormSteps.password]: ['password', 'passwordConfirm'],
};

const SignUp: React.FunctionComponent = () => {
    const [form] = Form.useForm();
    const { formatMessage } = useFormatMessage();
    const getPlaceholder = createPlaceholderGenerator(formatMessage);

    const [loading, setLoading] = useLoadingState(false);
    const [step, setStep] = useState(FormSteps.email);
    const history = useHistory();

    const inputs = useRef<HTMLInputElement[]>([]);

    const { username, inviteID, ...otherParams } = routeUtils.parseQuery<{
        username: string;
        token: string;
        inviteID: string;
    }>(location.search);

    const initialValues = {
        username,
    };

    useDisableLoading(setLoading);

    const handleSubmit = (values: FieldsType) => {
        async function action() {
            try {
                setLoading(true);

                const { username, password, passwordConfirm } = values;

                const url = routeUtils.makeQueryPath(urls.AUTH_ROUTES.COUNTRY_SELECTION, {
                    inviteID,
                    ...otherParams,
                });

                analytics.logSignUp('email');

                history.push(url, {
                    username,
                    password,
                    passwordConfirm,
                    inviteID,
                });
            } catch (error) {
                handleError(error as APIError);
                setLoading(false);
            }
        }
        action();
    };

    const title = <FormattedMessage id="SignUp.Header.PageTitle" />;
    const footer = (
        <FormattedMessage
            id="SignUp.Header.HaveAccount"
            values={{
                action: (
                    <Link to={urls.AUTH_ROUTES.LOGIN}>
                        <FormattedMessage id="Common.Login" />
                    </Link>
                ),
            }}
        />
    );

    const continueRequiredFields = fields[step];

    const { checkErrors } = useFormCheckErrors(form, continueRequiredFields);

    const handleClickSubmit = () => {
        if (step === FormSteps.email) {
            setStep((prev) => prev + 1);
        } else {
            handleSubmit(form.getFieldsValue() as FieldsType);
        }
    };

    return (
        <div className="signup">
            <AuthBasics title={title} />
            <Form form={form} initialValues={initialValues} layout="vertical" onFinish={handleSubmit as any}>
                <div className={styles.description}>
                    {step === FormSteps.password && (
                        <p>
                            <FormattedMessage
                                id="SignUp.SigningUpAs.Title"
                                values={{
                                    user: <b>{form.getFieldValue('username')}</b>,
                                    link: (
                                        <Button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                // reset the form
                                                form.resetFields();
                                                setStep(FormSteps.email);
                                            }}
                                            type="link"
                                            style={{ padding: 0 }}
                                        >
                                            {formatMessage({ id: 'SignUp.Not.You' })}
                                        </Button>
                                    ),
                                }}
                            />
                        </p>
                    )}
                </div>
                <div className={styles.form_fields}>
                    <Email step={step} inputs={inputs} placeholder={getPlaceholder('username')} />
                    <Password
                        form={form}
                        step={step}
                        inputs={inputs}
                        passwordPlaceholder={getPlaceholder('password')}
                        confirmPasswordPlaceholder={getPlaceholder('passwordConfirm')}
                    />
                </div>

                <Item className={styles.last_item} shouldUpdate>
                    {() => (
                        <div className={styles.button_container}>
                            <AnalyticsButton
                                type="primary"
                                className={formStyles.form_submit_button}
                                disabled={checkErrors()}
                                onClick={handleClickSubmit}
                                size="large"
                            >
                                <FormattedMessage id="Common.Continue" />
                            </AnalyticsButton>
                        </div>
                    )}
                </Item>
            </Form>

            {step === FormSteps.email && <SocialButtons setLoading={setLoading} />}

            <p className={formStyles.footer_actions}>{footer}</p>

            <LoadingOverlay overlayBlocked={loading} />
        </div>
    );
};

export default SignUp;
