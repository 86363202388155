import React, { useEffect, useRef } from 'react';
import { routeUtils } from 'tds-common-fe';
import { Button, Form, message } from 'antd';
import styles from './VerifyPassword.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import AuthInput from '../Custom/AuthInput';
import { Item } from '../SignUp';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import AuthButton from '../Custom/AuthButton';
import useLoadingState from '../../../hooks/useLoadingState';
import * as urls from '../../Routes/urls';
import analytics from '../../../analytics/index';
import URL from '../../../api/urls';
import { getScreeningEagleHeaders } from '../../../api/headerUtils';
import SocialButtons from '../SignUp/SocialButtons';
import { AuthType } from '../../../types/authentication';
import LoadingOverlay from '../../Shared/LoadingOverlay';
import useDisableLoading from '../useDisableLoading';

export interface VerifyPasswordParams {
    email: string;
    redirect_uri: string;
    cancel_redirect_uri?: string;
    product: string;
    client: string;
    appClientID?: string; // to be used as x-se-client-id
    close_tab: boolean; // needed for multi login
    error?: string; // this will be provided by backend
    action: string;
}

const INVALID_PASSWORD = 'invalid credentials';

const VerifyPassword: React.FunctionComponent = () => {
    const params = routeUtils.parseQuery<VerifyPasswordParams>(location.search);
    const {
        email,
        cancel_redirect_uri: cancelRedirectURL,
        redirect_uri: redirectURL,
        action,
        error,
        ...otherParams
    } = params;
    const [form] = Form.useForm();
    const { formatMessage } = useFormatMessage();
    const [loading, setLoading] = useLoadingState(false);
    const htmlFormRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (error) {
            message.error(
                error === INVALID_PASSWORD ? formatMessage({ id: 'VerifyPassword.Failed.InvalidPassword' }) : error
            );
        }
    }, [error, formatMessage]);

    const redirectUri =
        redirectURL ||
        routeUtils.makeQueryPath(`${location.origin}${urls.AUTH_ROUTES.REQUEST_ACCOUNT_DELETION}`, {
            email,
            redirect_uri: cancelRedirectURL,
        });

    useDisableLoading(setLoading);

    // action parameters to be confirmed
    const actionUrl = routeUtils.makeQueryPath(URL.VERIFY_PASSWORD, {
        redirect_uri: redirectUri,
        email,
        cancel_redirect_uri: cancelRedirectURL,
        action,
        ...otherParams,
        ...getScreeningEagleHeaders(),
    });

    const handleSubmit = ({ password }: { password: string }) => {
        analytics.logVerifyPassword('confirm');
        const form = htmlFormRef.current;
        if (form !== null) {
            setLoading(true);
            const passwordInput: HTMLInputElement | null = form.querySelector('[name="password"]');
            if (passwordInput) {
                passwordInput.value = password;
            }
            form.submit();
        }
    };

    return (
        <div className="signup">
            <div className={styles.title_container}>
                <FormattedMessage id="VerifyPassword.Title" />
            </div>
            <div className={styles.form_container}>
                <div className={styles.subtitle3}>
                    <FormattedMessage id="VerifyPassword.Body" />
                </div>
                <div className={styles.password_container}>
                    <Form form={form} layout="vertical" onFinish={handleSubmit as any}>
                        <Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id="Login.Field.Password.Error.Empty" />,
                                },
                            ]}
                        >
                            <AuthInput
                                authInputType="password"
                                placeholder={formatMessage({ id: 'Login.Field.Password.Placeholder' })}
                            />
                        </Item>
                        <Item shouldUpdate>
                            {() => <AuthButton title="Common.Confirm" form={form} requiredFields={['password']} />}
                        </Item>
                    </Form>
                </div>
                <div className={styles.center}>
                    <Button
                        size="large"
                        type="link"
                        href={cancelRedirectURL}
                        onClick={() => analytics.logVerifyPassword('cancel')}
                    >
                        <FormattedMessage id="Common.Cancel" />
                    </Button>
                </div>
            </div>
            <LoadingOverlay overlayBlocked={loading} />
            <form
                ref={htmlFormRef}
                action={actionUrl}
                method="POST"
                style={{
                    position: 'absolute',
                    zIndex: -100,
                    top: 0,
                    left: 0,
                    opacity: 0.5,
                    pointerEvents: 'none',
                }}
            >
                <input name="password" type="password" autoComplete="off" tabIndex={-1} />
            </form>
            <SocialButtons authType={AuthType.ConfirmPassword} setLoading={setLoading} />
        </div>
    );
};

export default VerifyPassword;
