import { Form, Select } from 'antd';
import React, { useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormItem, routeUtils } from 'tds-common-fe';
import { EventName } from '../../../../analytics/analyticsConstants';
import analytics from '../../../../analytics/firebaseAnalytics';
import { signUp, signUpSocial } from '../../../../api/authService';
import { APIError, handleError } from '../../../../api/error';
import { getScreeningEagleHeaders } from '../../../../api/headerUtils';
import apiUrls from '../../../../api/urls';
import useLoadingState from '../../../../hooks/useLoadingState';
import useRecaptcha from '../../../../hooks/useRecaptcha';
import FormattedMessage from '../../../../localization/FormatMessage';
import countries from '../../../../resources/countries.json';
import { SignUpParams, SocialSignupParams } from '../../../../types/authentication';
import { getCaptchaToken } from '../../../../utils/recaptchaUtils';
import * as urls from '../../../Routes/urls';
import AuthBasics from '../../Custom/AuthBasics';
import AuthButton from '../../Custom/AuthButton';
import AuthSelect from '../../Custom/AuthSelect';
import formStyles from '../../Form.styl';
import styles from '../SignUp.styl';
import { FieldsType } from '../SignUpRules';
import TermsAndConditions from '../TermsAndConditions';
import { CountrySelectionFieldsType, requiredFields, rules } from './CountrySelectionRules';
import { redirectURLWithLanguage } from '../../../../utils/authUtils';
import { LocalizationContext } from '../../../../localization/LocalizedIntlProvider';

const { Option } = Select;

export class Item extends FormItem<CountrySelectionFieldsType> {}

function CountrySelection() {
    const { includeLang, ...params } = routeUtils.parseQuery<any>(location.search);
    const [loading, setLoading] = useLoadingState(false);
    const { language } = useContext(LocalizationContext);

    const [form] = Form.useForm();
    const authForm = useRef<HTMLFormElement>(null);
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { redirect_uri } = params;

    const actionURL = routeUtils.makeQueryPath(apiUrls.OAUTH_AUTHORIZATION, {
        ...getScreeningEagleHeaders(),
        ...params,
        redirect_uri: includeLang === 'true' ? redirectURLWithLanguage(redirect_uri, language) : redirect_uri,
    });

    const { state } = useLocation<FieldsType & { inviteID?: string }>();

    useRecaptcha();

    const handleSubmit = (values: CountrySelectionFieldsType) => {
        async function action() {
            try {
                setLoading(true);
                const captchaToken = await getCaptchaToken('sign_up');

                const { countryCode } = values;
                let body = {
                    countryCode,
                    captcha: captchaToken,
                };

                analytics.logEventWithName(EventName.SIGN_UP);
                // if from social login redirect to product
                if (params.social) {
                    await signUpSocial({ ...body, social: params.social } as SocialSignupParams);
                    authForm.current?.submit();
                } else {
                    await signUp({ ...body, ...state } as SignUpParams);
                    // else push to sign up done
                    history.push(
                        routeUtils.makeQueryPath(urls.AUTH_ROUTES.SIGN_UP_DONE, {
                            email: state.username,
                            ...params,
                        })
                    );
                }
            } catch (error) {
                handleError(error as APIError);
                setLoading(false);
            }
        }
        action();
    };

    const title = <FormattedMessage id="SignUp.Header.PageTitle" />;

    return (
        <div className="signup">
            <AuthBasics title={title} />
            <div className={styles.description}>
                <p>
                    <FormattedMessage id="SignUp.Select.Region" />
                </p>
            </div>
            <Form form={form} onFinish={handleSubmit} initialValues={{}}>
                <Item
                    className={formStyles.form_item}
                    style={{ paddingBottom: 4 }}
                    name="countryCode"
                    rules={rules.countryCode}
                >
                    <AuthSelect
                        showSearch
                        placeholder={<FormattedMessage id="SignUp.Field.Country" />}
                        style={{
                            height: 54,
                            textAlign: 'left',
                        }}
                    >
                        {countries.map(({ name, code }) => (
                            <Option key={code} value={code}>
                                {name}
                            </Option>
                        ))}
                    </AuthSelect>
                </Item>
                <TermsAndConditions />
                <Item className={styles.last_item} shouldUpdate>
                    {() => (
                        <AuthButton
                            title="SignUp.Button.CreateMyAccount"
                            className={styles.button_container}
                            form={form}
                            loading={loading}
                            requiredFields={requiredFields}
                        />
                    )}
                </Item>
            </Form>
            {params.social && (
                <form ref={authForm} action={actionURL} method="POST" className={styles.hidden_form}>
                    {Object.keys(params).map((key) => (
                        <input key={key} type="hidden" name={key} value={params[key]} />
                    ))}
                </form>
            )}
        </div>
    );
}

export default CountrySelection;
