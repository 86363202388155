import React, { CSSProperties } from 'react';
import { FormatIDs } from '../../types';
import FormattedMessage from '../../localization/FormatMessage';
import styles from './EmptyState.styl';

interface EmptyStateProps {
    Icon: React.ReactNode;
    title: FormatIDs;
    message?: FormatIDs;
    description?: string;
    style?: CSSProperties;
}

const EmptyState: React.FunctionComponent<EmptyStateProps> = (props) => {
    const { Icon, title, message, style, description } = props;
    return (
        <div className={styles.empty_state_wrapper} style={style}>
            {Icon}
            <h1 className={styles.title}>
                <FormattedMessage id={title} />
            </h1>
            <p style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                {description}
                {message && <FormattedMessage id={message} />}
            </p>
        </div>
    );
};

export default EmptyState;
