import React from 'react';
import { Rule } from 'antd/lib/form';
import FormattedMessage from '../../../../localization/FormatMessage';
import { uuidRegex } from '../../../../utils/regex';
import Link from '../../../Routes/Link';
import styles from './Activation.styl';
import postMessenger from '../../../../utils/PostMessenger';

export interface FieldsType {
    licenseKey: string;
}

export type FieldsKeys = keyof FieldsType;

export const licenseKeyMaxLength = 36;

const SupportLink = () => {
    const linkStyle: React.CSSProperties = { textDecoration: 'underline' };
    return postMessenger.parentOrigin ? (
        <a style={linkStyle} onClick={() => postMessenger.post({ type: 'openContactForm', data: {} })}>
            <FormattedMessage id="License.Field.LicenseKey.SupportLink" />
        </a>
    ) : (
        <Link style={{ fontSize: 'inherit', ...linkStyle }} to={'contactSales'}>
            <FormattedMessage id="License.Field.LicenseKey.SupportLink" />
        </Link>
    );
};

export const invalidKeyMessage = (
    <span className={styles.error_message}>
        <FormattedMessage id="License.Field.LicenseKey.Error.Invalid" values={{ supportLink: <SupportLink /> }} />
    </span>
);

export const activatedKeyMessage = (
    <span className={styles.error_message}>
        <FormattedMessage id="License.Field.LicenseKey.Error.Activated" values={{ supportLink: <SupportLink /> }} />
    </span>
);

export const rules: { [K in keyof FieldsType]?: Rule[] } = {
    licenseKey: [
        {
            required: true,
            message: <FormattedMessage id="License.Field.LicenseKey.Error.Empty" />,
            max: 36,
        },
        {
            pattern: uuidRegex,
            message: <FormattedMessage id="License.Field.LicenseKey.Error.Incomplete" />,
        },
    ],
};
