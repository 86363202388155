import React from 'react';
import styles from './Profile.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import { ReactComponent as TickIcon } from '../../../../images/tickCircled.svg';
import { StopOutlined } from '@ant-design/icons';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { getTwoFASetUpRoute, SetUpFlowStage } from '../../../Routes/urls';
import { useHistory } from 'react-router-dom';
import { routeUtils } from 'tds-common-fe';
import { ProfileTwoFAQueryParams } from '../../../../types/authentication';
import postMessenger from '../../../../utils/PostMessenger';
import analytics from '../../../../analytics';
import { EventName } from '../../../../analytics/analyticsConstants';

interface HeaderProps {
    is2FAEnabled?: boolean;
}

const Header: React.FunctionComponent<HeaderProps> = ({ is2FAEnabled }) => {
    const history = useHistory();

    // remove theme as 2fa set up flow needs to be in dark theme
    const { theme, ...otherParams } = routeUtils.parseQuery<ProfileTwoFAQueryParams>(location.search);

    return (
        <div className={styles.header_main} style={is2FAEnabled ? {} : { marginBottom: 0 }}>
            <div className={styles.header}>
                <FormattedMessage id="2FA" />
            </div>
            {is2FAEnabled ? (
                <div className={styles.header_subtitle}>
                    <TickIcon className={styles.enabled_icon} />
                    <FormattedMessage id="2FA.Profile.Header.Enabled" />
                </div>
            ) : (
                <div className={styles.header_subtitle}>
                    <StopOutlined className={styles.set_up_icon} />
                    <FormattedMessage id="2FA.Profile.Header.SetUp" />
                    <AnalyticsButton
                        className={styles.set_up_button}
                        type="link"
                        onClick={() => {
                            analytics.logEventWithName(EventName.PROFILE_2FA_SETUP);
                            if (postMessenger.parentOrigin) {
                                // send message to parent so that the parent can open the page directly
                                postMessenger.post({ type: 'setUp2FA', data: {} });
                            } else {
                                history.push({
                                    pathname: getTwoFASetUpRoute(SetUpFlowStage.setUpAuthenticator),
                                    search: routeUtils.makeQueryPath('', { ...otherParams, theme: 'dark' }),
                                });
                            }
                        }}
                    >
                        <FormattedMessage id="2FA.Profile.Header.SetUp.Button" />
                    </AnalyticsButton>
                </div>
            )}
        </div>
    );
};

export default Header;
