import React from 'react';
import { routeUtils } from 'tds-common-fe';
import { Input } from 'antd';
import { useHistory } from 'react-router-dom';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './AccountDeletion.styl';
import Button from '../../AnalyticsComponents/Button';
import * as urls from '../../Routes/urls';
import analytics from '../../../analytics/index';

interface InitiateAccountDeletionParams {
    email: string;
    redirect_uri: string;
    product: string;
}

const InitiateAccountDeletion: React.FunctionComponent = () => {
    const params = routeUtils.parseQuery<InitiateAccountDeletionParams>(location.search);
    const { email, product = 'workspace', redirect_uri: redirectURL } = params;
    const history = useHistory();
    return (
        <>
            <div className={styles.container}>
                <div className={styles.title}>
                    <FormattedMessage id="AccountDeletion.Initiate.Title" />
                </div>
                <div className={styles.subtitle1}>
                    <FormattedMessage id="AccountDeletion.Initiate.Body" />
                </div>
            </div>
            <div className={styles.email_container}>
                <div className={styles.disabled_input_title}>
                    <FormattedMessage id="ProductName.EagleID" />
                </div>
                <div>
                    <Input className={styles.input} disabled type="text" size="large" value={email} />
                </div>
                <div className={styles.button_container}>
                    <Button
                        type="primary"
                        onClick={() => {
                            analytics.logRequestDeleteAccount('initiate_request');
                            const newPath = routeUtils.makeQueryPath(urls.AUTH_ROUTES.VERIFY_PASSWORD, {
                                product,
                                email,
                                action: 'user_delete_request',
                                client: 'web',
                                close_tab: 'false',
                                cancel_redirect_uri: redirectURL,
                            });
                            history.push(newPath);
                        }}
                        size="large"
                    >
                        <FormattedMessage id="AccountDeletion.Initiate.Button" />
                    </Button>
                    <Button
                        href={redirectURL}
                        onClick={() => analytics.logRequestDeleteAccount('cancel')}
                        size="large"
                        type="link"
                    >
                        <FormattedMessage id="Common.Cancel" />
                    </Button>
                </div>
            </div>
        </>
    );
};

export default InitiateAccountDeletion;
