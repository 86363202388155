import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

export const intlProxy = {
    locale: 'en',
};

const IntlProxy: React.FunctionComponent = () => {
    const locale = useIntl().locale;

    useEffect(() => {
        intlProxy.locale = locale;
    }, [locale]);

    return null;
};

export default IntlProxy;
