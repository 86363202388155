import { isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { differenceBy } from 'lodash';
import { Row, Col } from 'antd';

import FormattedMessage from '../../../../localization/FormatMessage';
import AppWrapper from '../../AppWrapper';
import styles from './UpgradeInfo.styl';
import { APP_ROUTES, getRouteWithUserID } from '../../../Routes/urls';
import { usePostPageHeight } from '../PurchaseForm/usePostPageHeight';
import * as licenseService from '../../../../api/licenseService';
import PlanInfoCard from './PlanInfoCard';
import { loadingPlaceholder } from '../..';
import useLicenseTiers from '../../../../hooks/useLicenseTiers';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { RootState } from '../../../../reducers';

const UpgradeInfo: React.FunctionComponent = () => {
    const history = useHistory();
    const { userID } = useParams<{ userID: string }>();
    const license = useSelector((state: RootState) => state.profile.userLicense);
    const userLicenseRank = license.active ? license.tier?.rank : 0;

    const { tiers, tierIDs } = useLicenseTiers();
    const nonFreeTierIDs = useMemo(
        () => tierIDs.filter((id) => !tiers[id].free && tiers[id].rank > (userLicenseRank || 0)),
        [userLicenseRank, tierIDs, tiers]
    );

    const getUniqueFeatures = (position: number, tierID: string) => {
        let featuresIncluded = tiers[tierID].features;
        if (nonFreeTierIDs[position - 1]) {
            featuresIncluded = differenceBy(
                tiers[tierID].features,
                tiers[nonFreeTierIDs[position - 1]].features,
                'code'
            );
        }
        return featuresIncluded;
    };

    const [isLoading, setLoading] = useState(true);

    const { containerRef } = usePostPageHeight({ minHeight: 300, isReady: !isLoading });

    useEffect(() => {
        licenseService.getLicenseTiers().finally(() => {
            setLoading(false);
        });
    }, []);

    // Temporary disable navigation to purchase form
    // const handleOnClick = useCallback(
    //     (tierID: string) => {
    //         const path = getPurchaseFormPath(userID, tierID);
    //         history.push(path);
    //     },
    //     [history, userID]
    // );

    const isTierEmpty = isEmpty(tiers);

    return isLoading ? (
        loadingPlaceholder
    ) : (
        <AppWrapper className={styles.app_wrapper}>
            <div className={styles.container} ref={containerRef}>
                <h1 className={styles.modal_title} style={{ marginBottom: 26 }}>
                    <FormattedMessage id="License.Subscription.Title" />
                </h1>

                {isTierEmpty ? (
                    <p>
                        <FormattedMessage id="License.Tier.Empty" />
                    </p>
                ) : (
                    <>
                        <Row gutter={10} style={{ width: '100%' }} justify="center">
                            {nonFreeTierIDs.map((id, index) => (
                                <Col
                                    key={id}
                                    xs={24}
                                    sm={Math.min(24 / nonFreeTierIDs.length, 12)}
                                    style={{ marginBottom: 10 }}
                                >
                                    <PlanInfoCard
                                        tier={tiers[id]}
                                        features={getUniqueFeatures(index, id)}
                                        showPricing={false}
                                        previousTierName={tiers[nonFreeTierIDs[index - 1]]?.name}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <AnalyticsButton
                            type="primary"
                            className={styles.button_style}
                            onClick={() => history.push(getRouteWithUserID(APP_ROUTES.CONTACT_SALES, userID))}
                        >
                            <FormattedMessage id="License.Subscription.Help.Action" />
                        </AnalyticsButton>
                    </>
                )}
                <div style={{ height: 16 }} />
            </div>
        </AppWrapper>
    );
};

export default UpgradeInfo;
