import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, message } from 'antd';
import { FormItem, NonOptionalKeys, useFormCheckErrors } from 'tds-common-fe';
import { useHistory, useParams } from 'react-router-dom';
import AppWrapper from '../../AppWrapper';
import styles from './PurchaseForm.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import ContactFields from '../Form/ContactFields';
import { FieldsType as ContactFieldsType } from '../Form/contactFieldsRules';
import { FieldsType as OrderSummaryFieldsType } from './orderSummaryRules';
import { APP_ROUTES, getRouteWithUserID } from '../../../Routes/urls';
import StepProgress from './StepProgress';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { FormatIDs } from '../../../../types';
import OrderSummary from './OrderSummary';
import { enumToArray } from '../../../../utils/generalUtils';
import { usePostPageHeight } from './usePostPageHeight';
import * as licenseService from '../../../../api/licenseService';
import { getFormattedPhoneNumber } from '../../../../utils/phoneUtils';
import { CurrencyCode, LicensePrice, LicenseTier } from '../../../../types/license';
import { RootState } from '../../../../reducers';
import { loadingPlaceholder } from '../..';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import { getLicensePrice } from '../../../../utils/pricingUtils';
import { getCaptchaToken } from '../../../../utils/recaptchaUtils';

interface FieldsType extends ContactFieldsType, OrderSummaryFieldsType {}

class Item extends FormItem<FieldsType> {}

type FormValues = { [Key in keyof FieldsType]: string };

export interface StepConfig {
    titleID: FormatIDs;
    requiredFields: NonOptionalKeys<FieldsType>[];
}

enum PurchaseStep {
    invoiceInfo,
    orderSummary,
}

export const stepConfigs: { [key in PurchaseStep]: StepConfig } = {
    [PurchaseStep.invoiceInfo]: {
        titleID: 'PurchaseForm.Step.InvoiceInfo',
        requiredFields: ['firstName', 'lastName', 'email', 'companyName', 'countryCode', 'addressLine1', 'phone'],
    },

    [PurchaseStep.orderSummary]: {
        titleID: 'PurchaseForm.Step.OrderSummary',
        requiredFields: ['currencyCode', 'userVolume', 'agreeTnC'],
    },
};

export const purchaseSteps = enumToArray(PurchaseStep);

export type PurchaseFormTier = Pick<LicenseTier, 'tier' | 'name' | 'discount' | 'discountDescription'> & {
    pricing: LicensePrice[];
};

interface PurchaseFormProps {
    tierInfo: PurchaseFormTier;
    userID: string;
}

const PurchaseForm: React.FunctionComponent<PurchaseFormProps> = (props) => {
    const { userID, tierInfo } = props;
    const { discount, discountDescription, pricing } = tierInfo;
    const { formatMessage } = useFormatMessage();

    const [form] = Form.useForm();
    const history = useHistory();
    const { containerRef, scrollRef } = usePostPageHeight();
    const inputs = useRef<HTMLInputElement[]>([]);
    const [valueStore, setValueStore] = useState<FormValues[]>([]);
    const [currentStep, setCurrentStep] = useState<PurchaseStep>(purchaseSteps[0]);
    const [useDefaultEmail, setUseDefaultEmail] = useState(true);

    const storeCurrentValues = useCallback(
        (values: FormValues) => {
            const newValueStore = [...valueStore];
            newValueStore[currentStep] = { ...values };
            setValueStore(newValueStore);
            return newValueStore;
        },
        [currentStep, valueStore]
    );
    const nextStep = () => {
        setCurrentStep((step) => step + 1);
    };

    const prevStep = () => {
        setCurrentStep((step) => Math.max(step - 1, 0));
    };

    useEffect(() => {
        scrollRef.current?.scrollTo({ top: 0 });
    }, [currentStep, scrollRef]);

    const handleValuesChange = (changedValues: { [Key in keyof FieldsType]: string }) => {
        if (changedValues.countryCode) {
            // Preventing USD currency data remains after countryCode value is changed from US
            if (form.getFieldValue('currencyCode') === CurrencyCode.USD) {
                form.setFieldsValue({ currencyCode: undefined });
            }
        }
    };

    const handleSubmit = async (values: { [Key in keyof FieldsType]: string }) => {
        const valuesInSteps = storeCurrentValues(values);
        const allValues: FormValues = valuesInSteps.reduce((prevVals, vals) => ({ ...prevVals, ...vals }), {} as any);
        const { firstName, lastName, countryCode, phone, companyName, currencyCode, email } = allValues;

        if (isFinalStep) {
            const contactNumber = getFormattedPhoneNumber(phone, countryCode);
            const licensePrice = getLicensePrice(pricing, currencyCode);
            if (!licensePrice) {
                message.error(formatMessage({ id: 'PurchaseForm.Failure.Price' }));
                return;
            }
            const token = await getCaptchaToken('upgrade_request');
            licenseService
                .requestUpgradeLicense({
                    firstName,
                    lastName,
                    countryCode,
                    companyName,
                    contactNumber,
                    contactEmail: email,
                    captcha: token,
                })
                .then(() => {
                    // Navigate to success page
                    const path = getRouteWithUserID(APP_ROUTES.LICENSE_PURCHASE_DONE, userID);
                    history.push(path);
                })
                .catch(() => {});
        } else {
            nextStep();
        }
    };

    const { checkErrors } = useFormCheckErrors(form, stepConfigs[purchaseSteps[currentStep]].requiredFields);

    const isFinalStep = currentStep === purchaseSteps[purchaseSteps.length - 1];

    return (
        <AppWrapper className={styles.app_wrapper}>
            <div className={styles.container} ref={containerRef}>
                <div className={styles.form_section}>
                    <h1 className={styles.form_title}>
                        <FormattedMessage id="License.Upgrade.Title" values={{ tierName: tierInfo.name }} />
                    </h1>
                    <StepProgress currentStep={currentStep} />
                </div>
                <Form
                    form={form}
                    className={styles.form}
                    layout="vertical"
                    style={{ width: '100%' }}
                    onFinish={handleSubmit as any}
                    requiredMark={false}
                    onValuesChange={handleValuesChange as any}
                >
                    <div className={styles.form_scrollable_area} ref={scrollRef}>
                        <div className={styles.form_section}>
                            {currentStep === PurchaseStep.invoiceInfo && (
                                <ContactFields
                                    inputs={inputs}
                                    form={form}
                                    useDefaultEmail={useDefaultEmail}
                                    onSetUseDefaultEmail={setUseDefaultEmail}
                                    emailCheckboxMessage="PurchaseForm.Email.EagleID.SameEmail"
                                    emailSettings={{
                                        label: 'Contact.Field.Email.Label',
                                        placeholder: 'Contact.Field.Email.Label',
                                        emptyErrorMessage: 'Contact.Field.Email.Error.Empty',
                                    }}
                                    hasAddress
                                />
                            )}

                            {currentStep === PurchaseStep.orderSummary && (
                                <OrderSummary
                                    tierName={tierInfo.name}
                                    form={form}
                                    inputs={inputs}
                                    pricing={pricing}
                                    discount={discount}
                                    discountDescription={discountDescription}
                                    countryCode={valueStore[PurchaseStep.invoiceInfo].countryCode}
                                />
                            )}
                        </div>
                    </div>
                    <div className={`${styles.actions_area} fill_form_row`}>
                        <Item shouldUpdate style={{ margin: 0 }}>
                            {() => (
                                <div className={styles.button_wrapper_container}>
                                    <div className={styles.button_wrapper}>
                                        {currentStep > purchaseSteps[0] && (
                                            <AnalyticsButton
                                                onClick={prevStep}
                                                type="link"
                                                className={styles.back_button}
                                            >
                                                <div className={styles.back_icon} />
                                                <FormattedMessage id="Common.Back" />
                                            </AnalyticsButton>
                                        )}
                                        <AnalyticsButton
                                            className={styles.button_style}
                                            type="primary"
                                            htmlType="submit"
                                            disabled={checkErrors()}
                                        >
                                            <FormattedMessage id={isFinalStep ? 'Common.Submit' : 'Common.Next'} />
                                        </AnalyticsButton>
                                    </div>
                                </div>
                            )}
                        </Item>
                    </div>
                </Form>
            </div>
        </AppWrapper>
    );
};

const PurchaseFormWrapper: React.FunctionComponent = () => {
    const { userID, tierID } = useParams<{ userID: string; tierID: string }>();

    const tierRaw = useSelector((state: RootState) => state.license.getIn(['tier', 'tiers', tierID]));
    const tier: LicenseTier | undefined = useMemo(() => (tierRaw ? tierRaw.toJS() : undefined), [tierRaw]);

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (!tier) {
            setLoading(true);
            licenseService.getLicenseTiers().finally(() => {
                setLoading(false);
            });
        }
    }, [tier]);

    return tier && tier.pricing ? (
        <PurchaseForm
            userID={userID}
            tierInfo={{
                name: tier.name,
                discount: tier.discount,
                discountDescription: tier.discountDescription,
                tier: tier.tier,
                pricing: tier.pricing,
            }}
        />
    ) : isLoading ? (
        loadingPlaceholder
    ) : (
        <div>Invalid License Tier</div>
    );
};

export default PurchaseFormWrapper;
