import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import AnalyticsRoute from '../AnalyticsComponents/Route';
import AuthWrapper from '../Authentication/AuthWrapper';
import MultipleDevicesLogin from '../Authentication/MultipleDevices/MultipleDevicesLogin';
import MultipleDevicesWarning from '../Authentication/MultipleDevices/MultipleDevicesWarning';
import { MULTIPLE_DEVICES_ROUTES, MULTIPLE_DEVICES_ROUTE_BASE } from './urls';
import MultipleDevicesRedirection from '../Authentication/MultipleDevices/MultipleDevicesRedirection';

const MultipleDevicesRoutes: React.FunctionComponent = () => {
    return (
        <>
            <Switch>
                <AnalyticsRoute
                    exact
                    path={MULTIPLE_DEVICES_ROUTES.LOGIN_REDIRECTION}
                    titleFormatIDs={['NavTitle.MultipleDevices.Redirection']}
                >
                    <MultipleDevicesRedirection />
                </AnalyticsRoute>
            </Switch>
            <AuthWrapper showLanguageSwitcher={false}>
                <Switch>
                    <AnalyticsRoute
                        exact
                        path={MULTIPLE_DEVICES_ROUTES.LOGIN}
                        titleFormatIDs={['NavTitle.MultipleDevices.Login']}
                    >
                        <MultipleDevicesLogin />
                    </AnalyticsRoute>
                    <AnalyticsRoute
                        exact
                        path={MULTIPLE_DEVICES_ROUTES.WARNING}
                        titleFormatIDs={['NavTitle.MultipleDevices.Warning']}
                    >
                        <MultipleDevicesWarning />
                    </AnalyticsRoute>

                    <Redirect
                        exact
                        strict={false}
                        from={MULTIPLE_DEVICES_ROUTE_BASE}
                        to={MULTIPLE_DEVICES_ROUTES.LOGIN}
                    />
                </Switch>
            </AuthWrapper>
        </>
    );
};

export default MultipleDevicesRoutes;
