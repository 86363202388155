import React, { useState } from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Item } from '..';
import { PASSWORD_MAX_LENGTH } from '../../../../config/constants';
import { FormSteps } from '../../../../types/authentication';
import AuthInput from '../../Custom/AuthInput';
import signUpStyles from '../SignUp.styl';
import formStyles from '../../Form.styl';
import { rules } from '../SignUpRules';
import FormattedMessage from '../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../types';

type PasswordProps = {
    inputs: React.MutableRefObject<HTMLInputElement[]>;
    passwordPlaceholder: string;
    confirmPasswordPlaceholder: string;
    step: FormSteps;
    form: any;
};

export enum PasswordState {
    characters = 'characters',
    numberAndSymbol = 'numberAndSymbol',
    lowerAndUpperCase = 'lowerAndUpperCase',
    email = 'email',
}

const validationTexts: {
    [key in PasswordState]: FormatIDs;
} = {
    [PasswordState.characters]: 'SignUp.Password.Validation.Characters',
    [PasswordState.numberAndSymbol]: 'SignUp.Password.Validation.DigitsAndSymbols',
    [PasswordState.lowerAndUpperCase]: 'SignUp.Password.Validation.LowerAndUpperCase',
    [PasswordState.email]: 'SignUp.Password.Validation.Email',
};

function Password({ inputs, passwordPlaceholder, confirmPasswordPlaceholder, form, step }: PasswordProps) {
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordState, setPasswordState] = useState({
        [PasswordState.characters]: false,
        [PasswordState.numberAndSymbol]: false,
        [PasswordState.lowerAndUpperCase]: false,
        [PasswordState.email]: false,
    });

    const visible = step === FormSteps.password;
    const itemClassName = visible ? formStyles.form_item : formStyles.form_item_hidden;
    const inputType = visible ? 'password' : 'default';

    return (
        <>
            <Item
                className={`${itemClassName} password`}
                name="password"
                dependencies={['username']}
                rules={rules.password}
                style={{ marginBottom: 8 }}
            >
                <AuthInput
                    authInputType={inputType}
                    placeholder={passwordPlaceholder}
                    inputs={inputs}
                    maxLength={PASSWORD_MAX_LENGTH}
                    onChange={(e) => {
                        const password = e.target.value;
                        // validate password

                        const checkEmail = () => {
                            const name = form.getFieldValue('username').split('@', 2)[0];
                            return !password.toLowerCase().includes(name.toLowerCase()) && password.length > 0;
                        };

                        const passwordValidation = {
                            [PasswordState.characters]: password?.length >= 8,
                            [PasswordState.numberAndSymbol]: /[0-9]/.test(password) && /[^A-Za-z0-9]/.test(password),
                            [PasswordState.lowerAndUpperCase]: /[a-z]/.test(password) && /[A-Z]/.test(password),
                            [PasswordState.email]: checkEmail(),
                        };
                        setPasswordState(passwordValidation);

                        form.validateFields(['password'])
                            .then(() => setShowConfirmPassword(true))
                            .catch(() => setShowConfirmPassword(false));
                    }}
                />
            </Item>
            <div
                className={signUpStyles.passwordHelpText}
                style={{
                    display: visible ? 'block' : 'none',
                }}
            >
                {Object.keys(passwordState).map((key) => {
                    const passwordStateKey = key as PasswordState;
                    const className = passwordState[passwordStateKey] ? signUpStyles.valid : '';

                    return (
                        <p key={key} className={className}>
                            <CheckOutlined />
                            <FormattedMessage id={validationTexts[passwordStateKey]} />
                        </p>
                    );
                })}
            </div>
            <Item
                className={itemClassName}
                name="passwordConfirm"
                dependencies={['password']}
                rules={rules.passwordConfirm}
                hidden={!showConfirmPassword}
            >
                <AuthInput
                    authInputType={inputType}
                    placeholder={confirmPasswordPlaceholder}
                    maxLength={PASSWORD_MAX_LENGTH}
                />
            </Item>
        </>
    );
}

export default Password;
