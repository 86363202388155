import { InputNumber } from 'antd';
import React, { useRef } from 'react';
import styles from './VerificationCodeInput.styl';

interface VerificationCodeInputProps {
    code: (number | undefined)[];
    setCode: (code: (number | undefined)[]) => void;
}

const VerificationCodeInput: React.FunctionComponent<VerificationCodeInputProps> = (props) => {
    const { code, setCode } = props;
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const onChange = (newValue: any, index: number) => {
        const newCode = code.map((val, codeIndex) => (codeIndex === index ? newValue : val));
        setCode(newCode);
        const nextIndex = newCode.findIndex((i) => i === undefined);
        if (nextIndex > 0) {
            inputRefs.current[newCode.findIndex((i) => i === undefined)]?.focus();
        } else {
            inputRefs.current[index].blur();
        }
    };

    const onKeyDown = (e: React.KeyboardEvent, index: number) => {
        if (e.key === 'Backspace') {
            if (!code[index] && index > 0) {
                const newCode = code.map((val, codeIndex) => (codeIndex === index - 1 ? undefined : val));
                setCode(newCode);
                inputRefs.current[index - 1]?.focus();
            } else {
                const newCode = code.map((val, codeIndex) => (codeIndex === index ? undefined : val));
                setCode(newCode);
            }
        }
    };

    return (
        <div className={styles.input_container}>
            {code.map((value, index) => (
                <InputNumber
                    type="tel"
                    className={styles.input_code}
                    ref={(el) => {
                        inputRefs.current[index] = el as HTMLInputElement;
                    }}
                    onChange={(num) => onChange(num, index)}
                    autoFocus={index === 0}
                    onClick={(e) => {
                        // move cursor pointer to end of value
                        if (value !== undefined) {
                            e.currentTarget.setSelectionRange(1, 1);
                        }
                    }}
                    onKeyDown={(e) => onKeyDown(e, index)}
                    value={value}
                    key={index} // add reset to key to force new element with empty value
                    maxLength={1}
                    size="large"
                    min={0}
                    max={9}
                    controls={false}
                />
            ))}
        </div>
    );
};

export default VerificationCodeInput;
