import React, { useMemo } from 'react';
import { Checkbox, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormItem } from 'tds-common-fe';
import { rules, FieldsType } from './orderSummaryRules';
import styles from './OrderSummary.styl';
import AuthSelect from '../../../Authentication/Custom/AuthSelect';
import FormattedMessage from '../../../../localization/FormatMessage';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import NewTabLink from '../../../Shared/NewTabLink';
import { POLICY_URL, TNC_URL } from '../../../../config/constants';
import AuthTextArea from '../../../Authentication/Custom/AuthTextArea';
import UserVolumeInput from './UserVolumeInput';
import { LicensePrice } from '../../../../types/license';
import { getPricingInfo } from '../../../../utils/pricingUtils';

class Item extends FormItem<FieldsType> {}

const { Option } = Select;

interface OrderSummaryProps {
    tierName: string;
    inputs: React.MutableRefObject<HTMLInputElement[]>;
    form: FormInstance;
    pricing: LicensePrice[];
    discount?: number;
    discountDescription?: string;
    countryCode: string;
}

function getLicensePrice(pricing: LicensePrice[], currencyCode: string): LicensePrice | undefined {
    return pricing.find((val) => val.currencyCode === currencyCode);
}

const OrderSummary: React.FunctionComponent<OrderSummaryProps> = (props) => {
    const { inputs, form, pricing, discount, discountDescription, countryCode, tierName } = props;
    const { getFieldValue } = form;
    const { formatMessage } = useFormatMessage();

    const { fallbackCurrencyCode, filteredPricing } = useMemo(() => {
        return getPricingInfo(countryCode, pricing);
    }, [countryCode, pricing]);

    if (pricing.length === 0) {
        return null;
    }

    const fields = {
        currency: (
            <Item
                label={<FormattedMessage id="PurchaseForm.Field.Currency" />}
                rules={rules.currencyCode}
                name="currencyCode"
                initialValue={fallbackCurrencyCode}
            >
                <AuthSelect size="middle" disabled={filteredPricing.length <= 1}>
                    {filteredPricing.map((price) => (
                        <Option key={price.currencyCode} value={price.currencyCode}>
                            {price.currency} ({price.currencyCode})
                        </Option>
                    ))}
                </AuthSelect>
            </Item>
        ),
        userVolume: (
            <Item shouldUpdate={(prevValues, curValues) => prevValues.currencyCode !== curValues.currencyCode}>
                {() => {
                    const currencyCode = getFieldValue('currencyCode') ?? fallbackCurrencyCode;
                    const licensePrice = getLicensePrice(pricing, currencyCode)!;
                    const { monthlyPrice, yearlyPrice, yearlyDiscountedPrice } = licensePrice;

                    return (
                        <Item
                            initialValue={1}
                            name="userVolume"
                            className={styles.form_item}
                            rules={rules.userVolume}
                            shouldUpdate
                        >
                            <UserVolumeInput
                                tierName={tierName}
                                monthlyPrice={monthlyPrice}
                                annualPrice={yearlyDiscountedPrice ?? yearlyPrice}
                                currencyShort={getFieldValue('currencyCode') ?? fallbackCurrencyCode}
                                promotionContent={discountDescription}
                                discountPercent={discount}
                            />
                        </Item>
                    );
                }}
            </Item>
        ),
        additionalNotes: (
            <Item
                label={<FormattedMessage id="PurchaseForm.Field.Notes" />}
                name="additionalNotes"
                className={styles.form_item}
            >
                <AuthTextArea
                    placeholder={formatMessage({ id: 'Contact.Field.Message.Placeholder' })}
                    inputs={inputs}
                    rows={5}
                />
            </Item>
        ),

        agreeTnc: (
            <Item name="agreeTnC" className={styles.form_item} rules={rules.agreeTnC} valuePropName="checked">
                <Checkbox className={styles.form_item}>
                    <FormattedMessage
                        id="SignUp.Field.TNC"
                        values={{
                            policy: (
                                <NewTabLink url={POLICY_URL} insertTimestamp>
                                    <FormattedMessage id="SignUp.Field.TNC.Policy" />
                                </NewTabLink>
                            ),
                            tnc: (
                                <NewTabLink url={TNC_URL} insertTimestamp>
                                    <FormattedMessage id="SignUp.Field.TNC.GeneralTNC" />
                                </NewTabLink>
                            ),
                        }}
                    />
                </Checkbox>
            </Item>
        ),
    };

    const totalInfo = (
        <Item shouldUpdate>
            {() => {
                const userVolume: number = getFieldValue('userVolume');
                const currencyCode = getFieldValue('currencyCode') ?? fallbackCurrencyCode;
                const licensePrice = getLicensePrice(pricing, currencyCode)!;
                const { yearlyPrice, yearlyDiscountedPrice } = licensePrice;
                const totalYearlyOriginalPrice = yearlyPrice * userVolume;
                const totalYearlyDiscountedPrice = (yearlyDiscountedPrice ?? 0) * userVolume;
                const totalYearlyPrice =
                    totalYearlyDiscountedPrice > 0 ? totalYearlyDiscountedPrice : totalYearlyOriginalPrice;

                return (
                    <div className={styles.total_info}>
                        <div>
                            <span className={styles.total_title}>
                                <span>
                                    <FormattedMessage id="Common.Total" />
                                </span>
                                <span style={{ fontSize: 14 }}>
                                    <FormattedMessage id="PurchaseForm.ExcludingVAT" />
                                </span>
                            </span>
                        </div>
                        <div className={styles.total_value}>
                            {totalYearlyDiscountedPrice > 0 && (
                                <div className={styles.total_value_original}>
                                    {currencyCode} {totalYearlyOriginalPrice.toFixed(2)}
                                </div>
                            )}
                            <div className={styles.total_value_final}>
                                <span>{currencyCode} </span>
                                <span>{totalYearlyPrice.toFixed(2).slice(0, -3)}</span>
                                <span style={{ fontSize: 14 }}>{totalYearlyPrice.toFixed(2).slice(-3)}</span>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Item>
    );

    return (
        <>
            {fields.currency}
            {fields.userVolume}
            {totalInfo}
            {fields.additionalNotes}
            {fields.agreeTnc}
        </>
    );
};

export default OrderSummary;
