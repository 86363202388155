import { routeUtils } from 'tds-common-fe';
import * as licenseActions from '../actions/license';
import { APIResponse } from '../types';
import { LicenseTier, ProductCode, RequestSalesContactParams } from '../types/license';
import { thunkGet, post } from './apiService';
import urls from './urls';
import { handleError } from './error';

interface LicenseTiersParams {
    product?: string;
    free?: boolean;
}

export async function getLicenseTiers(params: LicenseTiersParams = { free: false }) {
    type Response = APIResponse<LicenseTier[]>;
    const url = routeUtils.makeQueryPath(urls.LICENSE_TIER, {
        product: params.product,
        free: params.free ? 'true' : 'false',
    });
    const { response, dispatch } = await thunkGet<Response>(url);
    dispatch(licenseActions.receivedLicenseTiers(response.data));
}

export function requestSalesContact(params: RequestSalesContactParams, product?: ProductCode) {
    type Response = APIResponse<{}>;
    const url = routeUtils.makeQueryPath(urls.REQUEST_SALES_CONTACT, { product: product?.toLowerCase() });
    return post<Response>(url, { params: { ...params }, errorHandler: handleError });
}

export function requestUpgradeLicense(params: RequestSalesContactParams, product?: ProductCode) {
    type Response = APIResponse<{}>;
    const url = routeUtils.makeQueryPath(urls.REQUEST_UPGRADE_LICENSE, { product: product?.toLowerCase() });
    return post<Response>(url, { params: { ...params }, errorHandler: handleError });
}
