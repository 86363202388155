import React from 'react';
import { Col, Row } from 'antd';
import { AuthType, Socials } from '../../../types/authentication';
import signUpStyles from './SignUp.styl';
import SocialButton from '../../Shared/SocialButton';
import FormattedMessage from '../../../localization/FormatMessage';

type SocialButtonsProps = {
    authType?: AuthType;
    setLoading: (loading: boolean) => void;
};

function SocialButtons({ authType = AuthType.SignUp, setLoading }: SocialButtonsProps) {
    return (
        <>
            <div className={signUpStyles.signUpWith}>
                <hr />
                <span>
                    <FormattedMessage id={authType === 'signup' ? 'SignUp.With' : 'Login.With'} />
                </span>
                <hr />
            </div>

            <Row className={signUpStyles.social_buttons} gutter={[16, 16]}>
                {Object.values(Socials).map((social) => (
                    <Col span={8} xs={24} md={8} key={social}>
                        <SocialButton provider={social} authType={authType} setLoading={setLoading} />
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default SocialButtons;
