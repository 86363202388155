import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { EventName } from '../../../../analytics/analyticsConstants';
import analytics from '../../../../analytics/index';
import FormattedMessage from '../../../../localization/FormatMessage';
import postMessenger from '../../../../utils/PostMessenger';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { APP_ROUTES, getRouteWithUserID } from '../../../Routes/urls';
import styles from './ContactSales.styl';

const ContactSection = () => {
    const history = useHistory();
    const { userID } = useParams<{ userID: string }>();

    return (
        <div className={styles.contact_section} data-mode={'bright'}>
            <FormattedMessage id="License.Subscription.Contact.text" />
            <AnalyticsButton
                type="default"
                className="upgrade_button"
                onClick={() => {
                    analytics.logEventWithName(EventName.CONTACT_SALES);
                    if (postMessenger.parentOrigin) {
                        postMessenger.post({ type: 'openContactForm', data: {} });
                    } else {
                        history.push(getRouteWithUserID(APP_ROUTES.LICENSE_UPGRADE_INFO, userID));
                    }
                }}
            >
                <FormattedMessage id="License.Subscription.Help.Action" />
            </AnalyticsButton>
        </div>
    );
};

export default ContactSection;
