import React, { useContext } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { IconButton } from 'tds-common-fe';
import * as appUtils from '../../utils/appUtils';
import FormattedMessage from '../../localization/FormatMessage';
import { ReactComponent as GlobeIcon } from '../../images/GlobeIcon.svg';
import { languageKeys, LanguageOptions } from '../../localization';
import { LocalizationContext } from '../../localization/LocalizedIntlProvider';
import { FormatIDs } from '../../types';
import styles from './LanguageSwitcher.styl';

function getFormatID(key: LanguageOptions): FormatIDs {
    switch (key) {
        case 'en':
            return 'Language.EN';
        case 'zh':
            return 'Language.ZH';
        case 'ja':
            return 'Language.JA';
        case 'ko':
            return 'Language.KO';
        case 'de':
            return 'Language.DE';
        case 'fr':
            return 'Language.FR';
        case 'it':
            return 'Language.IT';
        case 'pt':
            return 'Language.PT';
        case 'ru':
            return 'Language.RU';
        case 'es':
            return 'Language.ES';
        case 'id':
            return 'Language.ID';
        case 'sv':
            return 'Language.SV';
        default:
            return 'Empty';
    }
}

const LanguageSwitcher: React.FunctionComponent = () => {
    const { setLanguage, language } = useContext(LocalizationContext);

    const menuProps: MenuProps = {
        onClick: (menuInfo) => setLanguage(menuInfo.key as LanguageOptions),
        items: languageKeys.map((key) => ({
            key,
            label: (
                <div className={styles.menu_item}>
                    <FormattedMessage id={getFormatID(key)} />
                    {key === language && <CheckOutlined />}
                </div>
            ),
        })),
    };

    return !appUtils.fromApp() && languageKeys.length > 1 ? (
        <Dropdown menu={menuProps} trigger={['click']} arrow getPopupContainer={(node) => node.myParentElement()}>
            <IconButton
                className={styles.globe_button}
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            >
                <GlobeIcon />
            </IconButton>
        </Dropdown>
    ) : null;
};

export default LanguageSwitcher;
