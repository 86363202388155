import React from 'react';
import EmptyState from '../../../Shared/EmptyState';
import PurchaseDoneIcon from '../../../../images/purchaseDoneIcon.svg';
import { usePostPageHeight } from './usePostPageHeight';

const PurchaseDone: React.FunctionComponent = () => {
    const { containerRef } = usePostPageHeight();

    return (
        <div ref={containerRef}>
            <EmptyState
                Icon={<img src={PurchaseDoneIcon} height="149" alt="purchase done" />}
                title="PurchaseDone.Title"
                message="PurchaseDone.Message"
                style={{ padding: '60px 0' }}
            />
        </div>
    );
};

export default PurchaseDone;
