import React, { useEffect, useState } from 'react';
import { routeUtils } from 'tds-common-fe';
import * as licenseService from '../../../../api/licenseService';
import useLicenseTiers from '../../../../hooks/useLicenseTiers';
import { loadingPlaceholder } from '../../index';
import styles from './UpgradeInfo.styl';
import PlanFeatureList from '../Plan/PlanFeatureList';
import FormattedMessage from '../../../../localization/FormatMessage';
import { usePostPageHeight } from '../PurchaseForm/usePostPageHeight';
import { ProductCode, ProductCodeName } from '../../../../types/license';

interface FeatureInfoParams {
    showTierName?: boolean;
    tierID?: string;
    product: string;
    isFreeTrial?: boolean;
}

const FeatureInfo: React.FunctionComponent = () => {
    const [isLoading, setLoading] = useState(true);
    const { tiers, tierIDs } = useLicenseTiers();
    const { showTierName, tierID, product, isFreeTrial } = routeUtils.parseQuery<FeatureInfoParams>(location.search);
    const { containerRef } = usePostPageHeight({ minHeight: 200, isReady: !isLoading });
    const productName = product ? ProductCodeName[product as ProductCode] : '';

    useEffect(() => {
        licenseService.getLicenseTiers({ product, free: isFreeTrial === 'true' }).finally(() => {
            setLoading(false);
        });
    }, [isFreeTrial, product]);

    // if not given, default to first tier
    const tierToUse = tierID ?? tierIDs[0];

    return isLoading ? (
        loadingPlaceholder
    ) : tiers[tierToUse] ? (
        <div ref={containerRef} className={styles.feature_info_container}>
            <h1 className={styles.feature_info_plan_title}>
                {showTierName === 'true' ? (
                    tiers[tierToUse].name
                ) : (
                    <FormattedMessage id="License.Feature.Info.Try.Free" values={{ productName }} />
                )}
            </h1>
            <PlanFeatureList
                columnCount={2}
                features={tiers[tierToUse].features}
                className={styles.plan_items_wrapper}
            />
        </div>
    ) : null;
};

export default FeatureInfo;
