import { limitImageSize } from '../../../utils/fileUtils';

export const squareImage = (src: string): Promise<{ dataURL: string; originalAspect?: number }> => {
    return new Promise((resolve) => {
        try {
            const image = new Image();
            image.src = src;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.style.position = 'absolute';
                canvas.style.maxWidth = '10px';
                canvas.style.maxHeight = '10px';
                canvas.style.top = '0';
                canvas.style.left = '0';
                canvas.style.visibility = 'hidden';

                document.body.append(canvas);
                const ctx = canvas.getContext('2d');

                const { height, width } = limitImageSize({
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                    limit: 2048,
                });

                const longerSide = Math.max(height, width);
                canvas.height = longerSide;
                canvas.width = longerSide;

                ctx?.drawImage(image, (longerSide - width) / 2, (longerSide - height) / 2, width, height);

                const dataURL = canvas.toDataURL('image/png', 1);
                canvas.remove();
                resolve({ dataURL, originalAspect: width / height });
            };
            image.onerror = (error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                resolve({ dataURL: '', originalAspect: undefined });
            };
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            resolve({ dataURL: '', originalAspect: undefined });
        }
    });
};
