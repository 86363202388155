import React, { useCallback, useEffect, useState } from 'react';
import { routeUtils } from 'tds-common-fe';
import { Button, Spin } from 'antd';
import styles from './AccountDeletion.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import { WORKSPACE_ROUTES } from '../../Routes/urls';
import { scheduleDeleteAccount } from '../../../api/userService';
import { APIError } from '../../../api/error';
import analytics from '../../../analytics/index';

interface ScheduleAccountDeletionParams {
    email: string;
    token: string;
}

enum ScheduleAccountDeletionType {
    success,
    linkExpired,
    requestFailed,
}

enum ScheduleAccountDeleteErrorCode {
    ItemNotFound = 'ItemNotFound',
    VerificationTokenInvalid = 'VerificationTokenInvalid',
    VerificationTokenExpired = 'VerificationTokenExpired',
}

const ScheduleAccountDeletion: React.FunctionComponent = () => {
    const params = routeUtils.parseQuery<ScheduleAccountDeletionParams>(location.search);
    const { email, token } = params;
    const [requestType, setRequestType] = useState<undefined | ScheduleAccountDeletionType>();

    const handleScheduleDeleteAccountError = useCallback((err: APIError) => {
        if (err.response?.data?.code === ScheduleAccountDeleteErrorCode.VerificationTokenExpired) {
            setRequestType(ScheduleAccountDeletionType.linkExpired);
        } else {
            setRequestType(ScheduleAccountDeletionType.requestFailed);
        }
    }, []);

    useEffect(() => {
        if (token && email) {
            scheduleDeleteAccount(token, email)
                .then(() => setRequestType(ScheduleAccountDeletionType.success))
                .catch((err: APIError) => handleScheduleDeleteAccountError(err));
        } else {
            setRequestType(ScheduleAccountDeletionType.requestFailed);
        }
    }, [email, handleScheduleDeleteAccountError, token]);

    const goToWorkspaceProfileLink = (
        <Button
            href={WORKSPACE_ROUTES.PERSONAL_PROFILE}
            onClick={() => analytics.logConfirmDeleteAccount('go_to_personal_profile')}
            type="link"
            size="large"
        >
            <FormattedMessage id="AccountDeletion.Schedule.GoToProfile" />
        </Button>
    );

    const SCHEDULE_ACCOUNT_TEXT: Record<
        ScheduleAccountDeletionType,
        { title: React.ReactNode; body: React.ReactNode; link: React.ReactNode }
    > = {
        [ScheduleAccountDeletionType.success]: {
            title: <FormattedMessage id="AccountDeletion.Schedule.Title" />,
            body: <FormattedMessage id="AccountDeletion.Schedule.Body" values={{ email: <b>{email}</b> }} />,
            link: (
                <Button
                    href={WORKSPACE_ROUTES.DASHBOARD}
                    onClick={() => analytics.logConfirmDeleteAccount('proceed_to_workspace')}
                    type="link"
                    size="large"
                >
                    <FormattedMessage id="AccountDeletion.Schedule.ProceedToWorkspace" />
                </Button>
            ),
        },
        [ScheduleAccountDeletionType.linkExpired]: {
            title: <FormattedMessage id="AccountDeletion.Schedule.LinkExpired.Title" />,
            body: <FormattedMessage id="AccountDeletion.Schedule.LinkExpired.Body" />,
            link: goToWorkspaceProfileLink,
        },
        [ScheduleAccountDeletionType.requestFailed]: {
            title: <FormattedMessage id="AccountDeletion.Schedule.RequestFailed.Title" />,
            body: <FormattedMessage id="AccountDeletion.Schedule.RequestFailed.Body" />,
            link: goToWorkspaceProfileLink,
        },
    };

    return requestType !== undefined ? (
        <div className={styles.container}>
            <div className={styles.title}>{SCHEDULE_ACCOUNT_TEXT[requestType].title}</div>
            <div className={styles.center_modal_body}>{SCHEDULE_ACCOUNT_TEXT[requestType].body}</div>
            <div className={styles.redirect_container}>{SCHEDULE_ACCOUNT_TEXT[requestType].link}</div>
        </div>
    ) : (
        <div className={styles.container}>
            <Spin />
        </div>
    );
};

export default ScheduleAccountDeletion;
