import { Modal } from 'antd';
import { ButtonType } from 'antd/lib/button';
import React from 'react';
import styles from './ConfirmModal.styl';
import AnalyticsButton from '../AnalyticsComponents/Button';

interface ConfirmModalProps {
    visible: boolean;
    onCancel: () => void;
    container: HTMLDivElement | null;
    title?: React.ReactNode;
    content?: React.ReactNode;
    buttons?: {
        title: React.ReactNode;
        onClick?: () => void;
        type?: ButtonType;
        disabled?: boolean;
    }[];
}
const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = (props) => {
    const { visible, onCancel, container, title, content, buttons } = props;

    return (
        <Modal
            open={visible}
            onCancel={onCancel}
            width={400}
            getContainer={() => container ?? document.body}
            closable={false}
            footer={null}
            className={styles.model_container}
            styles={{ body: { padding: 0 }, mask: { background: 'transparent' } }}
        >
            <div className={styles.title_area}>
                <h1 className={styles.title}>{title}</h1>
            </div>
            <div className={styles.content_area}>
                <p className={styles.content}>{content}</p>
            </div>
            <div className={styles.button_area}>
                {buttons?.map((button, index) => {
                    return (
                        <AnalyticsButton
                            key={index}
                            type={button.type}
                            onClick={button.onClick}
                            disabled={button.disabled}
                        >
                            {button.title}
                        </AnalyticsButton>
                    );
                })}
            </div>
        </Modal>
    );
};

export default ConfirmModal;
