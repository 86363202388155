import { ChangePasswordParams } from '../types/authentication';
import { UserProfileInfoBasic, PictureInfo, UserLicenseInfo } from '../types/profile';
import { fileToDataBlob } from '../utils/fileUtils';
import { post, thunkPost, thunkPut, thunkGet } from './apiService';
import * as profileActions from '../actions/profile';
import { handleError } from './error';
import urls from './urls';
import { APIResponse } from '../types';
import postMessenger from '../utils/PostMessenger';

export interface GetUserProfileResponse extends UserProfileInfoBasic, PictureInfo {}

export function getUserProfile(userID: string, errorHandler?: () => void) {
    type Response = APIResponse<GetUserProfileResponse>;
    const url = urls.USER_PROFILE.replace('{userID}', userID);
    return thunkGet<Response>(url, { params: {}, errorHandler: errorHandler ?? handleError }).then(
        ({ response, dispatch }) => {
            if (response.data) {
                dispatch(profileActions.receivedUserProfile(response.data));
            }
            return response;
        }
    );
}

export interface UpdateUserProfileParams {
    firstName: string;
    lastName: string;
    companyName: string;
}

export function updateUserProfile(userID: string, params: UpdateUserProfileParams) {
    type Response = APIResponse<UserProfileInfoBasic>;
    const url = urls.USER_PROFILE.replace('{userID}', userID.toString());
    return thunkPut<Response>(url, { params: { ...params }, errorHandler: handleError }).then(
        ({ response, dispatch }) => {
            if (response.data) {
                dispatch(profileActions.receivedUserProfile(response.data));
            }
        }
    );
}

export async function uploadUserProfilePicture(userID: string, file: File) {
    type Response = APIResponse<PictureInfo>;
    const dataBlob = await fileToDataBlob(file);
    const url = urls.USER_PROFILE_PICTURE.replace('{userID}', userID.toString());
    return thunkPost<Response>(url, { params: { dataBlob }, errorHandler: handleError }).then(
        ({ response, dispatch }) => {
            if (response.data) {
                const { pictureURL } = response.data;
                dispatch(profileActions.receivedUserProfile({ pictureURL }));
            }
        }
    );
}

export function removeUserProfilePicture(userID: string) {
    type Response = APIResponse<PictureInfo>;
    const url = urls.USER_PROFILE_PICTURE.replace('{userID}', userID.toString());
    return thunkPost<Response>(url, { params: { dataBlob: '' }, errorHandler: handleError }).then(({ dispatch }) => {
        dispatch(profileActions.receivedUserProfile({ pictureURL: undefined }));
    });
}

export function changeUserPassword(params: ChangePasswordParams) {
    const url = urls.PASSWORD_CHANGE;
    return post<APIResponse<{}>>(url, { params: { ...params }, errorHandler: handleError });
}

export async function getUserLicense() {
    type Response = APIResponse<UserLicenseInfo>;
    const url = urls.USER_LICENSE;
    const { response, dispatch } = await thunkGet<Response>(url);
    dispatch(profileActions.receivedUserLicense(response.data));
    // Inform the parent about the new license
    postMessenger.post({ type: 'userLicenseUpdated', data: response.data });
}

export enum UserLicenseErrorCode {
    LicenseKeyInvalid = 'LicenseKeyInvalid',
    LicenseKeyAlreadyInUse = 'LicenseKeyAlreadyInUse',
    LicenseKeyActivationFailed = 'LicenseKeyActivationFailed',
    ItemNotFound = 'ItemNotFound',
}

export async function activateUserLicense(licenseKey: string) {
    type Response = APIResponse<UserLicenseInfo>;
    const url = urls.ACTIVATE_LICENSE;
    const { response, dispatch } = await thunkPost<Response>(url, { params: { key: licenseKey } });
    dispatch(profileActions.receivedUserLicense(response.data));
    // Inform the parent about the new license
    postMessenger.post({ type: 'userLicenseUpdated', data: response.data });
}

export async function deleteAccount(confirmCode: string) {
    const url = urls.DELETE_ACCOUNT;
    return thunkPost(url, { params: { confirmCode } });
}

export function scheduleDeleteAccount(token: string, email: string) {
    const url = urls.DELETE_ACCOUNT_CONFIRMATION.replace('{token}', token);
    return thunkPost(url, { params: { email } });
}
