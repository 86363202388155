import { Rule } from 'antd/lib/form';
import React from 'react';
import { NonOptionalKeys } from 'tds-common-fe';
import { PASSWORD_MAX_LENGTH } from '../../../config/constants';
import FormattedMessage from '../../../localization/FormatMessage';
import * as inputUtils from '../../../utils/inputUtils';
import * as passwordUtils from '../../../utils/passwordUtils';

export interface FieldsType {
    username: string;
    password: string;
    passwordConfirm: string;
}
export type FieldsKeys = keyof FieldsType;

export const requiredFields: NonOptionalKeys<FieldsType>[] = ['username', 'password', 'passwordConfirm'];

export const rules: { [K in keyof FieldsType]?: Rule[] } = {
    username: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.Email.Error.Empty" />,
        },
        { type: 'email', message: <FormattedMessage id="SignUp.Field.Email.Error.Invalid" /> },
    ],

    password: [
        {
            required: true,
            message: '',
        },
        passwordUtils.getStrongPasswordValidator<FieldsKeys>(['username'], ''),
        inputUtils.getMaxLengthValidator(PASSWORD_MAX_LENGTH, ''),
    ],
    passwordConfirm: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.ConfirmPassword.Error.Empty" />,
        },
        passwordUtils.getConfirmPasswordValidator<FieldsKeys>(
            'password',
            <FormattedMessage id="ResetPassword.Field.ConfirmPassword.Error.NotSame" />
        ),
    ],
};
