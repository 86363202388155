import React, { useEffect, useRef, useContext, useLayoutEffect } from 'react';
import { Alert, Form, message } from 'antd';
import { NonOptionalKeys, FormItem, routeUtils } from 'tds-common-fe';
import * as appUtils from '../../../utils/appUtils';
import FormattedMessage from '../../../localization/FormatMessage';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import AuthInput from '../Custom/AuthInput';
import styles from './Login.styl';
import formStyles from '../Form.styl';
import LoginFooter from './LoginFooter';
import AuthButton from '../Custom/AuthButton';
import URL from '../../../api/urls';
import useLoadingState from '../../../hooks/useLoadingState';
import { getLoginErrorMessage } from '../../../utils/errorUtils';
import * as storageUtils from '../../../utils/storageUtils';
import { AuthenticationParams, checkAuthParamsReady, redirectURLWithLanguage } from '../../../utils/authUtils';
import Link from '../../Routes/Link';
import * as urls from '../../Routes/urls';
import { getScreeningEagleHeaders } from '../../../api/headerUtils';
import analytics from '../../../analytics/index';
import { LocalizationContext } from '../../../localization/LocalizedIntlProvider';
import LoadingOverlay from '../../Shared/LoadingOverlay';
import AuthBasics from '../Custom/AuthBasics';
import config from '../../../config';
import SocialButtons from '../SignUp/SocialButtons';
import { AuthType } from '../../../types/authentication';
import useDisableLoading from '../useDisableLoading';

interface FieldsType {
    username: string;
    password: string;
    stayLogin?: boolean;
}

const requiredFields: NonOptionalKeys<FieldsType>[] = ['username', 'password'];

class Item extends FormItem<FieldsType> {}

interface LoginParams extends AuthenticationParams {
    errorCode?: string;
    username?: string;
    password?: string;
    relogin?: string;
}

const Login: React.FunctionComponent = () => {
    const htmlFormRef = useRef<HTMLFormElement>(null);
    const [form] = Form.useForm();
    const { formatMessage } = useFormatMessage();
    const params = routeUtils.parseQuery<LoginParams>(location.search);
    const { language } = useContext(LocalizationContext);

    // Redirect user to workspace by default if missing necessary params for login
    useLayoutEffect(() => {
        if (config.NODE_ENV === 'production' && !checkAuthParamsReady(params) && !appUtils.fromApp()) {
            window.open(config.WORKSPACE_DOMAIN, '_self');
        }
    }, [params]);

    /* eslint-disable @typescript-eslint/naming-convention */
    const {
        redirect_uri,
        client_id,
        response_type,
        state,
        scope,
        errorCode,
        username,
        password,
        relogin,
        includeLang,
        code_challenge,
        code_challenge_method,
    } = params;

    const isRelogin = relogin === 'true' || storageUtils.getSessionItem('relogin') === 'true';

    useEffect(() => {
        if (isRelogin) {
            storageUtils.saveSessionItem('relogin', 'true');
        }
    }, [isRelogin]);

    const initialUsername = username ?? storageUtils.getSessionItem('username') ?? undefined;

    const actionUrl = routeUtils.makeQueryPath(URL.LOGIN, {
        redirect_uri: includeLang === 'true' ? redirectURLWithLanguage(redirect_uri, language) : redirect_uri,
        client_id,
        response_type,
        state,
        scope,
        code_challenge,
        code_challenge_method,
        ...getScreeningEagleHeaders(),
    });
    /* eslint-enable @typescript-eslint/naming-convention */

    const [loading, setLoading] = useLoadingState(false);
    const loadingMessage = formatMessage({ id: 'Login.Message.Login' });

    const inputs = useRef<HTMLInputElement[]>([]);

    const errorFormatID = errorCode ? getLoginErrorMessage(errorCode) : undefined;

    const handleSubmit = (values: FieldsType) => {
        const isAuthParamsReady = checkAuthParamsReady(params);
        if (!isAuthParamsReady) {
            message.error(formatMessage({ id: 'Login.ParamsIncomplete' }));
            return;
        }
        const { username, password } = values;
        const form = htmlFormRef.current;

        if (form !== null) {
            setLoading(true, loadingMessage);
            const usernameInput: HTMLInputElement | null = form.querySelector('[name="username"]');
            const passwordInput: HTMLInputElement | null = form.querySelector('[name="password"]');
            if (usernameInput) {
                usernameInput.value = username;
            }
            if (passwordInput) {
                passwordInput.value = password;
            }
            storageUtils.saveSessionItem('username', username);
            analytics.logLogin('email');
            form.submit();
        }
    };

    useDisableLoading(setLoading);

    const passwordExtra = (
        <Link to={urls.AUTH_ROUTES.FORGET_PASSWORD} style={{ margin: '6px 0px' }} size="small">
            <FormattedMessage id="NavTitle.ResetPassword" />
        </Link>
    );

    // Save the param username into session & check the validity
    useEffect(() => {
        if (!initialUsername) return;
        form.validateFields(['username']).then(() => {
            storageUtils.saveSessionItem('username', initialUsername);
        });
    }, [initialUsername, form]);

    const getEmailVerificationLink = () => {
        const email = storageUtils.getSessionItem('username');
        return email
            ? routeUtils.makeQueryPath(urls.AUTH_ROUTES.EMAIL_VERIFICATION, { email, directResend: 'true' })
            : urls.AUTH_ROUTES.EMAIL_VERIFICATION;
    };

    return (
        <div className="signup">
            <AuthBasics centerTitle title={<FormattedMessage id="Login.Header.PageTitle" />} />
            <Form
                className={formStyles.form}
                form={form}
                layout="vertical"
                onFinish={handleSubmit as any}
                requiredMark={false}
            >
                {errorFormatID && (
                    <Alert
                        className={styles.danger_alert}
                        message={
                            <FormattedMessage
                                id={errorFormatID}
                                values={{
                                    resendLink: (
                                        <Link to={getEmailVerificationLink()} retainAuthSearch={false}>
                                            <FormattedMessage id="Common.Here" />
                                        </Link>
                                    ),
                                    resetLink: (
                                        <Link to={urls.AUTH_ROUTES.FORGET_PASSWORD}>
                                            <FormattedMessage id="Error.AccountMaxLoginAttempts.ResetLink" />
                                        </Link>
                                    ),
                                }}
                            />
                        }
                        type="error"
                        closable
                        showIcon
                    />
                )}
                <Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="Login.Field.Username.Error.Empty" />,
                        },
                        { type: 'email', message: <FormattedMessage id="Login.Field.Username.Error.Invalid" /> },
                    ]}
                    initialValue={initialUsername}
                >
                    <AuthInput
                        type="email"
                        placeholder={formatMessage({ id: 'ProductName.EagleID' })}
                        inputs={inputs}
                        disabled={isRelogin}
                    />
                </Item>
                <Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="Login.Field.Password.Error.Empty" />,
                        },
                    ]}
                    style={{
                        marginBottom: 12,
                    }}
                    initialValue={password}
                    extra={isRelogin ? undefined : passwordExtra}
                >
                    <AuthInput
                        authInputType="password"
                        placeholder={formatMessage({ id: 'Login.Field.Password.Placeholder' })}
                        inputs={inputs}
                    />
                </Item>
                <Item shouldUpdate>
                    {() => (
                        <AuthButton
                            title="Login.Button.Login"
                            form={form}
                            className={styles.button_container}
                            requiredFields={requiredFields}
                        />
                    )}
                </Item>
            </Form>
            <SocialButtons authType={AuthType.Login} setLoading={setLoading} />
            {!isRelogin && <LoginFooter />}
            <LoadingOverlay overlayBlocked={loading} />
            <form
                ref={htmlFormRef}
                action={actionUrl}
                method="POST"
                style={{
                    position: 'absolute',
                    zIndex: -100,
                    top: 0,
                    left: 0,
                    opacity: 0.5,
                    pointerEvents: 'none',
                }}
            >
                <input name="username" type="email" autoComplete="off" tabIndex={-1} />
                <input name="password" type="password" autoComplete="off" tabIndex={-1} />
            </form>
        </div>
    );
};

export default Login;
