import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeUtils } from 'tds-common-fe';
import * as authService from '../../../api/authService';
import { APIError, handleError } from '../../../api/error';
import FormattedMessage from '../../../localization/FormatMessage';
import { FormatIDs } from '../../../types';
import { ProductType } from '../../../types/authentication';
import { getSignInLink, isProductExisting, productNameMap } from '../../../utils/productUtils';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import LoadingOverlay from '../../Shared/LoadingOverlay';
import AuthBasics from '../Custom/AuthBasics';
import formStyles from '../Form.styl';
import styles from './EmailVerification.styl';

interface EmailVerificationParams {
    token: string;
    email: string;
    directResend?: boolean;
    product?: string;
    client?: string;
}

enum EmailVerificationErrorCode {
    VerificationTokenExpired = 'VerificationTokenExpired',
    AccountAlreadyVerified = 'AccountAlreadyVerified',
    AccountNotFound = 'AccountNotFound',
    VerificationTokenInvalid = 'VerificationTokenInvalid',
}

enum EmailVerificationState {
    alreadyVerified,
    resendSuccess,
    verifiedSuccess,
    resendEmail,
}

const stateData: {
    [key in EmailVerificationState]: {
        title: FormatIDs;
        description: FormatIDs;
    };
} = {
    [EmailVerificationState.alreadyVerified]: {
        title: 'EmailVerification.AlreadyVerifiedTitle',
        description: 'EmailVerification.FailedDescription',
    },
    [EmailVerificationState.resendSuccess]: {
        title: 'EmailVerification.ResendSuccessfulTitle',
        description: 'EmailVerification.ResendSuccessfulDescription',
    },
    [EmailVerificationState.verifiedSuccess]: {
        title: 'EmailVerification.Success.Title',
        description: 'EmailVerification.Success.Description',
    },
    [EmailVerificationState.resendEmail]: {
        title: 'EmailVerification.FailedTitle',
        description: 'EmailVerification.LinkExpired',
    },
};

const EmailVerification: React.FunctionComponent = () => {
    const params = routeUtils.parseQuery<EmailVerificationParams>(location.search);
    const { token, email, directResend, product = ProductType.Workspace } = params;
    const history = useHistory();
    const [verificationState, setVerificationState] = useState<EmailVerificationState | undefined>(undefined);
    const [isResending, setIsResending] = useState(false);
    const [profileState, setProfileState] = useState(true);

    const handleEmailVerificationErrors = useCallback((err: APIError) => {
        switch (err.response?.data.code) {
            case EmailVerificationErrorCode.VerificationTokenExpired:
                setVerificationState(EmailVerificationState.resendEmail);
                break;
            case EmailVerificationErrorCode.AccountAlreadyVerified:
                setVerificationState(EmailVerificationState.alreadyVerified);
                break;
            case EmailVerificationErrorCode.AccountNotFound:
            case EmailVerificationErrorCode.VerificationTokenInvalid:
                handleError(err);
                setVerificationState(EmailVerificationState.resendEmail);
                break;
        }
    }, []);

    const handleResendVerification = useCallback(async () => {
        if (!email) return;
        setIsResending(true);
        try {
            await authService.resendEmailVerification({ email });
            setVerificationState(EmailVerificationState.resendSuccess);
        } catch (err) {
            handleEmailVerificationErrors(err as APIError);
        } finally {
            setIsResending(false);
        }
    }, [email, handleEmailVerificationErrors]);

    useEffect(() => {
        if (token) {
            authService
                .emailVerification(token)
                .then((res: { data?: { profileComplete: boolean } }) => {
                    if (res.data?.profileComplete !== undefined) {
                        setProfileState(res.data?.profileComplete);
                    }
                    setVerificationState(EmailVerificationState.verifiedSuccess);
                })
                .catch((err: APIError) => {
                    handleEmailVerificationErrors(err);
                });
        } else if (directResend) {
            handleResendVerification();
        } else {
            setVerificationState(EmailVerificationState.resendEmail);
        }
    }, [token, history, handleEmailVerificationErrors, directResend, handleResendVerification]);

    return (
        <>
            <>
                {verificationState !== undefined && (
                    <>
                        <div className={styles.text_container}>
                            {verificationState === EmailVerificationState.resendSuccess ? (
                                <AuthBasics
                                    title={<FormattedMessage id={stateData[verificationState].title} />}
                                    description={
                                        <FormattedMessage
                                            id={stateData[verificationState].description}
                                            values={{
                                                email: <b>{email}</b>,
                                            }}
                                        />
                                    }
                                    centerDescription
                                />
                            ) : (
                                <AuthBasics
                                    title={<FormattedMessage id={stateData[verificationState].title} />}
                                    description={<FormattedMessage id={stateData[verificationState].description} />}
                                    {...(verificationState !== EmailVerificationState.resendEmail && {
                                        centerDescription: true,
                                    })}
                                />
                            )}
                        </div>

                        {verificationState === EmailVerificationState.resendEmail && (
                            <AnalyticsButton
                                loading={isResending}
                                className={formStyles.form_submit_button}
                                onClick={handleResendVerification}
                                type="primary"
                                htmlType="submit"
                                size="large"
                            >
                                <FormattedMessage id="EmailVerification.RequestNewEmail" />
                            </AnalyticsButton>
                        )}

                        {(verificationState === EmailVerificationState.verifiedSuccess ||
                            verificationState === EmailVerificationState.alreadyVerified) && (
                            <AnalyticsButton
                                style={{ marginTop: 10 }}
                                type="primary"
                                href={getSignInLink(product, email)}
                                size="large"
                            >
                                <FormattedMessage
                                    id={!profileState ? 'SignUp.Button.SetupProfile' : 'EmailVerification.SignInTo'}
                                    values={{
                                        product: (
                                            <FormattedMessage
                                                id={
                                                    isProductExisting(product)
                                                        ? productNameMap[product]
                                                        : 'ProductName.Workspace'
                                                }
                                            />
                                        ),
                                    }}
                                />
                            </AnalyticsButton>
                        )}
                    </>
                )}
                <LoadingOverlay overlayBlocked={verificationState === undefined} />
            </>
        </>
    );
};

export default EmailVerification;
