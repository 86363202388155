import React from 'react';
import { routeUtils } from 'tds-common-fe';
import { classNames } from '../../../../utils/classUtils';
import wrapperStyles from '../../../Authentication/AuthWrapper.styl';
import twoFAStyles from '../TwoFAStyles.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { ReactComponent as VerifiedIcon } from '../../../../images/verified.svg';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA, SetUpCategories } from '../../../../analytics/analyticsConstants';
import urls from '../../../../api/urls';

const Complete: React.FunctionComponent = () => {
    const params = routeUtils.parseQuery<{ redirect_uri: string; state: string }>(location.search);

    // if state is given, proceed with oauth flow. else, navigate directly to redirect_uri
    const { redirect_uri: redirectURL, state } = params;
    const oauthAuthorizationURL = routeUtils.makeQueryPath(urls.OAUTH_AUTHORIZATION, { ...params });

    return (
        <div
            className={classNames(
                wrapperStyles.content_wrapper,
                wrapperStyles.input_block,
                twoFAStyles.generic_container
            )}
        >
            <div>
                <VerifiedIcon />
            </div>
            <div className={twoFAStyles.header}>
                <FormattedMessage id="2FA.SetUp.Complete.Title" />
            </div>
            <div className={twoFAStyles.body_text}>
                <FormattedMessage id="2FA.SetUp.Complete.Body" />
            </div>
            <AnalyticsButton
                className={twoFAStyles.primary_button_container}
                type="primary"
                href={state ? oauthAuthorizationURL : redirectURL}
                onClick={() => analytics.logSetUpTwoFA(SetUpCategories.complete, ButtonsTwoFA.done)}
                size="large"
            >
                <span className={twoFAStyles.primary_button_text}>
                    <FormattedMessage id="Common.Done" />
                </span>
            </AnalyticsButton>
        </div>
    );
};

export default Complete;
