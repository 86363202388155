import { PictureInfo, CompanyInfo, CompanyInfoBasic } from '../types/profile';
import { fileToDataBlob } from '../utils/fileUtils';
import { thunkGet, thunkPost, thunkPut } from './apiService';
import * as profileActions from '../actions/profile';
import { handleError } from './error';
import urls from './urls';
import { APIResponse } from '../types';

export async function uploadCompanyProfilePicture(companyID: string, file: File) {
    type Response = APIResponse<PictureInfo>;
    const dataBlob = await fileToDataBlob(file);
    const url = urls.COMPANY_PICTURE.replace('{companyID}', companyID);
    await thunkPost<Response>(url, { params: { dataBlob }, errorHandler: handleError }).then(
        ({ response, dispatch }) => {
            if (response.data) {
                const { pictureURL } = response.data;
                dispatch(profileActions.receivedCompanyProfile({ pictureURL }));
            }
        }
    );
}

export function removeCompanyProfilePicture(companyID: string) {
    type Response = APIResponse<PictureInfo>;
    const url = urls.COMPANY_PICTURE.replace('{companyID}', companyID);
    return thunkPost<Response>(url, { params: { dataBlob: '' }, errorHandler: handleError }).then(({ dispatch }) => {
        dispatch(profileActions.receivedCompanyProfile({ pictureURL: undefined }));
    });
}

export async function getUserCompany(userID: string) {
    type Response = APIResponse<CompanyInfo[]>;
    const url = urls.COMPANY_LIST;
    return thunkGet<Response>(url, { params: {}, errorHandler: handleError }).then(({ response, dispatch }) => {
        if (response.data) {
            const companies = response.data;
            if (!Array.isArray(companies)) {
                return;
            }
            const company = companies.find((company) => Number(company.owner) === Number(userID));
            if (company) {
                dispatch(profileActions.receivedCompanyProfile(company));
            }
        }
    });
}

export async function getCompany(companyID: string) {
    type Response = APIResponse<CompanyInfo>;
    const url = urls.COMPANY_PROFILE.replace('{companyID}', companyID);
    return thunkGet<Response>(url, { params: {}, errorHandler: handleError }).then(({ response, dispatch }) => {
        const company = response.data;
        if (company) {
            dispatch(profileActions.receivedCompanyProfile(company));
        }
        return company;
    });
}

export interface UpdateCompanyParams {
    name: string;
    address: string;
    contactNumber: string;
    email: string;
    website: string;
}

export function updateCompany(companyID: string, params: UpdateCompanyParams) {
    type Response = APIResponse<CompanyInfoBasic>;
    const url = urls.COMPANY_PROFILE.replace('{companyID}', companyID);
    const jsonBody = { ...params };
    return thunkPut<Response>(url, { params: jsonBody, errorHandler: handleError }).then(({ response, dispatch }) => {
        if (response.data) {
            dispatch(profileActions.receivedCompanyProfile(response.data));
        }
    });
}
