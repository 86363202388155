import React from 'react';
import { saveAs } from 'file-saver';
import { Spin } from 'antd';
import { clipboardUtils } from 'tds-common-fe';
import * as appUtils from '../../../../utils/appUtils';
import { classNames } from '../../../../utils/classUtils';
import wrapperStyles from '../../../Authentication/AuthWrapper.styl';
import styles from './RecoveryKey.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA } from '../../../../analytics/analyticsConstants';
import twoFAStyles from '../TwoFAStyles.styl';

interface RecoveryKeyProps {
    onDownload: () => void;
    recoveryKeys: string[];
    doneRedirectURL: string;
}

const RecoveryKey: React.FunctionComponent<RecoveryKeyProps> = (props) => {
    const { onDownload, recoveryKeys, doneRedirectURL } = props;
    const isApp = appUtils.fromApp();

    const onClick = () => {
        if (isApp) {
            clipboardUtils.copyTextToClipboard(recoveryKeys.toString(), '');
        } else {
            const blob = new Blob([recoveryKeys.join('\n')], { type: 'text/plain;charset=utf-8' });
            saveAs(blob, 'screening-eagle-id-recovery-keys.txt');
            onDownload();
        }
    };

    return (
        <div
            className={classNames(
                wrapperStyles.content_wrapper,
                wrapperStyles.input_block,
                twoFAStyles.generic_container
            )}
        >
            <div className={styles.header}>
                <FormattedMessage id="2FA.SetUp.RecoveryKey.Title" />
            </div>
            <div className={styles.body_text}>
                <FormattedMessage id="2FA.SetUp.RecoveryKey.Body" />
            </div>
            <div className={styles.recovery_keys}>
                {recoveryKeys.length ? (
                    <ul>
                        {recoveryKeys.map((code) => (
                            <li key={code}>{code}</li>
                        ))}
                    </ul>
                ) : (
                    <Spin className={styles.spinner} />
                )}
            </div>
            {!!recoveryKeys.length && (
                <div className={styles.download_button_container}>
                    <AnalyticsButton type="primary" size="large" ghost onClick={onClick}>
                        <span className={styles.primary_button_text}>
                            <FormattedMessage id={isApp ? 'Common.Copy' : 'Common.Download'} />
                        </span>
                    </AnalyticsButton>
                    <AnalyticsButton
                        type="primary"
                        size="large"
                        href={doneRedirectURL}
                        onClick={() => analytics.logRecoveryKeyTwoFA(ButtonsTwoFA.done)}
                    >
                        <FormattedMessage id="Common.Done" />
                    </AnalyticsButton>
                </div>
            )}
        </div>
    );
};

export default RecoveryKey;
