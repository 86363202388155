import React from 'react';
import * as appUtils from '../../utils/appUtils';
import styles from './AuthWrapper.styl';
import config from '../../config';
import LanguageSwitcher from '../Shared/LanguageSwitcher';
import { classNames } from '../../utils/classUtils';
import { ReactComponent as EagleIcon } from '../../images/eagleIcon.svg';

interface AuthWrapperProps {
    hasContentOutsideModal?: boolean;
    showLanguageSwitcher?: boolean;
}

const AuthWrapper: React.FunctionComponent<React.PropsWithChildren<AuthWrapperProps>> = (props) => {
    const { children, hasContentOutsideModal = false, showLanguageSwitcher = true } = props;
    const hideScrollbar = appUtils.fromApp();

    const header = showLanguageSwitcher ? (
        <div className={`${styles.content_container}`}>
            <div className={styles.header_content}>
                <LanguageSwitcher />
            </div>
        </div>
    ) : null;

    const content = (
        <div className={styles.content_container}>
            {hasContentOutsideModal ? (
                <>{children}</>
            ) : (
                <div className={classNames(styles.content_wrapper, styles.input_block)}>
                    <EagleIcon style={{ fontSize: 54 }} />
                    {children}
                </div>
            )}
        </div>
    );

    return (
        <div
            data-mode="dark"
            data-component="auth"
            className={hideScrollbar ? styles.hidden_scrollbar_container : styles.container}
        >
            <div className={styles.header}>{header}</div>
            {content}
            <div className={styles.footer}>
                {config.APP_ENV !== 'production' && <div className={styles.version}>v{config.APP_VERSION}</div>}
            </div>
        </div>
    );
};

export default AuthWrapper;
