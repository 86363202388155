import React, { useEffect } from 'react';
import { routeUtils } from 'tds-common-fe';
import { useHistory } from 'react-router-dom';
import * as appUtils from '../../../utils/appUtils';
import FullScreenSpinner from '../../Shared/FullScreenSpinner';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import postMessenger from '../../../utils/PostMessenger';
import { VerifyPasswordParams } from '../VerifyPassword';
import { confirmSignIn } from '../../../api/authService';
import { AUTH_ROUTES } from '../../Routes/urls';
import { APIError } from '../../../api/error';
import { getSessionItem } from '../../../utils/storageUtils';

interface MultipleDevicesRedirectionParams extends Partial<VerifyPasswordParams> {
    parentOrigin?: string;
    code: string;
}

const MultipleDevicesRedirection: React.FunctionComponent = () => {
    const { formatMessage } = useFormatMessage();
    const history = useHistory();
    const isApp = appUtils.fromApp();

    const params = routeUtils.parseQuery<MultipleDevicesRedirectionParams>(location.search);
    const { parentOrigin, code = '', ...verifyParams } = params;

    useEffect(() => {
        if (parentOrigin) {
            postMessenger.setParentOrigin(decodeURIComponent(parentOrigin));
        }
    }, [parentOrigin]);

    useEffect(() => {
        const accessToken = isApp ? getSessionItem('accessToken') : undefined;
        confirmSignIn(code, accessToken!)
            .then(() => postMessenger.post({ type: 'closeIframe', isPopup: window.opener }))
            .catch((error: APIError) => {
                const errorRedirectPath = routeUtils.makeQueryPath(AUTH_ROUTES.VERIFY_PASSWORD, {
                    ...verifyParams,
                    parentOrigin,
                    close_tab: 'false',
                    action: 'confirm_signin',
                    error: error.response?.data?.message,
                    redirect_uri: location.href,
                });
                history.push(errorRedirectPath);
            });
    }, [code, history, isApp, parentOrigin, verifyParams]);

    return (
        <FullScreenSpinner
            spinnerSize="large"
            extraStyle={{ background: 'black' }}
            tip={formatMessage({ id: 'Login.Message.Login' })}
        />
    );
};

export default MultipleDevicesRedirection;
