import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { routeUtils } from 'tds-common-fe';
import analytics from '../../analytics/index';
import { FormatIDs, FormatMessageFunc } from '../../types';
import { AuthenticationParams } from '../../utils/authUtils';
import { isProductExisting, productNameMap } from '../../utils/productUtils';

interface AnalyticsRouteProps extends RouteProps {
    changeDocumentTitle?: boolean;
    titleFormatIDs: FormatIDs[];
    computedMatch?: {
        params: { [K: string]: string };
    };
    disabled?: boolean;
}

const AnalyticsRoute: React.FunctionComponent<AnalyticsRouteProps> = ({
    titleFormatIDs = [],
    changeDocumentTitle,
    disabled = false,
    ...props
}) => {
    const { exact } = props;
    const formatMessage: FormatMessageFunc = useIntl().formatMessage;

    const pagePath = location.pathname + location.search;
    const pageName = titleFormatIDs.reduce(
        (acc, formatID, index) => `${acc}${index > 0 ? ' | ' : ''}${formatMessage({ id: formatID })}`,
        ''
    );

    const { product } = routeUtils.parseQuery<AuthenticationParams>(location.search);

    const defaultTitle = formatMessage({
        id: isProductExisting(product) ? productNameMap[product] : 'ProductName.EagleID',
    });

    useEffect(() => {
        if (changeDocumentTitle) {
            document.title = `${pageName} - ${defaultTitle}`;
            return () => {
                document.title = defaultTitle;
            };
        }
        return () => {};
    }, [changeDocumentTitle, pageName, defaultTitle]);

    useEffect(() => {
        if (exact) {
            analytics.setScreenName(pageName);
            // console.log('page name', pageName, pagePath);
            analytics.logPageView({ title: pageName, path: pagePath });
        }
    }, [exact, pageName, pagePath]);

    return disabled ? null : <Route {...props} />;
};

export default AnalyticsRoute;
