import { Reducer } from 'redux';
import actionTypes, { Action, PayloadAction } from '../actions/actionTypes';
import { CompanyInfo, UserProfileInfo, UserLicenseInfo } from '../types/profile';

export interface UserProfileState extends Partial<UserProfileInfo> {}

export interface UserLicenseState extends Partial<UserLicenseInfo> {}

export interface CompanyProfileState extends Partial<CompanyInfo> {}

export interface ProfileState {
    userProfile: UserProfileState;
    userLicense: UserLicenseState;
    companyProfile: CompanyProfileState;
}

export const initialState: ProfileState = {
    userProfile: {},
    userLicense: {},
    companyProfile: {},
};

const Profile: Reducer<ProfileState, Action> = (state = initialState, action): ProfileState => {
    switch (action.type) {
        case actionTypes.RECEIVED_USER_PROFILE: {
            const { payload } = action as PayloadAction<UserProfileState>;
            const userProfile = { ...state.userProfile, ...payload };
            return {
                ...state,
                userProfile,
            };
        }

        case actionTypes.RECEIVED_USER_LICENSE: {
            const { payload } = action as PayloadAction<UserLicenseInfo>;
            return {
                ...state,
                userLicense: payload,
            };
        }

        case actionTypes.RECEIVED_COMPANY_PROFILE: {
            const { payload } = action as PayloadAction<CompanyProfileState>;

            const companyPictureURL = payload.pictureURL;
            const companyProfile = {
                ...state.companyProfile,
                ...payload,
                // same url is used even if picture is updated. add query param so that new image is loaded
                pictureURL: !!companyPictureURL ? `${companyPictureURL}?timestamp=${Date.now()}` : undefined,
            };

            return {
                ...state,
                companyProfile,
            };
        }
    }
    return state;
};

export default Profile;
