import React, { useContext, useEffect } from 'react';
import { Route, RouteProps, useLocation, useHistory } from 'react-router-dom';
import { routeUtils } from 'tds-common-fe';
import { languageKeys, LanguageOptions } from '../../localization';
import { LocalizationContext } from '../../localization/LocalizedIntlProvider';
import { isConsumerValid } from '../../config/consumers';
import { ThemeContext, ThemeOptions } from './ThemeProvider';

interface LanguageThemeParams {
    lang?: LanguageOptions;
    theme?: ThemeOptions;
}

const LanguageThemeRoute: React.FunctionComponent<RouteProps> = (props) => {
    const location = useLocation();
    const history = useHistory();

    const { search, pathname } = location;
    const { lang, theme } = routeUtils.parseQuery<LanguageThemeParams>(search);
    const { setLanguage } = useContext(LocalizationContext);
    const { setTheme } = useContext(ThemeContext);

    useEffect(() => {
        if (theme) {
            setTheme(theme as ThemeOptions);
        }
    }, [theme, setTheme]);

    // Change language based on URL params
    useEffect(() => {
        const myLang = lang as LanguageOptions | undefined;
        if (myLang && languageKeys.includes(myLang)) {
            setLanguage(myLang);
        }
        if (myLang !== undefined) {
            const newPath = routeUtils.removeQueryKeys(pathname, search, ['lang']);
            history.replace(newPath);
        }
    }, [lang, setLanguage, history, search, pathname]);

    // Change language when consumer changed language
    useEffect(() => {
        const listener = (event: MessageEvent) => {
            const { origin, data } = event;
            if (isConsumerValid(origin) && data.type === 'languageUpdated' && languageKeys.includes(data.data)) {
                setLanguage(data.data as LanguageOptions);
            }
        };

        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
        };
    }, [setLanguage]);

    return lang === undefined ? <Route {...props} /> : null;
};

export default LanguageThemeRoute;
