import React, { useState } from 'react';

export type ThemeOptions = 'bright' | 'dark';

interface ThemeContextState {
    theme?: string;
    setTheme: (mode: ThemeOptions) => void;
}

export const ThemeContext = React.createContext<ThemeContextState>({
    theme: undefined,
    setTheme: () => {},
});

const ThemeProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = (props) => {
    const [theme, setTheme] = useState<ThemeOptions | undefined>(undefined);

    return <ThemeContext.Provider value={{ theme, setTheme }}>{props.children}</ThemeContext.Provider>;
};
export default ThemeProvider;
