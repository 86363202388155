import React from 'react';
import FormattedMessage from '../../../localization/FormatMessage';
import AuthBasics from '../Custom/AuthBasics';

const ForgetPasswordDone: React.FunctionComponent = () => {
    const title = <FormattedMessage id="ForgetPasswordDone.Header.Title" />;
    const signInLinkText = <FormattedMessage id="ForgetPasswordDone.Button.Back" />;
    return <AuthBasics title={title} signInLinkText={signInLinkText} />;
};

export default ForgetPasswordDone;
