import React from 'react';
import { Rule } from 'antd/lib/form';
import FormattedMessage from '../../../../localization/FormatMessage';
import { getTncValidator } from '../../../../utils/inputUtils';

export interface FieldsType {
    currencyCode: string;
    agreeTnC: boolean;
    userVolume: number;
    additionalNotes: string;
}

export type FieldsKeys = keyof FieldsType;

export const rules: { [K in FieldsKeys]?: Rule[] } = {
    currencyCode: [{ required: true }],
    userVolume: [
        {
            required: true,
            message: <FormattedMessage id="PurchaseForm.Field.UserVolume.Error.Empty" />,
        },
    ],
    agreeTnC: [getTncValidator(<FormattedMessage id="SignUp.Field.TNC.Error.Empty" />)],
};
