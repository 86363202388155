export enum EventName {
    isExternalUser = 'is_external_user',
    userID = 'user_id',
    PAGE_VIEW = 'page_view',
    SIGN_UP = 'sign_up',
    LOGIN = 'login',
    BUTTON_CLICK = 'button_click',
    SWITCH_ACCOUNT = 'switch_account',
    REQUEST_DELETE_ACCOUNT = 'request_delete_account',
    VERIFY_DELETE_ACCOUNT = 'verify_delete_account',
    CONFIRM_DELETE_ACCOUNT = 'confirm_delete_account',
    VERIFY_PASSWORD = 'verify_password',

    // 2fa
    ENABLE_2FA = 'enable_2fa',
    SETUP_2FA = 'setup_2fa',
    LOGIN_2FA = 'login_2fa',
    LOGIN_RECOVERY_KEY_2FA = 'login_recovery_key_2fa',
    RESET_2FA = 'reset_2fa',
    RECOVERY_KEY_2FA = 'recovery_key_2fa',

    // 2fa profile
    PROFILE_2FA_SETUP = 'profile_2fa_setup',
    PROFILE_2FA_RESET = 'profile_2fa_reset',
    PROFILE_2FA_RECOVERY_KEY = 'profile_2fa_recovery_key',

    // contact form
    UPGRADE = 'upgrade',
    UPGRADE_SUBMIT = 'upgrade_submit',
    CONTACT_SALES = 'contact_sales',

    // multiple devices
    MULTIPLE_DEVICES_WARNING = 'multiple_devices_warning',
    MULTIPLE_DEVICES_LOGIN = 'multiple_devices_login',
}

export enum SetUpCategories {
    setUpAuthenticator = 'set_up_authenticator',
    verificationCode = 'verification_code',
    recoveryKey = 'recovery_key',
    complete = 'complete',
}

export enum ButtonsTwoFA {
    continue = 'continue',
    download = 'download',
    done = 'done',
    cancel = 'cancel',
    closeError = 'close_error',
    verify = 'verify',
    trustDevice = 'trust_device',
    authCodeUnavailable = 'auth_code_unavailable',
    contactSupport = 'contact_support',
    reset = 'reset',
    goBackToSignIn = 'go_back_to_sign_in',
}
