import React from 'react';
import { routeUtils } from 'tds-common-fe';
import FormattedMessage from '../../../localization/FormatMessage';
import { checkAuthParamsReady } from '../../../utils/authUtils';
import AuthBasics from '../Custom/AuthBasics';

const ForgetPasswordDone: React.FunctionComponent = () => {
    const title = <FormattedMessage id="ResetPasswordDone.Header.Title" />;
    const signInLinkText = <FormattedMessage id="ResetPasswordDone.Button.Back" />;

    const params = routeUtils.parseQuery(location.search);
    const isAuthParamsReady = checkAuthParamsReady(params);

    return <AuthBasics title={title} signInLinkText={isAuthParamsReady && signInLinkText} />;
};

export default ForgetPasswordDone;
