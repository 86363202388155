import React, { useContext, useEffect, useRef, useState } from 'react';
import { routeUtils } from 'tds-common-fe';
import { useParams } from 'react-router';
import { message, Spin } from 'antd';
import * as appUtils from '../../utils/appUtils';
import postMessenger from '../../utils/PostMessenger';
import * as userService from '../../api/userService';
import styles from './AppFormStyles.styl';
import { ThemeContext } from '../Routes/ThemeProvider';
import analytics from '../../analytics';

const isApp = appUtils.fromApp();

export const loadingPlaceholder = (
    <div className={isApp ? styles.app_wrapper_basic : styles.app_wrapper}>
        <div className={styles.loading_wrapper}>
            <Spin />
        </div>
    </div>
);

const App: React.FunctionComponent<React.PropsWithChildren<{}>> = (props) => {
    const { children } = props;

    const container = useRef<HTMLDivElement>(null);
    const { parentOrigin } = routeUtils.parseQuery<{ parentOrigin: string }>(location.search);

    const { theme } = useContext(ThemeContext);

    const { userID } = useParams<{ userID: string; companyID: string }>();

    const [fetchingData, setFetchingData] = useState(true);

    useEffect(() => {
        if (userID) {
            setFetchingData(true);

            const allTask: Promise<any>[] = [
                userService.getUserProfile(userID),
                userService.getUserLicense().catch(() => {}),
            ];

            Promise.all(allTask)
                .then((res) => {
                    if (res[0] && res[0].data?.email) {
                        analytics.setUserIdentity({ email: res[0].data?.email, id: userID });
                    }
                })
                .finally(() => {
                    setFetchingData(false);
                });
        } else {
            setFetchingData(false);
        }
    }, [userID]);

    useEffect(() => {
        if (parentOrigin) {
            postMessenger.setParentOrigin(decodeURIComponent(parentOrigin));
        }

        message.config({
            getContainer: () => container.current ?? document.body,
        });

        return () => {
            message.config({
                getContainer: () => document.body,
            });
        };
    }, [parentOrigin]);

    return (
        <div ref={container} data-mode={theme ?? 'bright'} style={{ height: '100%' }}>
            {fetchingData ? loadingPlaceholder : children}
        </div>
    );
};

export default App;
