// This is a react router Link alternative created using Button instead or Anchor
// because it will appear weird when Anchors are embedded in iOS web view

import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';
import { routeUtils } from 'tds-common-fe';
import AnalyticsButton from '../AnalyticsComponents/Button';
import { getAuthParams } from '../../utils/authUtils';

interface LinkProps {
    to: string;
    retainAuthSearch?: boolean;
    style?: CSSProperties;
    size?: 'small' | 'large';
}

const Link: React.FunctionComponent<React.PropsWithChildren<LinkProps>> = React.memo((props) => {
    const { to: path, retainAuthSearch = true, style = {}, size } = props;
    const history = useHistory();

    const handleClick = () => {
        let newPath = path;
        if (retainAuthSearch) {
            const query = getAuthParams();
            newPath = routeUtils.makeQueryPath(path, { ...query }, false);
        }
        history.push(newPath);
    };

    return (
        <AnalyticsButton type="link" onClick={handleClick} size={size} style={{ padding: 0, ...style }}>
            {props.children}
        </AnalyticsButton>
    );
});

export default Link;
