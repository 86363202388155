import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { routeUtils } from 'tds-common-fe';
import { Spin } from 'antd';
import FormattedMessage from '../../../localization/FormatMessage';
import Button from '../../AnalyticsComponents/Button';
import { AUTH_ROUTES, MULTIPLE_DEVICES_ROUTES } from '../../Routes/urls';
import { VerifyPasswordParams } from '../VerifyPassword';
import styles from './MultipleDevices.styl';
import analytics from '../../../analytics/index';
import postMessenger from '../../../utils/PostMessenger';
import AnalyticsButton from '../../AnalyticsComponents/Button';

const MultipleDevicesLogin: React.FunctionComponent = () => {
    const history = useHistory();
    const params = routeUtils.parseQuery<Partial<VerifyPasswordParams> & { parentOrigin?: string }>(location.search);
    const { email, product, client, parentOrigin, appClientID } = params;

    const verifyRedirectURL = routeUtils.makeQueryPath(
        `${location.origin}${MULTIPLE_DEVICES_ROUTES.LOGIN_REDIRECTION}`,
        {
            email,
            product,
            client,
            cancel_redirect_uri: location.href,
            parentOrigin,
            appClientID,
        }
    );
    const verifyPasswordPath = routeUtils.makeQueryPath(AUTH_ROUTES.VERIFY_PASSWORD, {
        email,
        cancel_redirect_uri: location.href,
        product,
        client,
        close_tab: 'false',
        action: 'confirm_signin',
        redirect_uri: verifyRedirectURL,
    });

    useEffect(() => {
        if (parentOrigin) {
            postMessenger.setParentOrigin(decodeURIComponent(parentOrigin));
        }
    }, [parentOrigin]);

    const [renderComplete, setRenderComplete] = useState(false);

    useEffect(() => {
        postMessenger.post({ type: 'renderComplete' });
        setRenderComplete(true);
    }, []);

    return renderComplete ? (
        <>
            <div className={styles.title_container}>
                <FormattedMessage id="Login.Title" />
            </div>
            <div className={styles.body_container}>
                <FormattedMessage id="MultipleDevices.Login.Body" values={{ email: <b>{email}</b> }} />
            </div>
            <div className={styles.button_container}>
                <Button
                    type="primary"
                    loading={false}
                    disabled={false}
                    onClick={() => {
                        analytics.logMultipleDevicesLogin('sign_in');
                        history.push(verifyPasswordPath);
                    }}
                    size="large"
                >
                    <FormattedMessage id="Login.Title" />
                </Button>
            </div>
            <div className={styles.button_container}>
                <AnalyticsButton
                    type="link"
                    onClick={() => {
                        postMessenger.post({ type: 'logout' });
                        analytics.logMultipleDevicesLogin('use_different_account');
                    }}
                    size="large"
                >
                    <FormattedMessage id="MultipleDevices.Warning.Cancel" />
                </AnalyticsButton>
            </div>
        </>
    ) : (
        <Spin style={{ marginTop: '25px' }} />
    );
};

export default MultipleDevicesLogin;
