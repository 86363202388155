import { FormatIDs } from '../types';

export enum ErrorCode {
    InvalidCredential = 'InvalidCredential',
    AccountNotVerified = 'AccountNotVerified',
    AccountMaxLoginAttempts = 'AccountMaxLoginAttempts',
}

export function getLoginErrorMessage(errorCode: string): FormatIDs | undefined {
    switch (decodeURIComponent(errorCode)) {
        case ErrorCode.InvalidCredential:
            return 'Error.InvalidCredentials';
        case ErrorCode.AccountMaxLoginAttempts:
            return 'Error.AccountMaxLoginAttempts';
        case ErrorCode.AccountNotVerified:
            return 'Error.AccountNotVerifiedResendEmail';
        default:
            return 'Error.LoginFailed';
    }
}
