import * as appUtils from '../utils/appUtils';
import * as storageUtils from '../utils/storageUtils';
import enLocalizedMessage from './en';
import zhLocalizedMessage from './zh';
import jaLocalizedMessage from './ja';
import koLocalizedMessage from './ko';
import deLocalizedMessage from './de';
import frLocalizedMessage from './fr';
import itLocalizedMessage from './it';
import ptLocalizedMessage from './pt';
import ruLocalizedMessage from './ru';
import esLocalizedMessage from './es';
import idLocalizedMessage from './id';
import svLocalizedMessage from './sv';
import config from '../config';

export type LocalizedMessage = typeof enLocalizedMessage;

export type LanguageOptions = 'en' | 'zh' | 'ja' | 'ko' | 'de' | 'fr' | 'it' | 'pt' | 'ru' | 'es' | 'id' | 'sv';

const releasedLanguages: LanguageOptions[] = ['en', 'zh', 'ja', 'ko', 'de', 'fr', 'it', 'pt', 'ru', 'es', 'id'];

export const messagesLib: { [key in LanguageOptions]: { [key: string]: string } } = {
    en: enLocalizedMessage,
    zh: zhLocalizedMessage,
    ja: jaLocalizedMessage,
    ko: koLocalizedMessage,
    de: deLocalizedMessage,
    fr: frLocalizedMessage,
    it: itLocalizedMessage,
    pt: ptLocalizedMessage,
    ru: ruLocalizedMessage,
    es: esLocalizedMessage,
    id: idLocalizedMessage,
    sv: svLocalizedMessage,
};

export const languageKeys =
    config.APP_ENV === 'dev' ? (Object.keys(messagesLib) as LanguageOptions[]) : releasedLanguages;

const navLanguage = navigator.language.split(/[-_]/)[0]; // language without region code
const storageLanguage = !appUtils.fromApp() ? storageUtils.getLocalItem('language') : undefined;
const language = storageLanguage ?? navLanguage;

export const defaultLanguage: LanguageOptions = languageKeys.find((key) => key === language) ?? 'en';
