import firebaseAnalytics from './firebaseAnalytics';
import { EventName } from './analyticsConstants';
import mixpanelAnalytics from './mixpanelAnalytics';
import { isExternalUser } from 'tds-common-fe';

class Analytics {
    initialize = () => {
        firebaseAnalytics.initialize();
        mixpanelAnalytics.initialize();
    };

    setUserProperties = (name: EventName, value: string) => {
        firebaseAnalytics.setUserProperties(name, value);
        mixpanelAnalytics.setUserProperties({ [name]: value });
    };

    setUserIdentity = (params: { email: string; id: string }) => {
        this.setUserProperties(EventName.isExternalUser, isExternalUser(params.email));
        mixpanelAnalytics.setUserProperties({ [EventName.userID]: params.id });
        mixpanelAnalytics.setUniqueID(params.id);
    };

    logEventWithName = (name: EventName | string, params: { [key: string]: string } = {}) => {
        firebaseAnalytics.logEventWithName(name, params);
        mixpanelAnalytics.track(name, params);
    };

    setScreenName = (name: string) => {
        firebaseAnalytics.setScreenName(name);
    };

    logPageView = ({ title, path }: { title?: string; path?: string }) => {
        this.logEventWithName(EventName.PAGE_VIEW, {
            page_title: title ?? '',
            page_path: path ?? '',
        });
    };

    logLogin = (method: string) => {
        // FIXME: When page jump to for login action. Analytics process will stop.
        this.logEventWithName(EventName.LOGIN, { method });
    };

    logSignUp = (method: string) => {
        this.logEventWithName(EventName.SIGN_UP, { method });
    };

    logButtonClick = (buttonName: string) => {
        this.logEventWithName(EventName.BUTTON_CLICK, {
            button_name: buttonName,
        });
    };

    logSwitchAccount = (product: string) => {
        this.logEventWithName(EventName.SWITCH_ACCOUNT, {
            product,
        });
    };

    logRequestDeleteAccount = (buttonName: string) => {
        this.logEventWithName(EventName.REQUEST_DELETE_ACCOUNT, {
            button_name: buttonName,
        });
    };

    logVerifyDeleteAccount = (buttonName: string) => {
        this.logEventWithName(EventName.VERIFY_DELETE_ACCOUNT, {
            button_name: buttonName,
        });
    };

    logConfirmDeleteAccount = (buttonName: string) => {
        this.logEventWithName(EventName.CONFIRM_DELETE_ACCOUNT, {
            button_name: buttonName,
        });
    };

    logVerifyPassword = (buttonName: string) => {
        this.logEventWithName(EventName.VERIFY_PASSWORD, {
            button_name: buttonName,
        });
    };

    // 2FA
    logSetUpTwoFA = (category: string, buttonName: string) => {
        this.logEventWithName(EventName.SETUP_2FA, {
            button_name: buttonName,
            category,
        });
    };

    logLoginTwoFA = (buttonName: string) => {
        this.logEventWithName(EventName.LOGIN_2FA, {
            button_name: buttonName,
        });
    };

    logLoginRecoveryKeyTwoFA = (buttonName: string) => {
        this.logEventWithName(EventName.LOGIN_RECOVERY_KEY_2FA, {
            button_name: buttonName,
        });
    };

    logResetTwoFA = (buttonName: string) => {
        this.logEventWithName(EventName.RESET_2FA, {
            button_name: buttonName,
        });
    };

    logRecoveryKeyTwoFA = (buttonName: string) => {
        this.logEventWithName(EventName.RECOVERY_KEY_2FA, {
            button_name: buttonName,
        });
    };

    // multiple devices
    logMultipleDevicesWarning = (buttonName: string) => {
        this.logEventWithName(EventName.MULTIPLE_DEVICES_WARNING, {
            button_name: buttonName,
        });
    };

    logMultipleDevicesLogin = (buttonName: string) => {
        this.logEventWithName(EventName.MULTIPLE_DEVICES_LOGIN, {
            button_name: buttonName,
        });
    };
}

const analytics = new Analytics();

export default analytics;
