export interface LicenseTier {
    tier: string;
    name: string;
    description: string;
    features: LicenseFeature[];
    free: boolean;
    discount?: number;
    discountDescription?: string;
    customerFeatureList?: string;
    pricing?: LicensePrice[];
    rank: number;
    upgradable: boolean;
}

export enum ProductCode {
    GPR_INSIGHTS = 'GPR_INSIGHTS',
    INSPECT = 'INSPECT',
}

export const ProductCodeName: { [key in ProductCode]: string } = {
    [ProductCode.GPR_INSIGHTS]: 'GPR Insights',
    [ProductCode.INSPECT]: 'Inspect',
};

export interface LicenseFeature {
    code: string;
    description: string;
}

export enum CurrencyCode {
    CHF = 'CHF',
    USD = 'USD',
    EUR = 'EUR',
    SGD = 'SGD',
    RMB = 'RMB',
    RUB = 'RUB',
    AED = 'AED',
    BRL = 'BRL',
    GBP = 'GBP',
}

export interface LicensePrice {
    currency: string;
    currencyCode: CurrencyCode;
    monthlyPrice: number;
    yearlyPrice: number;
    yearlyDiscountedPrice?: number;
}

// region API Params

interface SharedLicenseRequestParams {
    firstName?: string;
    lastName?: string;
    contactNumber: string;
    companyName?: string;
    countryCode: string;
}

export interface RequestSalesContactParams extends SharedLicenseRequestParams {
    message?: string;
    contactEmail?: string;
    licenseTierFree?: boolean;
    captcha?: string;
}

// endRegion
