import React, { useRef } from 'react';
import { routeUtils } from 'tds-common-fe';
import styles from './Login.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { ReactComponent as ChevronLeft } from '../../../../images/chevron_left.svg';
import analytics from '../../../../analytics/index';
import { ButtonsTwoFA } from '../../../../analytics/analyticsConstants';
import URL from '../../../../api/urls';

interface GoBackToSignInProps {
    disabled: boolean;
    setDisabled: (disable: boolean) => void;
    queryParams: any;
}

const GoBackToSignIn: React.FunctionComponent<GoBackToSignInProps> = (props) => {
    const { disabled, setDisabled, queryParams } = props;
    const htmlFormRef = useRef<HTMLFormElement>(null);

    const actionURL = routeUtils.makeQueryPath(URL.LOGOUT, {
        ...queryParams,
    });

    const handleSubmit = () => {
        setDisabled(true);
        analytics.logLoginTwoFA(ButtonsTwoFA.goBackToSignIn);
        const form = htmlFormRef.current;
        if (form !== null) {
            form.submit();
        }
    };

    return (
        <>
            <AnalyticsButton
                disabled={disabled}
                className={styles.go_back_button}
                type="link"
                size="large"
                onClick={handleSubmit}
            >
                <ChevronLeft />
                <FormattedMessage id="2FA.Login.Footer.GoBack" />
            </AnalyticsButton>
            <form ref={htmlFormRef} action={actionURL} method="GET" className={styles.hidden_form}>
                {Object.keys(queryParams).map((key) => (
                    <input key={key} type="hidden" name={key} value={queryParams[key]} />
                ))}
            </form>
        </>
    );
};

export default GoBackToSignIn;
