import React from 'react';
import { classNames } from '../../../../utils/classUtils';
import wrapperStyles from '../../../Authentication/AuthWrapper.styl';
import styles from '../TwoFAStyles.styl';
import FormattedMessage from '../../../../localization/FormatMessage';

const ErrorTwoFA: React.FunctionComponent = () => {
    return (
        <div className={classNames(wrapperStyles.content_wrapper, wrapperStyles.input_block)}>
            <div className={styles.header}>
                <FormattedMessage id="2FA.Error.Title" />
            </div>
            <div className={styles.body_text}>
                <FormattedMessage id="2FA.Error.Body" />
            </div>
        </div>
    );
};

export default ErrorTwoFA;
