const config = {
    APP_ENV: process.env.APP_ENV,
    APP_VERSION: process.env.APP_VERSION,
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
    NODE_ENV: process.env.NODE_ENV,
    EAGLE_ID_DOMAIN: process.env.REACT_APP_EAGLE_ID_DOMAIN,
    EAGLE_ID_API_VERSION: process.env.REACT_APP_EAGLE_ID_API_VERSION,
    INSPECT_DOMAIN: process.env.REACT_APP_INSPECT_DOMAIN,
    WORKSPACE_DOMAIN: process.env.REACT_APP_WORKSPACE_DOMAIN,

    INSPECT_WEB_FIREBASE_API_KEY: process.env.REACT_APP_INSPECT_WEB_FIREBASE_API_KEY,
    INSPECT_WEB_FIREBASE_PROJECT_ID: process.env.REACT_APP_INSPECT_WEB_FIREBASE_PROJECT_ID,
    INSPECT_WEB_FIREBASE_SENDER_ID: process.env.REACT_APP_INSPECT_WEB_FIREBASE_SENDER_ID,
    INSPECT_WEB_FIREBASE_APP_ID: process.env.REACT_APP_INSPECT_WEB_FIREBASE_APP_ID,
    INSPECT_WEB_FIREBASE_MEASUREMENT_ID: process.env.REACT_APP_INSPECT_WEB_FIREBASE_MEASUREMENT_ID,

    RECAPTCHA_SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
};

export default config;
