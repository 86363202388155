import React, { useEffect } from 'react';
import { Checkbox, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormItem } from 'tds-common-fe';
import { useSelector } from 'react-redux';
import { FieldsType, rules } from './contactFieldsRules';
import styles from './ContactFields.styl';
import AuthInput from '../../../Authentication/Custom/AuthInput';
import AuthSelect from '../../../Authentication/Custom/AuthSelect';
import FormattedMessage from '../../../../localization/FormatMessage';
import countries from '../../../../resources/countries.json';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import { RootState } from '../../../../reducers';
import { FormatIDs } from '../../../../types';
import { getCountryCallingCode } from '../../../../utils/phoneUtils';
import { ProductCode, ProductCodeName } from '../../../../types/license';
import useLicenseTiers from '../../../../hooks/useLicenseTiers';

class Item extends FormItem<FieldsType> {}

const { Option } = Select;

interface ContactFieldsProps {
    inputs: React.MutableRefObject<HTMLInputElement[]>;
    form: FormInstance;
    useDefaultEmail: boolean;
    onSetUseDefaultEmail: (useDefaultEmail: boolean) => void;
    emailCheckboxMessage: FormatIDs;
    emailSettings?: {
        label: FormatIDs;
        placeholder: FormatIDs;
        emptyErrorMessage: FormatIDs;
    };
    hasAddress?: Boolean;
    shortForm?: boolean;
    showProduct?: boolean;
    product?: ProductCode;
    showTiers?: boolean;
    tier?: string;
}

const ContactFields: React.FunctionComponent<ContactFieldsProps> = (props) => {
    const {
        inputs,
        form,
        useDefaultEmail,
        onSetUseDefaultEmail,
        emailSettings,
        emailCheckboxMessage,
        hasAddress,
        shortForm,
        showProduct,
        product,
        showTiers,
        tier,
    } = props;
    const { getFieldValue, setFieldsValue } = form;
    const { formatMessage } = useFormatMessage();

    const userProfile = useSelector((state: RootState) => {
        return state.profile.userProfile;
    });

    const { tiers, tierIDs } = useLicenseTiers();

    const { firstName, lastName, email, companyName, countryCode } = userProfile;

    useEffect(() => {
        if (useDefaultEmail) {
            // Reset back to default email
            setFieldsValue({
                email,
            });
        }
    }, [useDefaultEmail, email, setFieldsValue]);

    const emailRules = emailSettings
        ? rules.email?.map((rule) => {
              if ((rule as any).required) {
                  return {
                      required: true,
                      message: <FormattedMessage id={emailSettings.emptyErrorMessage} />,
                  };
              }
              return rule;
          })
        : rules.email;

    const fields = {
        product: (
            <Item
                label={<FormattedMessage id="Contact.Field.Product" />}
                className={styles.form_item}
                name="product"
                rules={rules.product}
                initialValue={product}
            >
                <Select className={styles.item_select} value={product}>
                    {Object.values(ProductCode)
                        .filter((item) => item !== ProductCode.INSPECT)
                        .map((item) => (
                            <Select.Option key={item} value={item}>
                                {ProductCodeName[item]}
                            </Select.Option>
                        ))}
                </Select>
            </Item>
        ),
        tier: (
            <Item
                label={<FormattedMessage id="Contact.Field.Plan" />}
                className={styles.form_item}
                name="tier"
                rules={rules.tier}
                initialValue={tier}
            >
                <Select className={styles.item_select} value={product}>
                    {tierIDs.map((id) => (
                        <Select.Option key={tiers[id].tier} value={tiers[id].tier}>
                            {tiers[id].name}
                        </Select.Option>
                    ))}
                </Select>
            </Item>
        ),
        email: (
            <Item
                label={<FormattedMessage id={emailSettings ? emailSettings.label : 'SignUp.Field.Email'} />}
                className={useDefaultEmail ? styles.form_item_hidden : styles.form_item}
                name="email"
                rules={emailRules}
                initialValue={email}
            >
                <AuthInput
                    type="email"
                    placeholder={formatMessage({
                        id: emailSettings ? emailSettings.placeholder : 'SignUp.Field.Email.Placeholder',
                    })}
                    inputs={inputs}
                    size="middle"
                    tabIndex={useDefaultEmail ? -1 : 0}
                    disabled={useDefaultEmail}
                />
            </Item>
        ),

        name: (
            <div className={styles.inline_container}>
                <Item
                    label={<FormattedMessage id="SignUp.Field.FirstName" />}
                    className={styles.form_item}
                    name="firstName"
                    rules={rules.firstName}
                    initialValue={firstName}
                >
                    <AuthInput
                        placeholder={formatMessage({ id: 'SignUp.Field.FirstName.Placeholder' })}
                        inputs={inputs}
                        size="middle"
                    />
                </Item>
                <div className={styles.inline_space} />
                <Item
                    label={<FormattedMessage id="SignUp.Field.LastName" />}
                    className={styles.form_item}
                    name="lastName"
                    rules={rules.lastName}
                    initialValue={lastName}
                >
                    <AuthInput
                        placeholder={formatMessage({ id: 'SignUp.Field.LastName.Placeholder' })}
                        inputs={inputs}
                        size="middle"
                    />
                </Item>
            </div>
        ),

        country: (
            <Item
                label={<FormattedMessage id="SignUp.Field.Country" />}
                className={styles.form_item}
                style={{ paddingBottom: 4 }}
                name="countryCode"
                rules={rules.countryCode}
                initialValue={countryCode}
            >
                <AuthSelect
                    showSearch
                    placeholder={<FormattedMessage id="SignUp.Field.Country.Placeholder" />}
                    size="middle"
                    getPopupContainer={(node) => node.myParentElement()}
                >
                    {countries.map(({ name, code }) => (
                        <Option key={code} value={code}>
                            {name}
                        </Option>
                    ))}
                </AuthSelect>
            </Item>
        ),

        companyName: (
            <Item
                label={<FormattedMessage id="SignUp.Field.CompanyName" />}
                className={styles.form_item}
                name="companyName"
                rules={rules.companyName}
                initialValue={companyName}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.CompanyName.Placeholder' })}
                    inputs={inputs}
                    size="middle"
                />
            </Item>
        ),

        address: (
            <>
                <Item
                    label={<FormattedMessage id="Contact.Field.Address" />}
                    className={styles.form_item}
                    name="addressLine1"
                    rules={rules.addressLine1}
                >
                    <AuthInput
                        placeholder={formatMessage({ id: 'Contact.Field.AddressLine1.Placeholder' })}
                        inputs={inputs}
                        size="middle"
                    />
                </Item>
                <Item className={styles.form_item} name="addressLine2">
                    <AuthInput
                        placeholder={formatMessage({ id: 'Contact.Field.AddressLine2.Placeholder' })}
                        inputs={inputs}
                        size="middle"
                    />
                </Item>
            </>
        ),

        phone: (
            <Item label={<FormattedMessage id="Contact.Field.Phone" />} className={styles.form_item} shouldUpdate>
                {() => {
                    const countryCode: string | undefined = getFieldValue('countryCode');
                    const callingCode = `+${countryCode ? getCountryCallingCode(countryCode) : '0'}`;
                    return (
                        <Item
                            name="phone"
                            rules={rules.phone}
                            className={styles.form_item}
                            dependencies={['countryCode']}
                        >
                            <AuthInput
                                addonBefore={callingCode}
                                placeholder={formatMessage({ id: 'Contact.Field.Phone' })}
                                inputs={inputs}
                                size="middle"
                                type="tel"
                            />
                        </Item>
                    );
                }}
            </Item>
        ),
    };

    return (
        <>
            {!shortForm && (
                <>
                    <Checkbox
                        className={styles.form_item}
                        checked={useDefaultEmail}
                        onChange={(event) => onSetUseDefaultEmail(event.target.checked)}
                    >
                        <FormattedMessage id={emailCheckboxMessage} />
                    </Checkbox>
                    {showProduct && product && showTiers && tierIDs.length ? (
                        <div className={styles.inline_container}>
                            {fields.product}
                            <div className={styles.inline_space} />
                            {fields.tier}
                        </div>
                    ) : (
                        <>{showProduct && product && fields.product}</>
                    )}
                    {fields.email}
                    {fields.name}
                    {fields.companyName}
                </>
            )}
            {fields.country}
            {hasAddress && !shortForm && fields.address}
            {fields.phone}
        </>
    );
};

export default ContactFields;
