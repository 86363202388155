import React, { useCallback, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import jaJP from 'antd/locale/ja_JP';
import koKR from 'antd/locale/ko_KR';
import deDE from 'antd/locale/de_DE';
import svSV from 'antd/locale/sv_SE';
import IntlProxy from './IntlProxy';
import { messagesLib, defaultLanguage, LanguageOptions } from './index';
import * as storageUtils from '../utils/storageUtils';
import { THEME_CONFIG } from '../styles/antdThemeConfig';

interface LocalizationContextState {
    language: LanguageOptions;
    setLanguage: (language: LanguageOptions) => void;
}

export const LocalizationContext = React.createContext<LocalizationContextState>({
    language: defaultLanguage,
    setLanguage: () => {},
});

function getConfigProviderLocale(language: LanguageOptions) {
    switch (language) {
        case 'en':
            return enUS;
        case 'zh':
            return zhCN;
        case 'ja':
            return jaJP;
        case 'ko':
            return koKR;
        case 'de':
            return deDE;
        case 'sv':
            return svSV;
        default:
            return enUS;
    }
}

const LocalizedIntlProvider: React.FunctionComponent<React.PropsWithChildren<{}>> = (props) => {
    const [language, setLanguage] = useState(defaultLanguage);

    const handleSetLanguage = useCallback((language: LanguageOptions) => {
        setLanguage(language);
        storageUtils.saveLocalItem('language', language);
    }, []);

    const messages = useMemo(() => {
        return {
            ...messagesLib.en,
            ...messagesLib[language],
        };
    }, [language]);

    return (
        <LocalizationContext.Provider value={{ language, setLanguage: handleSetLanguage }}>
            <ConfigProvider
                theme={THEME_CONFIG}
                locale={getConfigProviderLocale(language)}
                button={{ autoInsertSpace: false }}
            >
                <IntlProvider locale={language} messages={messages}>
                    {props.children}
                    <IntlProxy />
                </IntlProvider>
            </ConfigProvider>
        </LocalizationContext.Provider>
    );
};

export default LocalizedIntlProvider;
