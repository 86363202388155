import React from 'react';
import { Switch } from 'react-router-dom';
import { APP_ROUTES } from './urls';
import App from '../App';
import PersonalProfile from '../App/UserProfile/PersonalProfile';
import CompanyProfile from '../App/UserProfile/CompanyProfile';
import AnalyticsRoute from '../AnalyticsComponents/Route';
import SubscriptionInfo from '../App/License/SubscriptionInfo';
import ContactSales from '../App/License/ContactSales';
import ContactSalesDone from '../App/License/ContactSales/ContactSalesDone';
import UpgradeInfo from '../App/License/UpgradeInfo';
import PurchaseForm from '../App/License/PurchaseForm';
import PurchaseDone from '../App/License/PurchaseForm/PurchaseDone';
import FeatureInfo from '../App/License/UpgradeInfo/FeatureInfo';
import RequestFreeTrial from '../App/License/ContactSales/RequestFreeTrial';

const AppRoutes: React.FunctionComponent = () => (
    <App>
        <Switch>
            <AnalyticsRoute exact path={[APP_ROUTES.PERSONAL_PROFILE]} titleFormatIDs={['NavTitle.PersonalProfile']}>
                <PersonalProfile />
            </AnalyticsRoute>
            <AnalyticsRoute exact path={APP_ROUTES.COMPANY_PROFILE} titleFormatIDs={['NavTitle.CompanyProfile']}>
                <CompanyProfile />
            </AnalyticsRoute>
            <AnalyticsRoute exact path={APP_ROUTES.CONTACT_SALES} titleFormatIDs={['NavTitle.ContactSales']}>
                <ContactSales />
            </AnalyticsRoute>
            <AnalyticsRoute exact path={APP_ROUTES.REQUEST_FREE_TRIAL} titleFormatIDs={[]}>
                <RequestFreeTrial />
            </AnalyticsRoute>
            <AnalyticsRoute exact path={APP_ROUTES.CONTACT_SALES_DONE} titleFormatIDs={['NavTitle.ContactSales']}>
                <ContactSalesDone />
            </AnalyticsRoute>
            <AnalyticsRoute exact path={APP_ROUTES.SUBSCRIPTION_INFO} titleFormatIDs={['NavTitle.SubscriptionInfo']}>
                <SubscriptionInfo />
            </AnalyticsRoute>
            <AnalyticsRoute exact path={APP_ROUTES.LICENSE_UPGRADE_INFO} titleFormatIDs={['NavTitle.LicenseUpgrade']}>
                <UpgradeInfo />
            </AnalyticsRoute>
            <AnalyticsRoute exact path={APP_ROUTES.LICENSE_FEATURE_INFO} titleFormatIDs={[]}>
                <FeatureInfo />
            </AnalyticsRoute>
            <AnalyticsRoute
                exact
                path={APP_ROUTES.LICENSE_PURCHASE_FORM}
                titleFormatIDs={['NavTitle.LicenseUpgrade']}
                disabled
            >
                <PurchaseForm />
            </AnalyticsRoute>
            <AnalyticsRoute
                exact
                path={APP_ROUTES.LICENSE_PURCHASE_DONE}
                titleFormatIDs={['NavTitle.LicenseUpgrade']}
                disabled
            >
                <PurchaseDone />
            </AnalyticsRoute>
        </Switch>
    </App>
);

export default AppRoutes;
