import mixpanel, { Config } from 'mixpanel-browser';
import { consumerInfo } from '../api/headerUtils';

class MixpanelAnalytics {
    private userProperties: undefined | object = undefined;

    initialize = () => {
        const { product, client, version, appVersion } = consumerInfo;
        const mixpanelConfig: Partial<Config> = {
            debug: false, // set to true to see it printed in console
            track_pageview: true,
            persistence: 'localStorage',
        };
        // inspect analytics logged to inspect project EC-1636
        if (product === 'inspect') {
            mixpanel.init(process.env.REACT_APP_INSPECT_MIXPANEL_TOKEN!, mixpanelConfig);
        } else {
            mixpanel.init(process.env.REACT_APP_EAGLEID_MIXPANEL_TOKEN!, mixpanelConfig);
        }
        mixpanel.register({ app_version: appVersion, version, product, client });
    };

    setUserProperties = (userProperties: any) => {
        if (mixpanel.people) {
            mixpanel.people.set(userProperties);
        } else {
            // properties cannot be set until identity is set
            this.userProperties = { ...(this.userProperties ?? {}), ...userProperties };
        }
    };

    setUniqueID = (id: string) => {
        mixpanel.identify(id);
        if (this.userProperties) {
            mixpanel.people.set(this.userProperties);
            this.userProperties = undefined;
        }
    };

    track = (name: string, params: { [key: string]: string } = {}) => {
        mixpanel.track(name, params);
    };

    reset = () => {
        mixpanel.reset();
    };
}

const mixpanelAnalytics = new MixpanelAnalytics();

export default mixpanelAnalytics;
