import { routeUtils } from 'tds-common-fe';
import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import styles from './AccountDeletion.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import * as urls from '../../Routes/urls';
import { deleteAccount } from '../../../api/userService';
import analytics from '../../../analytics/index';
import { APIError } from '../../../api/error';

interface RequestAccountDeletionParams {
    email: string;
    redirect_uri: string;
    cancel_redirect_uri: string;
    code: string;
}

enum RequestAccountDeletionType {
    success,
    failure,
    requestAlrExists,
}

const ITEM_ALREADY_EXISTS_ERROR = 'ItemAlreadyExist';

const RequestAccountDeletion: React.FunctionComponent = () => {
    const params = routeUtils.parseQuery<RequestAccountDeletionParams>(location.search);
    const { email, code, redirect_uri: redirectURL } = params;
    const [requestType, setRequestType] = useState<undefined | RequestAccountDeletionType>(
        code ? undefined : RequestAccountDeletionType.failure
    );

    useEffect(() => {
        if (code) {
            deleteAccount(code)
                .then(() => setRequestType(RequestAccountDeletionType.success))
                .catch((err: APIError) => {
                    if (err.response?.data?.code === ITEM_ALREADY_EXISTS_ERROR) {
                        setRequestType(RequestAccountDeletionType.requestAlrExists);
                    } else {
                        setRequestType(RequestAccountDeletionType.failure);
                    }
                });
        } else {
            setRequestType(RequestAccountDeletionType.failure);
        }
    }, [code]);

    const reInitiateDeletionPath = routeUtils.makeQueryPath(urls.AUTH_ROUTES.INITIATE_ACCOUNT_DELETION, {
        email,
        redirect_uri: redirectURL,
    });

    const goToWorkspaceLink = (
        <Button
            href={redirectURL}
            onClick={() => analytics.logVerifyDeleteAccount('go_back_to_workspace')}
            type="link"
            size="large"
        >
            <FormattedMessage id="AccountDeletion.Request.GoToWorkspace" />
        </Button>
    );

    const REQUEST_ACCOUNT_DELETION_TEXT: Record<
        RequestAccountDeletionType,
        { title: React.ReactNode; body: React.ReactNode; link: React.ReactNode }
    > = {
        [RequestAccountDeletionType.success]: {
            title: <FormattedMessage id="AccountDeletion.Request.Title" />,
            body: (
                <>
                    <FormattedMessage id="AccountDeletion.Request.Body" values={{ email: <b>{email}</b> }} />
                    <b>
                        <FormattedMessage id="AccountDeletion.Request.ConfirmationRequired" />
                    </b>
                </>
            ),
            link: goToWorkspaceLink,
        },
        [RequestAccountDeletionType.failure]: {
            title: <FormattedMessage id="AccountDeletion.Request.Failed.Title" />,
            body: <FormattedMessage id="AccountDeletion.Request.Failed.Body" />,
            link: (
                <Button
                    href={reInitiateDeletionPath}
                    onClick={() => analytics.logVerifyDeleteAccount('re-initiate_deletion_request')}
                    type="link"
                    size="large"
                >
                    <FormattedMessage id="AccountDeletion.Request.Failed.ReInitiateDeletion" />
                </Button>
            ),
        },
        [RequestAccountDeletionType.requestAlrExists]: {
            title: <FormattedMessage id="AccountDeletion.Request.Failed.Title" />,
            body: (
                <>
                    <FormattedMessage
                        id="AccountDeletion.Request.Failed.ItemAlrExists"
                        values={{ email: <b>{email}</b> }}
                    />
                    <b>
                        <FormattedMessage id="AccountDeletion.Request.ConfirmationRequired" />
                    </b>
                </>
            ),
            link: goToWorkspaceLink,
        },
    };

    return requestType !== undefined ? (
        <div className={styles.container}>
            <div className={styles.title}>{REQUEST_ACCOUNT_DELETION_TEXT[requestType].title}</div>
            <div className={styles.center_modal_body}>{REQUEST_ACCOUNT_DELETION_TEXT[requestType].body}</div>
            <div className={styles.redirect_container}>{REQUEST_ACCOUNT_DELETION_TEXT[requestType].link}</div>
        </div>
    ) : (
        <div className={styles.container}>
            <Spin />
        </div>
    );
};

export default RequestAccountDeletion;
