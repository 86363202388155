// region Authentication

import config from '../../config';

export const AUTH_ROUTES = {
    LOGIN: '/login',
    FORGET_PASSWORD: '/forgetPassword',
    FORGET_PASSWORD_DONE: '/forgetPasswordDone',
    RESET_PASSWORD: '/resetPassword',
    RESET_PASSWORD_DONE: '/resetPasswordDone',
    SIGN_UP: '/signUp',
    SETUP_PROFILE: '/setupProfile',
    COUNTRY_SELECTION: '/countrySelection',
    SIGN_UP_DONE: '/signUpDone',
    EMAIL_VERIFICATION: '/emailVerification',
    APP_REDIRECTION: '/appRedirection',
    INITIATE_ACCOUNT_DELETION: '/initiateAccountDeletion',
    REQUEST_ACCOUNT_DELETION: '/requestAccountDeletion',
    SCHEDULE_ACCOUNT_DELETION: '/scheduleAccountDeletion',
    VERIFY_PASSWORD: '/verifyPassword',
    SWITCH_ACCOUNT: '/switchAccount',
};

// endregion

// region App

export const APP_ROUTE_BASE = '/user/:userID';

export const TWO_FA_ROUTE_BASE = '/2FA';

export enum SetUpFlowStage {
    setUpAuthenticator = 'setUpAuthenticator',
    verificationCode = 'verificationCode',
    recoveryKey = 'recoveryKey',
    complete = 'complete',
}

export const TWO_FA_ROUTES = {
    ENABLE: `${TWO_FA_ROUTE_BASE}/enable`,
    SETUP_BASE: `${TWO_FA_ROUTE_BASE}/setup`,
    SETUP: `${TWO_FA_ROUTE_BASE}/setup/:setUpStage(${Object.values(SetUpFlowStage).join('|')})`,
    LOGIN: `${TWO_FA_ROUTE_BASE}/login`,
    LOGIN_RECOVERY_KEY: `${TWO_FA_ROUTE_BASE}/login/recoveryKey`,
    RECOVERY_KEY: `${TWO_FA_ROUTE_BASE}/recoveryKey`,
    RESET: `${TWO_FA_ROUTE_BASE}/reset`,
    RESET_REDIRECTION: `${TWO_FA_ROUTE_BASE}/reset/redirection`,
    PROFILE: `${TWO_FA_ROUTE_BASE}/profile`,
};

export const getTwoFASetUpRoute = (setUpFlowStage: SetUpFlowStage) => `${TWO_FA_ROUTES.SETUP_BASE}/${setUpFlowStage}`;

export const APP_ROUTES = {
    PERSONAL_PROFILE: `${APP_ROUTE_BASE}/personalProfile`,
    CONTACT_SALES: `${APP_ROUTE_BASE}/license/contactSales`,
    REQUEST_FREE_TRIAL: `${APP_ROUTE_BASE}/license/requestFreeTrial`,
    CONTACT_SALES_DONE: `${APP_ROUTE_BASE}/license/contactSalesDone`,
    SUBSCRIPTION_INFO: `${APP_ROUTE_BASE}/license/subscriptionInfo`,
    LICENSE_UPGRADE_INFO: `${APP_ROUTE_BASE}/license/upgradeInfo`,
    LICENSE_PURCHASE_FORM: `${APP_ROUTE_BASE}/license/purchaseForm/:tierID`,
    LICENSE_PURCHASE_DONE: `${APP_ROUTE_BASE}/license/purchaseDone`,
    COMPANY_PROFILE: `${APP_ROUTE_BASE}/companyProfile`,
    LICENSE_FEATURE_INFO: `${APP_ROUTE_BASE}/license/featureInfo`,
};

export const MULTIPLE_DEVICES_ROUTE_BASE = '/multipleDevices';

export const MULTIPLE_DEVICES_ROUTES = {
    WARNING: `${MULTIPLE_DEVICES_ROUTE_BASE}/warning`,
    LOGIN: `${MULTIPLE_DEVICES_ROUTE_BASE}/login`,
    LOGIN_REDIRECTION: `${MULTIPLE_DEVICES_ROUTE_BASE}/redirection`,
};

// endregion

// region Others

export const NOT_FOUND = '/notFound';

export const TERMS_AND_CONDITIONS = '/tnc';

// endregion

export const getRouteWithUserID = (route: string, userID: number | string) => {
    return route.replace(':userID', String(userID));
};

export const getPurchaseFormPath = (userID: number | string, tierID: string) => {
    return APP_ROUTES.LICENSE_PURCHASE_FORM.replace(':userID', String(userID)).replace(':tierID', tierID);
};

export const WORKSPACE_ROUTES = {
    DASHBOARD: `${config.WORKSPACE_DOMAIN}/dashboard`,
    PERSONAL_PROFILE: `${config.WORKSPACE_DOMAIN}/profile/personal`,
};
