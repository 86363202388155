import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import AppWrapper from '../../AppWrapper';
import styles from './SubscriptionInfo.styl';
import PlanContent from './PlanContent';
import Activation from '../Activation';
import { RootState } from '../../../../reducers';
import ExpirationInfo from '../../UserProfile/ExpirationInfo';

const SubscriptionInfo: React.FunctionComponent = () => {
    const license = useSelector((state: RootState) => state.profile.userLicense);
    const { active } = license;
    const isFreeTrial = license?.tier?.free ?? false;
    const isUpgradableOrEmpty = isEmpty(license) || (license.tier?.upgradable ?? false);

    return (
        <AppWrapper className={styles.app_wrapper}>
            {!active && <ExpirationInfo license={license} isFreeTrial={isFreeTrial} />}
            {active && <PlanContent license={license} isFreeTrial={isFreeTrial} />}
            {(!active || isUpgradableOrEmpty) && <Activation />}
        </AppWrapper>
    );
};

export default SubscriptionInfo;
