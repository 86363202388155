import React from 'react';
import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useHistory } from 'react-router-dom';
import { NonOptionalKeys, FormItem } from 'tds-common-fe';
import FormattedMessage from '../../../localization/FormatMessage';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import AuthInput from '../Custom/AuthInput';
import AuthButton from '../Custom/AuthButton';
import * as urls from '../../Routes/urls';
import * as authService from '../../../api/authService';
import { handleError } from '../../../api/error';
import AuthBasics from '../Custom/AuthBasics';
import Link from '../../Routes/Link';
import formStyles from '../Form.styl';

interface FieldsType {
    email: string;
}

const requiredFields: NonOptionalKeys<FieldsType>[] = ['email'];

class Item extends FormItem<FieldsType> {}

const ForgetPassword: React.FunctionComponent = () => {
    const [form] = Form.useForm();
    const { formatMessage } = useFormatMessage();
    const history = useHistory();

    const handleSubmit = (values: FieldsType) => {
        const { email } = values;
        authService
            .forgotPassword({ username: email })
            .then(() => history.push({ pathname: urls.AUTH_ROUTES.FORGET_PASSWORD_DONE, search: location.search }))
            .catch((error) => {
                handleError(error);
            });
    };

    const title = <FormattedMessage id="Login.ResetPassword" />;
    const description = <FormattedMessage id="ForgetPassword.Header.Description" />;

    return (
        <div className="signup">
            <AuthBasics title={title} description={description} />
            <Form form={form} layout="vertical" onFinish={handleSubmit as (values: Store) => void}>
                <Item
                    name="email"
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="SignUp.Field.Email.Error.Empty" />,
                        },
                        { type: 'email', message: <FormattedMessage id="SignUp.Field.Email.Error.Invalid" /> },
                    ]}
                >
                    <AuthInput type="email" placeholder={formatMessage({ id: 'SignUp.Field.Email.Placeholder' })} />
                </Item>
                <Item shouldUpdate>
                    {() => <AuthButton title="Common.Confirm" form={form} requiredFields={requiredFields} />}
                </Item>
                <div className={formStyles.footer_actions}>
                    <FormattedMessage
                        id="ResetPassword.Footer.LoginOrSignUp"
                        values={{
                            login: (
                                <Link to={urls.AUTH_ROUTES.LOGIN}>
                                    <FormattedMessage id="Login.Header.Title" />
                                </Link>
                            ),
                            signUp: (
                                <Link to={urls.AUTH_ROUTES.SIGN_UP}>
                                    <FormattedMessage id="SignUp.Header.Title" />
                                </Link>
                            ),
                        }}
                    />
                </div>
            </Form>
        </div>
    );
};

export default ForgetPassword;
