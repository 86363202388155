import styles from './Profile.styl';
import FormattedMessage from '../../../../localization/FormatMessage';
import Button from '../../../AnalyticsComponents/Button';
import postMessenger from '../../../../utils/PostMessenger';
import { AUTH_ROUTES, TWO_FA_ROUTES } from '../../../Routes/urls';
import { routeUtils } from 'tds-common-fe';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ProfileTwoFAQueryParams } from '../../../../types/authentication';
import analytics from '../../../../analytics';
import { EventName } from '../../../../analytics/analyticsConstants';

const ProfileTwoFAActions = () => {
    const history = useHistory();

    // remove theme as 2fa set up flow needs to be in dark theme
    const { theme, ...otherParams } = routeUtils.parseQuery<ProfileTwoFAQueryParams>(location.search);

    const recoveryKeyPath = routeUtils.makeQueryPath(TWO_FA_ROUTES.RECOVERY_KEY, otherParams);
    const viewRecoveryKeysPath = routeUtils.makeQueryPath(AUTH_ROUTES.VERIFY_PASSWORD, {
        redirect_uri: recoveryKeyPath,
        action: 'view_2fa_recovery_key',
        theme: 'dark',
        ...otherParams,
    });

    return (
        <>
            <div className={styles.action_container}>
                <FormattedMessage id="2FA.Profile.AuthenticatorApp" />
                <Button
                    onClick={() => {
                        analytics.logEventWithName(EventName.PROFILE_2FA_RESET);
                        if (postMessenger.parentOrigin) {
                            // send message to parent so that the parent can open the page directly
                            postMessenger.post({ type: 'reset2FA', data: {} });
                        } else {
                            history.push({
                                pathname: TWO_FA_ROUTES.RESET,
                                search: routeUtils.makeQueryPath('', { ...otherParams, theme: 'dark' }),
                            });
                        }
                    }}
                >
                    <FormattedMessage id="Common.Reset" />
                </Button>
            </div>
            <div className={styles.action_container}>
                <FormattedMessage id="2FA.LoginRecoveryKey.RecoveryKey" />
                <Button
                    onClick={() => {
                        analytics.logEventWithName(EventName.PROFILE_2FA_RECOVERY_KEY);
                        if (postMessenger.parentOrigin) {
                            // send message to parent so that the parent can open the page directly
                            postMessenger.post({ type: 'viewRecoveryKeys2FA', data: {} });
                        } else {
                            history.push(viewRecoveryKeysPath);
                        }
                    }}
                >
                    <FormattedMessage id="Common.View" />
                </Button>
            </div>
        </>
    );
};

export default ProfileTwoFAActions;
