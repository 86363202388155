import { useEffect, useRef } from 'react';
import postMessenger from '../../../../utils/PostMessenger';

interface Params {
    minHeight?: number;
    isReady?: boolean;
    addMargins?: boolean;
}

export const usePostPageHeight = (params: Params = {}) => {
    const { minHeight = 500, isReady = true, addMargins = false } = params;

    const containerRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!isReady) {
            return;
        }
        const height = containerRef.current?.scrollHeight ?? 0;
        const scrollFix = scrollRef.current ? scrollRef.current.scrollHeight - scrollRef.current.clientHeight : 0;

        let margin = 0;
        if (addMargins && containerRef.current) {
            const elementStyles = getComputedStyle(containerRef.current);
            margin = parseInt(elementStyles.marginTop, 10) + parseInt(elementStyles.marginBottom, 10);
        }

        postMessenger.postPageHeight(Math.max(height + scrollFix + margin, minHeight));
    }, [addMargins, minHeight, isReady]);

    return { containerRef, scrollRef };
};
