import React from 'react';
import FormattedMessage from '../../../localization/FormatMessage';
import * as urls from '../../Routes/urls';
import Link from '../../Routes/Link';
import formStyles from '../Form.styl';

const LoginFooter = React.memo(() => {
    return (
        <p className={formStyles.footer_actions}>
            <FormattedMessage id="Login.Header.NewUser" />
            <Link to={urls.AUTH_ROUTES.SIGN_UP}>
                <FormattedMessage id="Common.SignUp" />
            </Link>
        </p>
    );
});

export default LoginFooter;
