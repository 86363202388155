import React, { useCallback } from 'react';
import FormattedMessage from '../../../../localization/FormatMessage';
import AuthInput from '../../../Authentication/Custom/AuthInput';
import styles from './UserVolumeInput.styl';

interface UserVolumeInputProps {
    tierName: string;
    monthlyPrice: number;
    annualPrice: number;
    promotionContent?: React.ReactNode;
    discountPercent?: number;
    currencyShort: string;

    // For Ant Design Item
    value?: number;
    onChange?: (userVolume: number | string) => void;
}
const UserVolumeInput: React.FunctionComponent<UserVolumeInputProps> = (props) => {
    const {
        tierName,
        currencyShort,
        monthlyPrice,
        annualPrice,
        promotionContent,
        discountPercent,
        value = 1,
        onChange,
    } = props;

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            let inputValue: string | number = event.target.value;
            if (inputValue !== '') {
                inputValue = Number(inputValue);
                if (isNaN(inputValue) || inputValue < 1) {
                    inputValue = 1;
                } else {
                    inputValue = Math.floor(inputValue);
                }
            }

            if (onChange) {
                onChange(inputValue);
            }
        },
        [onChange]
    );

    return (
        <div className={styles.user_volume_block}>
            <div className={styles.description_area}>
                <b style={{ marginBottom: 4 }}>
                    {tierName} (<FormattedMessage id="PurchaseForm.AnnualSubscription" />)
                </b>
                <span>
                    {currencyShort} {(monthlyPrice * value).toFixed(2)}/
                    <FormattedMessage id="Common.Month" />
                </span>
                <span className={styles.description_small}>
                    <FormattedMessage id="PurchaseForm.BilledAsOnePayment" />
                    {` ${currencyShort} ${(annualPrice * value).toFixed(2)} `}
                    {discountPercent && (
                        <span className={styles.badge}>
                            <FormattedMessage
                                id="License.Subscription.Discount"
                                values={{ percentage: discountPercent }}
                            />
                        </span>
                    )}
                </span>
                {promotionContent && <span className={styles.description_small}>{promotionContent}</span>}
            </div>
            <div className={styles.input_area}>
                <span style={{ marginRight: '0.5em' }}>
                    <FormattedMessage id="PurchaseForm.Field.User" />:
                </span>
                <AuthInput value={value} type="number" size="middle" onChange={handleOnChange} />
            </div>
        </div>
    );
};

export default UserVolumeInput;
