import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import './polyfill';
import './index.css';
import './extension';
import * as serviceWorker from './worker/serviceWorker';
import LocalizedIntlProvider from './localization/LocalizedIntlProvider';
import Routes from './components/Routes';
import config from './config';
import store from './store';
import analytics from './analytics/index';
import { retrieveConsumerInfo } from './api/headerUtils';

import 'antd/dist/reset.css';
import './index.global.styl';
import './styles/brightModeOverride.global.styl';
import ThemeProvider from './components/Routes/ThemeProvider';

if (config.NODE_ENV === 'production') {
    Sentry.init({ dsn: config.SENTRY_DSN, environment: config.APP_ENV });
}

if (config.APP_ENV !== 'production') {
    // Add meta[name="robots"] to index.html to prevent search engine indexing
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex nofollow';
    document.head.appendChild(meta);
}

retrieveConsumerInfo();
analytics.initialize();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <LocalizedIntlProvider>
            <ThemeProvider>
                <Routes />
            </ThemeProvider>
        </LocalizedIntlProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
