import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Form, message, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useForm } from 'antd/lib/form/Form';
import { FormItem, useFormCheckErrors, routeUtils, objectUtils } from 'tds-common-fe';
import { isEqual } from 'lodash';

import * as appUtils from '../../../utils/appUtils';
import AppWrapper from '../AppWrapper';
import styles from './UserProfile.styl';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import FormattedMessage from '../../../localization/FormatMessage';
import AuthInput from '../../Authentication/Custom/AuthInput';
import EditProfileImage from '../ProfileImage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { RootState } from '../../../reducers';
import * as companyServices from '../../../api/companyService';
import { FieldsType, FieldsKeys, rules } from './companyProfileRules';
import postMessenger from '../../../utils/PostMessenger';
import { ReactComponent as CompanyLogoIcon } from '../../../images/companyLogoPlaceholder.svg';

class Item extends FormItem<FieldsType> {}

const mutableFields: FieldsKeys[] = ['name', 'address', 'contactNumber', 'email', 'website'];

const CompanyLogoPlaceholder = () => {
    return (
        <div className={styles.company_logo_placeholder}>
            <CompanyLogoIcon color="white" width={88} height={88} style={{ transform: 'scale(1.2)', opacity: 0.7 }} />
        </div>
    );
};

const CompanyProfile = () => {
    const { formatMessage } = useFormatMessage();
    const [form] = useForm();
    const { setFieldsValue } = form;
    const { edit } = routeUtils.parseQuery<{ edit: string }>(location.search);
    const initEditable = edit === 'true';

    const [fetchingData, setFetchingData] = useState(true);

    // For app to close webview
    const [dismiss, setDismiss] = useState(false);

    // Start Component related code
    const { userID } = useParams<{ userID: string }>();

    const companyProfile = useSelector((state: RootState) => {
        return state.profile.companyProfile;
    });

    const { id: companyID, pictureURL } = companyProfile;
    const [editEnabled, setEditEnabled] = useState(initEditable);
    const inputs = useRef<HTMLInputElement[]>([]);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setFetchingData(true);
        companyServices.getUserCompany(userID).finally(() => setFetchingData(false));
    }, [userID]);

    useEffect(() => {
        if (companyProfile.id) {
            postMessenger.post({ type: 'companyProfileUpdated', data: companyProfile, dismiss });
        }
    }, [companyProfile, dismiss]);

    useEffect(() => {
        if (!editEnabled && companyProfile.id) {
            setFieldsValue({
                name: companyProfile.name,
            });
        }
    }, [companyProfile, editEnabled, setFieldsValue]);

    const isApp = appUtils.fromApp();

    const handleSuccessMessage = (messageContent: string) => {
        if (postMessenger.parentOrigin) {
            postMessenger.postPopupMessage('success', messageContent);
        } else {
            message.success(messageContent);
        }
    };

    const handleUploadCompanyPicture = useCallback(
        (file: RcFile) => {
            if (!companyID) {
                return;
            }
            companyServices.uploadCompanyProfilePicture(companyID, file).then(() => {
                handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.CompanyPicture' }));
            });
        },
        [companyID, formatMessage]
    );

    const handleRemoveCompanyPicture = useCallback(() => {
        if (!companyID) {
            return;
        }
        companyServices.removeCompanyProfilePicture(companyID).then(() => {
            handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.CompanyPicture' }));
        });
    }, [companyID, formatMessage]);

    const handleSubmit = useCallback(
        async (values: { [Key in FieldsKeys]: string }) => {
            if (!companyID) {
                return;
            }
            const newValues = objectUtils.trimObjectWithKeys(values, mutableFields);
            const oldValues = objectUtils.trimObjectWithKeys(companyProfile, mutableFields);
            const shouldCompanyProfileUpdate = !isEqual(newValues, oldValues);

            if (shouldCompanyProfileUpdate) {
                setSubmitting(true);
                try {
                    await companyServices.updateCompany(companyID, newValues);
                    handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.CompanyProfile' }));
                    setEditEnabled(false);
                    if (isApp) {
                        setDismiss(true);
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                } finally {
                    setSubmitting(false);
                }
            } else {
                setEditEnabled(false);
                if (isApp) {
                    setDismiss(true);
                }
            }
        },
        [companyID, companyProfile, formatMessage, isApp]
    );

    const { checkErrors } = useFormCheckErrors(form, ['name']);

    const buttonArea = (
        <div className={styles.button_area}>
            {editEnabled ? (
                <Item shouldUpdate>
                    {() => (
                        <AnalyticsButton
                            type="primary"
                            htmlType="submit"
                            className={styles.submit_button}
                            disabled={submitting || checkErrors()}
                        >
                            <FormattedMessage id="Common.Done" />
                        </AnalyticsButton>
                    )}
                </Item>
            ) : (
                <AnalyticsButton type="primary" className={styles.submit_button} onClick={() => setEditEnabled(true)}>
                    <FormattedMessage id="Common.Edit" />
                </AnalyticsButton>
            )}
        </div>
    );

    const fields = {
        name: (
            <Item
                label={<FormattedMessage id="SignUp.Field.CompanyName" />}
                className={styles.form_item}
                name="name"
                rules={editEnabled ? rules.name : []}
                initialValue={companyProfile.name}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.CompanyName.Placeholder' })}
                    inputs={inputs}
                    maxLength={100}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
        address: (
            <Item
                label={<FormattedMessage id="Contact.Field.Address" />}
                className={styles.form_item}
                name="address"
                initialValue={companyProfile.address}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'Contact.Field.Address' })}
                    inputs={inputs}
                    maxLength={90}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
        contactNumber: (
            <Item
                label={<FormattedMessage id="Contact.Field.Phone" />}
                className={styles.form_item}
                name="contactNumber"
                rules={rules.contactNumber}
                initialValue={companyProfile.contactNumber}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'Contact.Field.Phone' })}
                    inputs={inputs}
                    type="tel"
                    maxLength={40}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
        email: (
            <Item
                label={<FormattedMessage id="Common.Email" />}
                className={styles.form_item}
                name="email"
                rules={editEnabled ? rules.email : []}
                initialValue={companyProfile.email}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'Common.Email' })}
                    inputs={inputs}
                    type="email"
                    maxLength={40}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
        website: (
            <Item
                label={<FormattedMessage id="Common.Website" />}
                className={styles.form_item}
                name="website"
                rules={editEnabled ? rules.website : []}
                initialValue={companyProfile.website}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'Common.Website' })}
                    inputs={inputs}
                    type="url"
                    maxLength={40}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
    };

    return (
        <AppWrapper className={isApp ? styles.app_wrapper_basic : styles.app_wrapper}>
            {companyID ? (
                <div className={isApp ? styles.inner_frame_basic : styles.inner_frame} style={{ paddingTop: 50 }}>
                    <EditProfileImage
                        src={pictureURL}
                        shape="rect"
                        onUpload={handleUploadCompanyPicture}
                        onRemove={handleRemoveCompanyPicture}
                        placeholder={<CompanyLogoPlaceholder />}
                        title={formatMessage({ id: 'Profile.CompanyImage.Title' })}
                        fullImage
                    />

                    <Form
                        form={form}
                        layout="vertical"
                        className={styles.form}
                        onFinish={handleSubmit as any}
                        noValidate
                    >
                        {buttonArea}
                        {fields.name}
                        {fields.address}
                        {fields.contactNumber}
                        {fields.email}
                        {fields.website}
                    </Form>
                </div>
            ) : fetchingData ? (
                <div className={styles.loading_wrapper}>
                    <Spin />
                </div>
            ) : (
                <div className={isApp ? styles.inner_frame_basic : styles.inner_frame}>
                    <FormattedMessage id="Error.Profile.FetchCompanyProfile" />
                </div>
            )}
        </AppWrapper>
    );
};

export default CompanyProfile;
