import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

interface AuthSelectProps<T = any> extends SelectProps<T> {}

const AuthSelect = React.forwardRef<any, AuthSelectProps>((props, ref) => {
    return (
        <Select
            ref={ref}
            filterOption={(input, option) =>
                ((option?.children as unknown) as string).toLowerCase().includes(input.toLowerCase())
            }
            getPopupContainer={(node) => node.myParentElement()}
            size="large"
            {...props}
        />
    );
});

export default AuthSelect;
