import { Rule } from 'antd/lib/form';
import React from 'react';
import { NonOptionalKeys } from 'tds-common-fe';
import FormattedMessage from '../../../../localization/FormatMessage';

export interface SetupProfileFieldsType {
    firstName: string;
    lastName: string;
    companyName: string;
}
export type SetupProfileFieldsKeys = keyof SetupProfileFieldsType;

export const requiredFields: NonOptionalKeys<SetupProfileFieldsType>[] = ['firstName', 'lastName', 'companyName'];
export const companyNameRegex = /^((?!:\/\/).)*$/;
export const nameRegex = /^['\-,.]*[A-Za-z0-9]*[^_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/;

export const rules: { [K in keyof SetupProfileFieldsType]?: Rule[] } = {
    firstName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.FirstName.Error.Invalid" />,
            pattern: nameRegex,
        },
    ],
    lastName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.LastName.Error.Invalid" />,
            pattern: nameRegex,
        },
    ],
    companyName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.CompanyName.Error.Invalid" />,
            pattern: companyNameRegex,
        },
    ],
};
