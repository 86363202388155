import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useInterval, dateUtils, DurationInfo } from 'tds-common-fe';
import FormattedMessage from '../../../localization/FormatMessage';
import { UserLicenseState } from '../../../reducers/userProfile';
import postMessenger from '../../../utils/PostMessenger';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { APP_ROUTES, getRouteWithUserID } from '../../Routes/urls';
import styles from './ExpirationInfo.styl';
import analytics from '../../../analytics/index';
import { EventName } from '../../../analytics/analyticsConstants';

const ExpirationInfo: React.FunctionComponent<{ license: UserLicenseState; isFreeTrial: boolean }> = (props) => {
    const { license, isFreeTrial } = props;
    const { expirationDate = 0 } = license;

    const history = useHistory();
    const { userID } = useParams<{ userID: string }>();

    const [durationInfo, setDurationInfo] = useState<DurationInfo>(dateUtils.getDurationInfo(expirationDate));

    // Check every minute
    const delay = durationInfo.expired ? null : 60 * 1000;

    useInterval(() => {
        if (expirationDate === 0) return;
        const newDurationInfo = dateUtils.getDurationInfo(expirationDate);
        setDurationInfo((info) => {
            // Always update with new duration info if not expired, days changed, or days <= 0
            if (!info.expired && (info.days !== newDurationInfo.days || newDurationInfo.days <= 0)) {
                return newDurationInfo;
            }
            // Return info will not cause a re-rendering as they are the same instance
            return info;
        });
    }, delay);

    if (isEmpty(license)) {
        return null;
    }

    return (
        <div className={styles.trial_info_wrapper}>
            <h1 className={styles.modal_title}>
                {durationInfo.expired && (
                    <FormattedMessage
                        id={isFreeTrial ? 'License.Expired.FreeTrialTitle' : 'License.Expired.Title'}
                        values={{ tierName: license?.tier?.name }}
                    />
                )}
                {durationInfo.days > 0 && (
                    <FormattedMessage
                        id="License.TrialExpiration.Title.Days"
                        values={{
                            count: durationInfo.days + (durationInfo.hours > 0 || durationInfo.minutes > 0 ? 1 : 0), // ceiling days count
                        }}
                    />
                )}
                {!durationInfo.expired && durationInfo.days <= 0 && (
                    <FormattedMessage
                        id="License.TrialExpiration.Title.Time"
                        values={{ hours: durationInfo.hours, minutes: durationInfo.minutes }}
                    />
                )}
            </h1>
            <p>
                <FormattedMessage
                    id={
                        durationInfo.expired
                            ? 'License.TrialExpired.Description'
                            : 'License.TrialExpiration.Description'
                    }
                />
            </p>
            <AnalyticsButton
                className={'upgrade_button'}
                onClick={() => {
                    if (postMessenger.parentOrigin) {
                        analytics.logEventWithName(EventName.UPGRADE);
                        postMessenger.post({ type: 'openContactForm', data: {} });
                    } else {
                        history.push(getRouteWithUserID(APP_ROUTES.LICENSE_UPGRADE_INFO, userID));
                    }
                }}
            >
                <FormattedMessage id="License.Subscription.Help.Action" />
            </AnalyticsButton>
        </div>
    );
};

export default ExpirationInfo;
