import config from '../config';

export const RECAPTCHA_KEY = 'recaptcha-key';

function getScriptElement() {
    return document.getElementById(RECAPTCHA_KEY) as HTMLScriptElement | undefined;
}

function waitForScript() {
    return new Promise<Exclude<typeof window.grecaptcha, undefined>>((resolve, reject) => {
        const captchaScript = getScriptElement();
        if (!captchaScript) {
            reject(new Error('Missing script'));
        } else if (!window.grecaptcha) {
            captchaScript.onload = () => resolve(window.grecaptcha ?? { execute: undefined, ready: undefined });
        } else {
            resolve(window.grecaptcha);
        }
    });
}

function waitForCaptchaReady(grecaptcha: Exclude<typeof window.grecaptcha, undefined>) {
    return new Promise<void>((resolve) => {
        grecaptcha.ready(() => resolve());
    });
}

export const getCaptchaToken = async (action: string) => {
    const grecaptcha = await waitForScript();
    await waitForCaptchaReady(grecaptcha);
    return grecaptcha.execute(config.RECAPTCHA_SITE_KEY, { action });
};
