import { fileUtils } from 'tds-common-fe';

export const fileToDataBlob = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        if (file.type.includes('heic')) {
            getHeicFile(file).then((heicFile) => {
                if (heicFile instanceof Error) {
                    reject(heicFile);
                    return;
                }

                readFilePromise(resolve, reject, heicFile);
            });
        }
        readFilePromise(resolve, reject, file);
    });
};

const readFilePromise = (resolve: Function, reject: Function, file: File) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
        const dataBlob = (reader.result as string).split(',')[1];
        resolve(dataBlob);
    };
    reader.onerror = (error) => {
        reject(error);
    };
};

export function limitImageSize({ height, width, limit }: { height: number; width: number; limit: number }) {
    if (height > limit && height >= width) {
        return {
            height: Math.round(limit),
            width: Math.round(width * (limit / height)),
        };
    }
    if (width > limit && width > height) {
        return {
            width: Math.round(limit),
            height: Math.round(height * (limit / width)),
        };
    }
    return { height, width };
}

export const getHeicFile = async (file: File) => {
    const heicFile = await fileUtils.convertHeicToJpeg(file);
    if (!heicFile) return new Error('Failed to convert file');
    return heicFile;
};
