import React, { CSSProperties } from 'react';
import FormattedMessage from '../../localization/FormatMessage';
import NewTabLink from './NewTabLink';
import { RECAPTCHA_POLICY_URL, RECAPTCHA_TNC_URL } from '../../config/constants';

interface RecaptchaTNCProps {
    buttonStyles?: CSSProperties;
}

const RecaptchaTNC: React.FunctionComponent<RecaptchaTNCProps> = ({ buttonStyles }) => (
    <div style={{ fontSize: '14px' }}>
        <FormattedMessage
            id="SignUp.Field.Recaptcha"
            values={{
                policy: (
                    <NewTabLink style={buttonStyles} url={RECAPTCHA_POLICY_URL}>
                        <FormattedMessage id="SignUp.Field.TNC.Policy" />
                    </NewTabLink>
                ),
                tnc: (
                    <NewTabLink style={buttonStyles} url={RECAPTCHA_TNC_URL}>
                        <FormattedMessage id="SignUp.Field.RecaptchaTerms" />
                    </NewTabLink>
                ),
            }}
        />
    </div>
);

export default RecaptchaTNC;
