import React, { useEffect } from 'react';
import * as userService from '../../../../api/userService';
import FormattedMessage from '../../../../localization/FormatMessage';
import { UserLicenseState } from '../../../../reducers/userProfile';
import { formatDate } from '../../../../utils/dateUtils';
import ContactSection from '../ContactSales/ContactSection';
import PlanFeatureList from '../Plan/PlanFeatureList';
import styles from './SubscriptionInfo.styl';
import { LicenseFeature } from '../../../../types/license';

interface PlanContentProps {
    license: UserLicenseState;
    isFreeTrial: boolean;
}

const PlanContent: React.FunctionComponent<PlanContentProps> = (props) => {
    const { license, isFreeTrial } = props;
    const { tier, expirationDate = 0 } = license;

    const features: LicenseFeature[] =
        tier?.customerFeatureList?.split('\n').map((feat) => ({ description: feat, code: feat })) ||
        tier?.features ||
        [];

    // Fetch the license again based on expiration date
    useEffect(() => {
        const duration = expirationDate - Date.now();
        if (duration > 0) {
            const timeoutID = setTimeout(() => {
                userService.getUserLicense().catch(() => {});
            }, duration);
            return () => clearTimeout(timeoutID);
        }
        return () => {};
    }, [expirationDate]);

    const isExpired = expirationDate <= Date.now();

    return (
        <div className={styles.inner_frame}>
            <div className={styles.plan_info_wrapper}>
                <h3 className={styles.sub_info_title}>
                    <FormattedMessage id="License.CurrentPlan.Title" />
                </h3>
                <h1 className={styles.plan_title}>{tier?.name ?? ''}</h1>
                {expirationDate > 0 ? (
                    <p className={styles.expiration_date}>
                        {isExpired ? (
                            <FormattedMessage id="License.CurrentPlan.Expired" />
                        ) : (
                            <FormattedMessage
                                id={
                                    isFreeTrial
                                        ? 'License.CurrentPlan.Expiration'
                                        : 'License.CurrentPlan.NextBillingDate'
                                }
                                values={{ date: formatDate(new Date(expirationDate)) }}
                            />
                        )}
                    </p>
                ) : (
                    <p className={styles.expiration_date}>
                        <FormattedMessage id="License.CurrentPlan.Basic" />
                    </p>
                )}

                <PlanFeatureList columnCount={2} features={features} className={styles.plan_items_wrapper} />
                <ContactSection />
            </div>
        </div>
    );
};

export default PlanContent;
