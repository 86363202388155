import React from 'react';
import { Form, message } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useHistory } from 'react-router-dom';
import { NonOptionalKeys, FormItem, routeUtils } from 'tds-common-fe';
import FormattedMessage from '../../../localization/FormatMessage';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import AuthInput from '../Custom/AuthInput';
import AuthButton from '../Custom/AuthButton';
import * as urls from '../../Routes/urls';
import * as passwordUtils from '../../../utils/passwordUtils';
import * as inputUtils from '../../../utils/inputUtils';
import * as authService from '../../../api/authService';
import formStyles from '../Form.styl';
import AuthPasswordLabel from '../Custom/AuthPasswordLabel';
import { handleError } from '../../../api/error';
import { PASSWORD_MAX_LENGTH } from '../../../config/constants';
import Link from '../../Routes/Link';
import AuthBasics from '../Custom/AuthBasics';
import { checkAuthParamsReady } from '../../../utils/authUtils';

interface FieldsType {
    username: string;
    password: string;
    passwordConfirm: string;
}

const requiredFields: NonOptionalKeys<FieldsType>[] = ['password', 'passwordConfirm'];

class Item extends FormItem<FieldsType> {}

const ResetPassword: React.FunctionComponent = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { formatMessage } = useFormatMessage();
    const params = routeUtils.parseQuery<{ token: string; username: string }>(location.search);
    const { token, username } = params;

    const isAuthParamsReady = checkAuthParamsReady(params);

    const handleSubmit = (values: FieldsType) => {
        const { password, passwordConfirm } = values;
        if (!token || !username) {
            message.warning(formatMessage({ id: 'ForgetPasswordDone.Header.Description' }));
            return;
        }
        authService
            .resetPassword({ token, username, password, passwordConfirm })
            .then(() => history.push({ pathname: urls.AUTH_ROUTES.RESET_PASSWORD_DONE, search: location.search }))
            .catch((error) => {
                handleError(error);
            });
    };

    const title = <FormattedMessage id="ResetPassword.Header.Title" />;
    const description = <FormattedMessage id="ResetPassword.Header.Description" />;

    return (
        <div className="signup">
            <AuthBasics title={title} description={description} />
            <Form
                form={form}
                layout="vertical"
                initialValues={{ username, token }}
                requiredMark={false}
                onFinish={handleSubmit as (values: Store) => {}}
            >
                <Item
                    className={formStyles.form_item}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="Login.Field.Password.Error.Empty" />,
                        },
                        passwordUtils.getStrongPasswordValidator(
                            ['username'],
                            <FormattedMessage id="SignUp.Field.Password.Error.Weak" />
                        ),
                        inputUtils.getMaxLengthValidator(
                            PASSWORD_MAX_LENGTH,
                            <FormattedMessage
                                id="SignUp.Field.Input.Error.MaxLength"
                                values={{ count: PASSWORD_MAX_LENGTH }}
                            />
                        ),
                    ]}
                >
                    <AuthInput
                        addonAfter={<AuthPasswordLabel />}
                        authInputType="password"
                        placeholder={formatMessage({ id: 'ResetPassword.Field.NewPassword.Placeholder' })}
                        maxLength={PASSWORD_MAX_LENGTH}
                    />
                </Item>

                <Item
                    name="passwordConfirm"
                    dependencies={['password']}
                    required={false}
                    rules={[
                        {
                            required: true,
                            message: <FormattedMessage id="ResetPassword.Field.ConfirmPassword.Error.Empty" />,
                        },
                        passwordUtils.getConfirmPasswordValidator<keyof FieldsType>(
                            'password',
                            <FormattedMessage id="ResetPassword.Field.ConfirmPassword.Error.NotSame" />
                        ),
                    ]}
                >
                    <AuthInput
                        authInputType="password"
                        placeholder={formatMessage({ id: 'ResetPassword.Field.ConfirmPassword.Placeholder' })}
                        maxLength={PASSWORD_MAX_LENGTH}
                    />
                </Item>
                <Item shouldUpdate>
                    {() => <AuthButton title="Common.Confirm" form={form} requiredFields={requiredFields} />}
                </Item>
                {isAuthParamsReady && (
                    <div className={formStyles.footer_actions}>
                        <FormattedMessage
                            id="ResetPassword.Footer.LoginOrSignUp"
                            values={{
                                login: (
                                    <Link to={urls.AUTH_ROUTES.LOGIN}>
                                        <FormattedMessage id="Login.Header.Title" />
                                    </Link>
                                ),
                                signUp: (
                                    <Link to={urls.AUTH_ROUTES.SIGN_UP}>
                                        <FormattedMessage id="SignUp.Header.Title" />
                                    </Link>
                                ),
                            }}
                        />
                    </div>
                )}
            </Form>
        </div>
    );
};

export default ResetPassword;
