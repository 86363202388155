import { routeUtils } from 'tds-common-fe';
import { LanguageOptions } from '../localization';

export interface AuthenticationParams {
    redirect_uri: string | undefined;
    client_id: string | undefined;
    response_type: string | undefined;
    state: string | undefined;
    scope: string | undefined;
    includeLang: string | undefined;
    product: string | undefined;
    code_challenge: string | undefined;
    code_challenge_method: string | undefined;
}

export function getAuthParams(): AuthenticationParams {
    /* eslint-disable @typescript-eslint/naming-convention */
    const {
        redirect_uri,
        client_id,
        response_type,
        state,
        scope,
        includeLang,
        product,
        code_challenge,
        code_challenge_method,
    } = routeUtils.parseQuery<AuthenticationParams>(location.search, false);
    /* eslint-enable @typescript-eslint/naming-convention */
    return {
        redirect_uri,
        client_id,
        response_type,
        state,
        scope,
        includeLang,
        product,
        code_challenge,
        code_challenge_method,
    };
}

type AuthParamKeys = keyof AuthenticationParams;

export const checkAuthParamsReady = (params: { [key: string]: string | number | undefined }) => {
    const necessaryAuthParams: AuthParamKeys[] = ['redirect_uri', 'client_id', 'response_type', 'scope'];
    for (const key of necessaryAuthParams) {
        if (params[key] === undefined) {
            return false;
        }
    }
    return true;
};

export function redirectURLWithLanguage(url: string | undefined, language: LanguageOptions): string | undefined {
    if (!url) return undefined;
    const splittedURL = url.split('?');
    const path = splittedURL[0];
    const query = routeUtils.parseQuery(splittedURL.length === 2 ? splittedURL[1] : '');
    return routeUtils.makeQueryPath(path, { ...query, lang: language });
}
