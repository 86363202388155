import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import React, { useContext, useRef } from 'react';
import { routeUtils } from 'tds-common-fe';
import urls from '../../api/urls';
import { ReactComponent as AppleIcon } from '../../images/socials/Apple.svg';
import { ReactComponent as GoogleIcon } from '../../images/socials/Google.svg';
import { ReactComponent as MicrosoftIcon } from '../../images/socials/Microsoft.svg';
import { LocalizationContext } from '../../localization/LocalizedIntlProvider';
import { AuthType, Socials } from '../../types/authentication';
import styles from '../Authentication/Form.styl';
import { getScreeningEagleHeaders } from '../../api/headerUtils';
import { redirectURLWithLanguage } from '../../utils/authUtils';
import analytics from '../../analytics';

const socialInfo: {
    [key in Socials]: {
        icon: React.ReactNode;
        title: string;
    };
} = {
    [Socials.GOOGLE]: {
        icon: <GoogleIcon />,
        title: 'Google',
    },
    [Socials.MICROSOFT]: {
        icon: <MicrosoftIcon />,
        title: 'Microsoft',
    },
    [Socials.APPLE]: {
        icon: <AppleIcon />,
        title: 'Apple',
    },
};

interface SocialButtonProps extends ButtonProps {
    provider: Socials;
    authType: AuthType;
    setLoading: (loading: boolean) => void;
}

const SocialButton = ({ provider, authType, setLoading, ...rest }: SocialButtonProps) => {
    const { includeLang, ...queryParams } = routeUtils.parseQuery<any>(location.search);
    const actionUrl = urls.OAUTH_SOCIAL_LOGIN.replace('{accountType}', provider);

    const { language } = useContext(LocalizationContext);

    // inorder to have the same redirectUri for token validation we need to append the language
    // reference: Login/index.tsx line 94
    if (includeLang === 'true' && queryParams.redirect_uri) {
        queryParams.redirect_uri = redirectURLWithLanguage(queryParams.redirect_uri, language);
    }

    const form = useRef<HTMLFormElement>(null);

    const params: { [key: string]: string | undefined } = {
        ...queryParams,
        ...getScreeningEagleHeaders(),
        ...(AuthType.ConfirmPassword === authType ? { password_confirm: 'true' } : {}),
    };

    const handleClick = () => {
        setLoading(true);
        if (authType === AuthType.Login) {
            analytics.logLogin(provider);
        } else {
            analytics.logSignUp(provider);
        }

        if (window.self === window.top) {
            // if it's not in iframe, submit the form
            const currentForm = form.current;
            if (currentForm !== null) currentForm.submit();
        } else {
            // open popup window
            const actionUrl = routeUtils.makeQueryPath(
                urls.OAUTH_SOCIAL_LOGIN.replace('{accountType}', provider),
                params
            );

            window.open(actionUrl, provider, 'popup');
            setLoading(false);
        }
    };

    return (
        <>
            <Button icon={socialInfo[provider].icon} {...rest} onClick={handleClick}>
                {socialInfo[provider].title}
            </Button>
            <form ref={form} action={actionUrl} method="GET" className={styles.hidden_form}>
                {Object.keys(params).map((key) => (
                    <input key={key} type="hidden" name={key} value={params[key]} />
                ))}
            </form>
        </>
    );
};

export default SocialButton;
