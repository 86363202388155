import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { LicenseTier } from '../types/license';

const useLicenseTiers = () => {
    const tiersRaw = useSelector((state: RootState) => state.license.getIn(['tier', 'tiers']));
    const tiers: { [key: string]: LicenseTier } = useMemo(() => tiersRaw?.toJS() ?? {}, [tiersRaw]);

    const tierIDsRaw = useSelector((state: RootState) => state.license.getIn(['tier', 'tierIDs']));
    const tierIDs: string[] = useMemo(() => tierIDsRaw?.toJS() ?? [], [tierIDsRaw]);

    return { tiers, tierIDs };
};

export default useLicenseTiers;
