import React from 'react';
import styles from './ExpirationInfo.styl';

interface PlanBadgeProps {
    top: number;
}

const PlanBadge: React.FunctionComponent<React.PropsWithChildren<PlanBadgeProps>> = (props) => {
    const { top = 0, children } = props;
    return (
        <div className={styles.badge} style={{ top }} data-color="yellow">
            {children}
        </div>
    );
};

export default PlanBadge;
