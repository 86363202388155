import React, { Fragment } from 'react';
import FormattedMessage from '../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../types';
import styles from './StepProgress.styl';
import { ReactComponent as TickIcon } from '../../../../images/tick.svg';
import { purchaseSteps, stepConfigs } from '.';

interface StepProgressProps {
    currentStep: number;
}

const StepProgress: React.FunctionComponent<StepProgressProps> = (props) => {
    const { currentStep } = props;
    return (
        <div className={styles.step_wrapper}>
            {purchaseSteps.map((purchaseStep, index) => {
                const stepConfig = stepConfigs[purchaseStep];
                return (
                    <Fragment key={index}>
                        <StepRenderer
                            number={index + 1}
                            titleID={stepConfig.titleID}
                            mode={getMode(index, currentStep)}
                        />
                        <div className={styles.line} />
                    </Fragment>
                );
            })}
        </div>
    );
};

export default StepProgress;

const getMode = (step: number, currentStep: number): StepMode => {
    if (step === currentStep) return 'active';
    if (step < currentStep) return 'done';
    return 'todo';
};

type StepMode = 'done' | 'active' | 'todo';

interface StepRendererProps {
    number: number | string;
    titleID: FormatIDs;
    mode: StepMode;
}
const StepRenderer: React.FunctionComponent<StepRendererProps> = (props) => {
    const { number, titleID, mode } = props;
    return (
        <div className={styles.step_item} data-state={mode}>
            <span className={styles.number}>{mode === 'done' ? <TickIcon fontSize={12} /> : number}</span>
            <span className={styles.title}>
                <FormattedMessage id={titleID} />
            </span>
        </div>
    );
};
