import React from 'react';
import { Switch } from 'react-router-dom';
import Login from '../Authentication/Login';
import ForgetPassword from '../Authentication/ForgetPassword';
import ForgetPasswordDone from '../Authentication/ForgetPassword/ForgetPasswordDone';
import ResetPassword from '../Authentication/ResetPassword';
import ResetPasswordDone from '../Authentication/ResetPassword/ResetPasswordDone';
import SignUp from '../Authentication/SignUp';
import SignUpDone from '../Authentication/SignUp/SignUpDone';
import EmailVerification from '../Authentication/EmailVerification';
import AnalyticsRoute from '../AnalyticsComponents/Route';
import { AUTH_ROUTES } from './urls';
import AuthWrapper from '../Authentication/AuthWrapper';
import InitiateAccountDeletion from '../Authentication/AccountDeletion/InitiateAccountDeletion';
import VerifyPassword from '../Authentication/VerifyPassword';
import RequestAccountDeletion from '../Authentication/AccountDeletion/RequestAccountDeletion';
import ScheduleAccountDeletion from '../Authentication/AccountDeletion/ScheduleAccountDeletion';
import SwitchAccount from '../Authentication/SwitchAccount';
import CountrySelection from '../Authentication/SignUp/CountrySelection';
import SetupProfile from '../Authentication/SignUp/SetupProfile';

const AuthRoutes: React.FunctionComponent = () => {
    return (
        <AuthWrapper>
            <Switch>
                <AnalyticsRoute exact path={AUTH_ROUTES.LOGIN} titleFormatIDs={['Login.Title']} changeDocumentTitle>
                    <Login />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={AUTH_ROUTES.SIGN_UP} titleFormatIDs={['SignUp.Title']} changeDocumentTitle>
                    <SignUp />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={AUTH_ROUTES.SIGN_UP_DONE} titleFormatIDs={['NavTitle.SignUpDone']}>
                    <SignUpDone />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={AUTH_ROUTES.COUNTRY_SELECTION} titleFormatIDs={['SignUp.Title']}>
                    <CountrySelection />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={AUTH_ROUTES.SETUP_PROFILE} titleFormatIDs={['SignUp.Title']}>
                    <SetupProfile />
                </AnalyticsRoute>
                <AnalyticsRoute
                    exact
                    path={AUTH_ROUTES.EMAIL_VERIFICATION}
                    titleFormatIDs={['NavTitle.EmailVerification']}
                >
                    <EmailVerification />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={AUTH_ROUTES.FORGET_PASSWORD} titleFormatIDs={['NavTitle.ForgetPassword']}>
                    <ForgetPassword />
                </AnalyticsRoute>
                <AnalyticsRoute
                    exact
                    path={AUTH_ROUTES.FORGET_PASSWORD_DONE}
                    titleFormatIDs={['NavTitle.ForgotPasswordDone']}
                >
                    <ForgetPasswordDone />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={AUTH_ROUTES.RESET_PASSWORD} titleFormatIDs={['NavTitle.ResetPassword']}>
                    <ResetPassword />
                </AnalyticsRoute>
                <AnalyticsRoute
                    exact
                    path={AUTH_ROUTES.RESET_PASSWORD_DONE}
                    titleFormatIDs={['NavTitle.ResetPasswordDone']}
                >
                    <ResetPasswordDone />
                </AnalyticsRoute>
                <AnalyticsRoute
                    exact
                    path={AUTH_ROUTES.INITIATE_ACCOUNT_DELETION}
                    titleFormatIDs={['AccountDeletion.Initiate.Title']}
                >
                    <InitiateAccountDeletion />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={AUTH_ROUTES.VERIFY_PASSWORD} titleFormatIDs={['VerifyPassword.Title']}>
                    <VerifyPassword />
                </AnalyticsRoute>
                <AnalyticsRoute
                    exact
                    path={AUTH_ROUTES.REQUEST_ACCOUNT_DELETION}
                    titleFormatIDs={['AccountDeletion.Request.Title']}
                >
                    <RequestAccountDeletion />
                </AnalyticsRoute>
                <AnalyticsRoute
                    exact
                    path={AUTH_ROUTES.SCHEDULE_ACCOUNT_DELETION}
                    titleFormatIDs={['AccountDeletion.Schedule.Title']}
                >
                    <ScheduleAccountDeletion />
                </AnalyticsRoute>
                <AnalyticsRoute exact path={AUTH_ROUTES.SWITCH_ACCOUNT} titleFormatIDs={['SwitchAccount.Title']}>
                    <SwitchAccount />
                </AnalyticsRoute>
            </Switch>
        </AuthWrapper>
    );
};

export default AuthRoutes;
