import React from 'react';
import { Redirect, Switch, SwitchProps } from 'react-router-dom';
import * as urls from './urls';

const SwitchWrapper: React.FunctionComponent<React.PropsWithChildren<SwitchProps>> = (props: SwitchProps) => {
    return (
        <Switch location={props.location}>
            {props.children}
            <Redirect path="*" to={urls.NOT_FOUND} />
        </Switch>
    );
};

export default SwitchWrapper;
