import { Checkbox } from 'antd';
import React from 'react';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './SignUp.styl';
import { Item } from './CountrySelection';
import NewTabLink from '../../Shared/NewTabLink';
import { rules } from './CountrySelection/CountrySelectionRules';
import { POLICY_URL, TNC_URL } from '../../../config/constants';

const TermsAndConditions = () => {
    return (
        <Item className={styles.terms_conditions} name="agreeTnC" rules={rules.agreeTnC} valuePropName="checked">
            <Checkbox>
                <FormattedMessage
                    id="SignUp.Field.TNC"
                    values={{
                        policy: (
                            <NewTabLink url={POLICY_URL} insertTimestamp>
                                <FormattedMessage id="SignUp.Field.TNC.Policy" />
                            </NewTabLink>
                        ),
                        tnc: (
                            <NewTabLink url={TNC_URL} insertTimestamp>
                                <FormattedMessage id="SignUp.Field.TNC.GeneralTNC" />
                            </NewTabLink>
                        ),
                    }}
                />
            </Checkbox>
        </Item>
    );
};

export default TermsAndConditions;
