import React from 'react';

const NotFound: React.FunctionComponent = () => {
    return (
        <div data-mode="dark" style={{ width: '100%', height: '100%', padding: 16 }}>
            404 NOT FOUND
        </div>
    );
};

export default NotFound;
