// This is a react router Link alternative created using Button instead or Anchor
// because it will appear weird when Anchors are embedded in iOS web view

import React, { CSSProperties } from 'react';
import { routeUtils } from 'tds-common-fe';
import * as appUtils from '../../utils/appUtils';
import AnalyticsButton from '../AnalyticsComponents/Button';

interface LinkProps {
    style?: CSSProperties;
    url: string;
    insertTimestamp?: boolean;
    size?: 'small';
}

const NewTabLink: React.FunctionComponent<React.PropsWithChildren<LinkProps>> = React.memo((props) => {
    const { url, style = {}, insertTimestamp = false, size } = props;

    const openNewTab = () => {
        if (appUtils.fromApp()) {
            appUtils.postAppData(appUtils.appDataTypes.openURL, url);
        } else {
            const newURL = insertTimestamp ? routeUtils.makeQueryPath(url, { t: new Date().getTime() }) : url;
            window.open(newURL, '_blank');
        }
    };

    return (
        <AnalyticsButton type="link" onClick={openNewTab} size={size} style={{ padding: 0, height: 'auto', ...style }}>
            {props.children}
        </AnalyticsButton>
    );
});

export default NewTabLink;
