import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Avatar, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import Cropper from 'react-easy-crop';

import ImgCrop from '../../Shared/AntdImageCrop';
import styles from './ProfileImage.styl';
import { ReactComponent as IconEdit } from '../../../images/icon_edit.svg';
import { FormatMessageFunc } from '../../../types';
import FormattedMessage from '../../../localization/FormatMessage';

interface ProfileImageProps {
    src?: string;
    shape: 'round' | 'rect';
    placeholder?: React.ReactNode;
    onUpload: (file: RcFile) => void;
    onRemove?: () => void;
    title: string;
    fullImage?: boolean;
}
const EditAvatar: React.FunctionComponent<ProfileImageProps> = (props) => {
    const { src, shape = 'round', placeholder, title, fullImage, onUpload, onRemove } = props;
    const formatMessage: FormatMessageFunc = useIntl().formatMessage;
    const modalContainer = useRef<HTMLDivElement>(null);

    const handleUpload: (file: RcFile, _: RcFile[]) => boolean = (file) => {
        onUpload(file);
        return false;
    };
    const showPlaceholder = placeholder && !src;

    const cropper = useRef<Cropper>(null);

    return (
        <div className={styles.profile_image_wrapper}>
            {showPlaceholder ? (
                placeholder
            ) : (
                <Avatar
                    className={fullImage ? styles.full_profile_image : ''}
                    shape={shape === 'round' ? 'circle' : 'square'}
                    size={88}
                    src={src}
                />
            )}
            <div className={shape === 'round' ? styles.edit_avatar_round : styles.edit_avatar_rect}>
                <ImgCrop
                    shape={shape === 'round' ? 'round' : 'rect'}
                    getContainer={() => modalContainer.current as HTMLDivElement}
                    modalOk={formatMessage({ id: 'Common.Upload' })}
                    modalCancel={formatMessage({ id: 'Common.Cancel' })}
                    modalTitle={title}
                    limitSize={200}
                    zoom
                    ref={cropper}
                    fullImage={fullImage}
                >
                    <Upload
                        multiple={false}
                        beforeUpload={handleUpload}
                        accept="image/png,image/jpeg,image/bmp,image/gif,image/heic"
                        showUploadList={false}
                    >
                        <div className={styles.edit_avatar_button}>
                            <IconEdit />
                        </div>
                    </Upload>
                </ImgCrop>
            </div>
            {src && onRemove && (
                <div className={styles.remove_button}>
                    <span onClick={() => onRemove()}>
                        <FormattedMessage id="Common.Remove" />
                    </span>
                </div>
            )}
            <div ref={modalContainer} />
        </div>
    );
};

export default EditAvatar;
