import { useEffect } from 'react';
import { routeUtils } from 'tds-common-fe';
import { RECAPTCHA_URL } from '../api/urls';
import config from '../config';
import { RECAPTCHA_KEY } from '../utils/recaptchaUtils';

const useRecaptcha = () => {
    useEffect(() => {
        const isScriptExist = document.getElementById(RECAPTCHA_KEY);
        if (!isScriptExist) {
            const script = document.createElement('script');
            script.src = routeUtils.makeQueryPath(RECAPTCHA_URL, { render: config.RECAPTCHA_SITE_KEY });
            script.id = RECAPTCHA_KEY;
            document.body.appendChild(script);
        }
    }, []);
};

export default useRecaptcha;
